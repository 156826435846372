import React from "react";
import { Formik, Form } from "formik";
import Title from "./inputs/title";
import SubTitle from "./inputs/subTitle";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import GenericInput from "./genericInput";
import GenericImage from "./genericImage";
import GenericButton from "./genericButton";
import ErrorMessage from "./inputs/errorMessage";
import {Link} from "react-router-dom";

const GenericForm = (props) => {
  const [t] = useTranslation("global");

  const render_image = (img) => {
    return <Grid
        item
        xs={12}
        md={props.form.images.length > 1 ? 6 : 12}
        key={img.id}
        className={img.props.styleGrid}
    >
      <div>
        <GenericImage props={img.props}/>
      </div>
    </Grid>
  }

  return (
    <Formik
      initialValues={props.initialValue}
      validationSchema={props.validates}
      onSubmit={(valores) => props.onSubmit(valores)}
    >
      {({ errors, submit }) => (
        <Form className={`formulario ${props.form.styleForm}`}>
          {props.form.images && props.isDefault &&
              props.form.images.map((img) => (render_image(img)))
          }
          <Title title={t(props.form.title)} className={props.form.styleTitle} />
          {props.form.subtitle && (
              <SubTitle subTitle={t(props.form.subtitle)} />
          )}
          {props.form.fields.map((field) => (
            <div key={field.props.id}>
              {!field.props.hide && <GenericInput {...field} loginError={props.loginError} setLoginError={props.setLoginError} setDisabledButton={props.setDisabledButton}/>}
              {field.props.type !== "email" ? (
                  <ErrorMessage name={field.props.name} errors={errors} />
              ) : (
                  ""
              )}
            </div>
          ))}

          <div style={{ marginTop: "20px" }} id="div buttton">
            <Grid container spacing={2} className="grid-buttons">
              {props.form.actionsButtons.map((btn) => (
                
                  <Grid
                      item
                      xs={12}
                      md={props.form.actionsButtons.length > 1 ? 6 : 12}
                      key={btn.id}
                      className={`${btn.styleGrid}`}
                  >
                    <div>
                      {!btn.hide && <GenericButton props={btn} submit={submit} disabledButton={props.disabledButton} />}
                    </div>
                  </Grid>
              ))}
            </Grid>
          </div>

          {props.form.footer.created && (
              <div className="footer">
                <Typography className="text" style={{ display: "inline" }}>
                  {t(props.form.footer.text)}
                </Typography>
                <Link to={props.form.footer.route} className="text">
                  {t(props.form.footer.textlink)}
                </Link>
              </div>
          )}
          {props.form.images && !props.isDefault &&
              props.form.images.map((img) => (render_image(img)))
          }
        </Form>
      )}
    </Formik>
  );
};

export default GenericForm;
