import { createContext, useContext, useState } from "react";


const SideMenuContext = createContext({});
export const SideMenuProvider = (props) => {
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
    const openSideMenu = () => {
        setIsSideMenuOpen(true);
    };

    const closeSideMenu = () => {
        setIsSideMenuOpen(false);
    }

    const toggleSideMenu = (open) => {
        setIsSideMenuOpen(open);
    }

    return(
        <SideMenuContext.Provider value={{
            isSideMenuOpen,
            openSideMenu,
            closeSideMenu,
            toggleSideMenu,
        }}>
            {props.children}
        </SideMenuContext.Provider>
    )
};

export const useSideMenuContext = () => {
    return useContext(SideMenuContext);
};