import * as React from 'react';
import { DialogActions, DialogContent, DialogTitle, Dialog, IconButton, Grid } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import themeDialog from '../../utils/themes/ThemeDialog';


export default function AlertDialog({ title, dialogContent, dialogActions, open = false, handleClose }) {
  const theme = themeDialog();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ maxWidth: '100% !important', width: '100% !important' }}
        id="dialog-generic"
        data-testid="dialog"
        classes={{
          scrollPaper: theme.topScrollPaperDialog,
          paperScrollBody: theme.topPaperScrollBodyDialog,
          paper: theme.dialogContainer,
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container>
            <Grid item xs={10}>
              {title}
            </Grid>
            <Grid item xs={2}>

              <IconButton
                aria-label="close"
                data-testid="close-dialog"
                onClick={handleClose}
                sx={{
                  display: "flex",
                  marginLeft: "auto",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          {dialogContent()}
        </DialogContent>

        <DialogActions>
          {typeof dialogActions === 'function' && dialogActions()}
        </DialogActions>

      </Dialog>
    </div>
  );
}