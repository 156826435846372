import {makeStyles} from "@material-ui/core/styles";

export const paymentsTheme = makeStyles((theme) => ({
    congratsText: {
        fontWeight: "500 !important",
        fontSize: '24px !important',
        lineHeight: '32px !important',
        color: '#343C46 !important',
        fontFamily: 'Readex Pro !important',
        textAlign: "center !important"
    },
    title: {
        fontWeight: "500 !important",
        fontSize: '16px !important',
        lineHeight: '24px !important',
        color: '#343C46 !important',
        fontFamily: 'Readex Pro !important',
    },
    description: {
        fontWeight: "400 !important",
        fontSize: '14px !important',
        lineHeight: '22px !important',
        color: '#343C46 !important',
        fontFamily: 'Readex Pro !important',
        textAlign: 'center !important',
    },
    label: {
        fontWeight: "400 !important",
        fontSize: '14px !important',
        lineHeight: '22px !important',
        color: '#343C46 !important',
        fontFamily: 'Readex Pro !important',
        letterSpacing: '0.25px !important'
    },
    value: {
        fontWeight: "500 !important",
        fontSize: '14px !important',
        lineHeight: '22px !important',
        color: '#343C46 !important',
        fontFamily: 'Readex Pro !important',
        letterSpacing: '0.25px !important'
    },
    boxBorder: {
        borderTop: '1px solid #E6E6E6',
    },
    totalPaymentImage: {
        [theme.breakpoints.down("xs")]: {
            width: '80%'
        },
        [theme.breakpoints.up("sm")]: {
            position: "absolute",
            marginTop: "-90px !important"
        },
    },
    partialPaymentImage: {
        [theme.breakpoints.down("xs")]: {
            width: '80%'
        },
        [theme.breakpoints.up("sm")]: {
            position: "absolute",
            marginTop: "-80px !important"
        },
    },
    checkPaymentImage: {
        [theme.breakpoints.down("xs")]: {
            width: '139.5px',
            marginTop: "-120px !important",
            zIndex: '0 !important',
            position: "absolute",
        },
        [theme.breakpoints.up("sm")]: {
            position: "absolute",
            marginTop: "-90px !important",
            width: '279.14px',
        },

    },
    cardOptReq:{
        [theme.breakpoints.down("xs")]: {
            background: 'transparent !important',
            boxShadow: '0px 0px 0px 0px !important',
        },
        [theme.breakpoints.up("sm")]: {
            borderRadius: '24px !important',
            boxShadow: '2px 4px 4px rgba(185, 185, 185, 0.25) !important',
            width: "598px  !important",
            justifyContent: "center  !important",
            alignItems: "flex-start  !important",
            background: '#ffffff !important',
            marginTop: "80px !important",
            paddingBottom: "40px !important"
        },
    },
    card: {
        [theme.breakpoints.down("xs")]: {
            boxShadow: '0px 0px 0px !important',
        },
        [theme.breakpoints.up("sm")]: {
            borderRadius: '24px !important',
            boxShadow: '2px 4px 4px rgba(185, 185, 185, 0.25) !important',
            width: "598px  !important",
            justifyContent: "center  !important",
            alignItems: "flex-start  !important",
            paddingBottom: "40px !important",
            marginTop: '60px !important'
        },
        background: '#ffffff',
    },
    boxOptReq: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "80px",
            paddingBottom: "3px",
            paddingLeft: "36px",
            paddingRight: "36px",
        }
    },
    gridContainer: {

        [theme.breakpoints.up("sm")]: {
            paddingRight: "60px",
            paddingLeft: "60px"
        }
    },
    gridContainerText: {
        [theme.breakpoints.up("sm")]: {
            paddingRight: "60px",
            paddingLeft: "60px"
        }
    },
    boxContainer: {
        [theme.breakpoints.down("sm")]: {
            paddingTop: "20px",
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "40px",
        }
    },
    textWow: {
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important"
    },
    gridTextWow: {
        [theme.breakpoints.up("sm")]: {
            marginTop: "185px !important"
        }

    },
    gridTextOptReq: {
        paddingBottom: "40px !important",
        [theme.breakpoints.up("sm")]: {
            marginTop: "215px !important",

        }
    }
}));
