import React from 'react';

import {Avatar, Stack, Typography, Button} from "@mui/material";
import {capitalizeFirstLetter} from "../../utils/functions/utils";
import {clearLocalStorage} from '../utils/localStorage';
import {DeleteSession} from "../../utils/providers/SessionManager";
import {ThemeBanner} from "../../utils/themes/ThemeBanner";
import {ThemeUserControlCenter} from "../../utils/themes/ThemeUserControlCenter";
import {useSideMenuContext} from "../../utils/providers/SideMenuProvider";
import {useCreditContext} from "../../utils/providers/CreditProvider";
import {useNavigate} from "react-router-dom";

import CloseIcon from '@mui/icons-material/Close';
import LogOutIcon from "../../assets/generic/LogOut.svg";
import Vector from "../../assets/generic/Vector.svg";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {useTranslation} from "react-i18next";

const UserControlCenter = () => {
    const [t] = useTranslation("global");
    const bannerStyles = ThemeBanner();

    const userControlStyles = ThemeUserControlCenter();

    const {closeSideMenu} = useSideMenuContext();

    const {client} = useCreditContext();

    const navigate = useNavigate();

    const {toggleSideMenu} = useSideMenuContext();

    const onCloseSession = () => {
        DeleteSession();
        clearLocalStorage()
        toggleSideMenu(false);
        navigate("/SignIn");
    }

    const onMyHistory = () => {
        closeSideMenu(); 
        window.analytics.track("HISTORY_CLICK", {
            page: "WC_Home",
            journey: "WC_Loans",
            button_name: "Mi Historial",
            product_group: "Lending",
            product_name: "Working_Capital"
        });
        
        navigate("/MyHistory");
    }

    return (
        <Stack sx={{overflow: 'none'}} className={bannerStyles.sideMenuBanner}>

            <Stack className={userControlStyles.closeButtonWrapper}>
                <div role="button" onClick={closeSideMenu}>
                    <CloseIcon style={{textAlign: 'center', fontSize: '22px'}}/>
                </div>
            </Stack>

            <Stack className={userControlStyles.avatar}>
                <Avatar sx={{bgcolor: "#363853", float: 'left', width: 72, height: 72}}>
                    <PersonOutlineIcon style={{textAlign: 'center', fontSize: '48px'}}/>
                </Avatar>
            </Stack>

            <Stack className={userControlStyles.userInfo}>
                <Typography className={userControlStyles.userNameTypography}>
                    {capitalizeFirstLetter(client?.first_name)} {capitalizeFirstLetter(client?.last_name)}
                </Typography>

                <Typography className={userControlStyles.emailTypography}>
                    {client?.email}
                </Typography>
            </Stack>

            <Stack className={userControlStyles.mainCard}>
                <Stack>
                    <Button
                        className={userControlStyles.myHistory}
                        startIcon={<img src={Vector} alt="mi historial"/>}
                        onClick={onMyHistory}
                    >{t("Menu.MyHistory")}
                    </Button>
                </Stack>

                <Stack className={userControlStyles.closeSessionWrapper}>
                    <Button
                        className={userControlStyles.ghostButton}
                        variant="text"
                        startIcon={<img src={LogOutIcon} alt="cerrar sesión"/>}
                        onClick={onCloseSession}
                        test-id="close-session"
                    >
                        {t("Menu.Logout")}
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default UserControlCenter;