import React, {useEffect, useState} from 'react'
import IframeKym from '../components/kym/IframeKYM'
import jwtDecode from "jwt-decode";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import GenericBackdrop from "../utils/GenericBackdrop";
import {validate_token} from "../actions/axios-api"

export default function FormRemedy() {
    let location = useLocation();
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const token = new URLSearchParams(location.search).get("token")
    const [flag, setFlag] = useState(false);
    const [id_pdv, setIdpdv] = useState(location.state && location.state.id_pdv);

    useEffect(() => {
        tokenValidation();
    }, []);

    function goToMessageScreen(info) {
        let tPrefix;
        let title = "Title";
        let subTitle = "SubTitle";
        let image = "Image"
        if (info === "expiration") {
            tPrefix = "ScreenMessage.InvalidToken.";
        } else {
            tPrefix = "ScreenMessage.Remedy.NoPending.";
        }
        navigate("/MessageScreen", {
            screen: "MessageScreen",
            state: {
                type: {
                    data: {
                        image: t(tPrefix + image),
                        title: t(tPrefix + title),
                        subTitle: t(tPrefix + subTitle),
                        default: false,
                    },
                },
            },
        });
    }

    async function tokenValidation() {
        if (token) {
            const decodedToken = jwtDecode(token);
            setIdpdv(decodedToken.id_pdv)
            const expiryDate = new Date(decodedToken.exp * 1000);
            const dateNow = new Date();
            if (expiryDate < dateNow.getTime()) {
                goToMessageScreen("expiration");
            } else {
                let data = {"token": token}
                const response = await validate_token(data)
                if (response.status === 200)
                    setFlag(true)
                else goToMessageScreen("noData");
            }
        }else{
            setFlag(true)
        }
    }

    const screen = () =>{
        if(flag){
            return (<IframeKym FormId="F1014_Remedy" email={""} idpdv={id_pdv} remedy={true} token={token}></IframeKym>);
        }else{
            return (<GenericBackdrop open={true}/>);
        }
    }

    return <>{screen()}</>;
}
