import React, { useEffect } from 'react'
import { Box, Card, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { primaryButtonStyles, secondaryButtonStyles, setButtonLabel } from "../../../utils/themes/ThemeButtons";

import WowMomentLogo from '../../../assets/credit/wowMoment.svg';
import themeCredit from '../../../utils/themes/ThemeCredit';
import Button from '../../../components/inputs/button';
import formatter from "../../../utils/translations/formatter";

export default function WowMomentComponent({
    onSubmit,
    onCancel,
    client,
    credit,
    matches,
    productName,
    disableCreditButton
}) {
    const [t] = useTranslation("global");
    const styles = themeCredit();
    const navigate = useNavigate()
    const name = client?.first_name ? client?.first_name.charAt(0).toUpperCase() + client?.first_name.slice(1) : ""

    window.analytics.page('PAGEVIEW_REQUEST_NOW', {
        page: 'WC_Request_wow',
        journey: 'WC_Loans',
        product_group: 'Lending',
        product_name: 'Working_Capital',
    });
    
    useEffect(async () => {
        window.analytics.page('PAGEVIEW_REQUEST_WOW', {
            page: 'WC_Request_wow',
            journey: 'WC_Loans',
            product_group: 'Lending',
            product_name: 'Working_Capital',
        });
        if (!client) {
            return navigate("/Credit");
        }
    }, [])

    return (
        <Stack direction="column"
                alignItems="center"
                justifyContent="center" 
                className={styles.boxOptReq}>
            <Card className={styles.wowMomentCard}>
                <Grid className={styles.gridContainer}>
                    <Grid 
                        container
                        display="flex"
                        justifyContent="center"
                        style={{marginBottom:'25px'}}
                    >
                        <img
                            alt="tigo money"
                            className={styles.wowMomentLogo}
                            src={WowMomentLogo}
                        />
                    </Grid>

                    <Grid
                        container
                        justifyContent="center" className={styles.wowMomentGridText} rowSpacing={2}>
                        <Grid item>
                            <Typography className={styles.wowMomentText} style={matches ? {display: "block"} : {display: "none"}}>¡{name}, ya tienes <strong>{formatter(credit?.loanAmount)}</strong> <br />disponibles en {productName}!</Typography>
                            <Typography className={styles.wowMomentText} style={!matches ? {display: "block"} : {display: "none"}} >¡{name}, ya tienes <br /> <strong>{formatter(credit?.loanAmount)}</strong> disponibles <br />en {productName}!</Typography>

                        </Grid>
                        <Box sx={{width: '100%'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Button
                                        {...setButtonLabel(primaryButtonStyles, t("Credit.WowMoment.Buttons.ApplyToCredit"), disableCreditButton)}
                                        btnNext={onSubmit}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button
                                        {...setButtonLabel(secondaryButtonStyles, t("Credit.WowMoment.Buttons.Home"))}
                                        btnNext={onCancel}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </Stack>
    )
}