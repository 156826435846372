import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const actualWidth = window.innerWidth;

const themeContract = makeStyles((theme: Theme) => ({
  contentLogo: {
    marginTop: '-20px', 
    marginLeft: '-40px',
    [theme.breakpoints.down("xs")]: {
      display: 'none'
    }
  },
  contentFooter: {
    display: "flex",
    flexDirection: "column",

    margin: "0px 5px",
    padding: "5px",
  },
  contentView: {
    display: "flex",
    flexDirection: "column",
    webkitBoxShadow: "1px 3px 1px #9E9E9E",
    mozBoxShadow: "1px 3px 1px #9E9E9E",
    boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
    borderRadius: "16px",
    backgroundColor: "white",
    margin: "0px 5%",
    padding: "3% 5% 3% 5%",
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "#ececec",
      margin: "0px 0px 0px 0px",
      padding: "3% 5% 3% 5%",
    }
  },

  containerView: {
    maxWidth: "100%",
    marginTop: "0px",
  },
  toScroll: {
    gridAutoFlow: 'row',
    overflow: 'scroll',
    overflowX: 'hidden',
    "&::-webkit-scrollbar": {
      backgroundColor: '#e9e7e7',
      width: 11
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: '#C4C4C4',
      borderRadius: '10px'
    },
    [theme.breakpoints.down("lg")]: {
      maxHeight: '20vh',
     
    },
    [theme.breakpoints.up("xl")]: {
      maxHeight: '30vh',
     
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: '100%',
      gridAutoFlow: 'none',
      overflow: 'none',
      overflowX: 'none',
      "&::-webkit-scrollbar": {
        backgroundColor: 'none',
        width: 'none',
        display: 'none'
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: 'none',
        borderRadius: 'none'
      },
    }
  }


}));

export default themeContract;
