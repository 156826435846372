import React from 'react';
import ReactDOM from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Theme from "./utils/themes/Theme";
import { ThemeProvider } from "@mui/material/styles";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import global_hn from "./utils/translations/hn/global.json";
import global_bo from "./utils/translations/bo/global.json";
import global_py from "./utils/translations/py/global.json";
import global_sv from "./utils/translations/sv/global.json";
import global_en from "./utils/translations/en/global.json";
import global_es from "./utils/translations/es/global.json";
import countries from "./utils/translations/country.json";
import { AuthProvider } from "react-auth-kit";

const result = window.location.origin;
const div = result.split(".");
let country = "es";
for (const x in div) {
  for (const y in Object.keys(countries)) {
    if (div[x] === Object.keys(countries)[y]) {
      country = div[x];
    }
  }
}

i18next.init({
  lng: country,
  resources: {
    hn: {
      global: global_hn,
    },
    py: {
      global: global_py,
    },
    bo: {
      global: global_bo,
    },
    sv: {
      global: global_sv,
    },
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});


ReactDOM.render(
  <React.StrictMode>
    <AuthProvider
      authType={"localstorage"}
      authName={"_auth"}
      cookieDomain={window.location.hostname}
    >
      <I18nextProvider i18n={i18next}>
        <BrowserRouter>
          <AuthProvider>
            <ThemeProvider theme={Theme}>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </ThemeProvider>
          </AuthProvider>
        </BrowserRouter>
      </I18nextProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
