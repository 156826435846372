const HOST_ = {
    "Digital V2": process.env.REACT_APP_DOMAIN,
    "MiTienda": process.env.REACT_APP_MITIENDA_DOMAIN,
    "default": process.env.REACT_APP_DOMAIN
}
const APIKEY_ = {
    "Digital V2": process.env.REACT_APP_DOMAIN_APIKEY,
    "MiTienda": process.env.REACT_APP_MITIENDA_DOMAIN_APIKEY,
    "default": process.env.REACT_APP_DOMAIN_APIKEY
}
export{
    HOST_,
    APIKEY_
}