import { createContext, useContext} from "react";
import { useCreditContext } from "./CreditProvider";
import axios from "axios";
import {HOST_, APIKEY_} from './Host-ApiKey'
const apiContext = createContext();
export const APIProvider = (
    {children,}) => {
        const {authToken, baseURL, APIKEY, host} = useCreditContext();
    let headers = {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": APIKEY,
                Accept: "application/json",
                "Authorization": authToken
            }
    };
    const error_response = {
        "status": 400,
        "message": "error"
    }
    const get_available_credit = async ({params}) => {
        return axios.get(
            `${baseURL}/credit/amount?${params}`,
            headers
        ).then((response) => {
            return response;
        }).catch((err) => {
            console.log("Error was occurred:" + err);
            return error_response;
        });
    }
    const credit_line_data = async ({params}) => {
        return axios.get(`${baseURL}/credit_line?${params}`, headers).then((response) => {
            return response;
        }).catch((err) => {
            console.log("Error was occurred:" + err);
            return error_response;
        });
    }
    const get_all_credits = async ({params}, client) => {
        let { offset = 0, limit = '', product = '', month= '', year='' } = params;
        return axios.get(
            `${baseURL}/client/${client}/loans?offset=${offset}&limit=${limit}&product=${product}&month=${month}&year=${year}`,
            headers
        ).then((response) => {
            return response;
        }).catch((err) => {
            console.log("Error was occurred:" + err);
            return error_response;
        });
    }
    const create_credit_pending = async ({params}) => {
        let { encodedKey, products, report_name, loanAmount, product } = params; 
        
        let data = {
            encodedKey: encodedKey,
            products,
            report_name,
            loanAmount,
            product
        }
      
        return axios.post(
            `${baseURL}/loan`,
            { ...data },
            headers
        ).then((response) => {
            return response;
        }).catch((err) => {
            console.log("Error was occurred:" + err);
            return error_response;
        });
    }
    const create_transaction = async ({params}) => {
        let {data, param} = params; 
        return axios.post(
            `${baseURL}/transaction?${param}`,
            { ...data },
            headers
        ).then((response) => {
            return response;
        }).catch((err) => {
            console.log("Error was occurred:" + err);
            return error_response;
        });
    }
    const get_products = async ({params}) => {
        return axios.get(
            `${baseURL}/products?${params}`,
            headers
        ).then((response) => {
            return response;
        }).catch((err) => {
            console.log("Error was occurred:" + err);
            return error_response;
        });
    }
    const create_payment = async ({params}) => {
        let {data, param} = params; 
        return axios.post(
            `${baseURL}/payment?${param}`,
            { ...data },
            headers
        ).then((response) => {
            return response;
        }).catch((err) => {
            console.log("Error was occurred:" + err);
            return error_response;
        });
    }
    const get_user = async ({param, token}) => {
        if (token) {
            headers.headers.Authorization = token
        }
        let URL = HOST_['default']
        headers.headers['x-api-key'] = APIKEY_['default']
        if (host) {
            URL = HOST_[host];
            headers.headers['x-api-key'] = APIKEY_[host]
        }
        return axios.get(
            `${URL}/user?${param}`,
            headers
        ).then((response) => {
            return response;
        }).catch((err) => {
            console.log(err.response)
            return error_response;
        });
    }
    const get_invoices = async ({param}) => {
        return axios.get(
            `${baseURL}/loans/${param}/bills`,
            headers
        ).then((response) => {
            return response;
        }).catch((err) => {
            console.log("Error was occurred:" + err);
            return error_response;
        });
    }
    const get_invoice_URL= async ({params}) => {
        let { loan_account_id, invoice_id, date_generated} = params;
        return axios.get(
            `${baseURL}/loans/${loan_account_id}/bills/${invoice_id}/files?date_generated=${date_generated}`,
            headers
        ).then((response) => {
            return response;
        }).catch((err) => {
            console.log("Error was occurred:" + err);
            return error_response;
        });
    }
    const get_credit_loan = async({params})=>{
        return axios.get(`${baseURL}/user_information?${params}`, headers).then((response) => {
            return response;
        }).catch((err) => {
            console.log("Error was occurred:" + err);
            return error_response;
        });
    }
    return (<>
        <apiContext.Provider value={{
           headers: headers,
           get_available_credit,
           credit_line_data,
           get_all_credits,
           create_credit_pending,
           create_transaction,
           get_products,
           create_payment,
           get_user,
           get_invoices,
           get_invoice_URL,
           get_credit_loan
        }}>
            {children}
        </apiContext.Provider>
    </>
    )
}

export const useAPIContext = () => {
    return useContext(apiContext);
}