import React, {useEffect, useState} from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import LogoTigo from '../assets/logoTigo/LogoTigoMoney.png';
import LogoTigoMobile from '../assets/logoTigo/LogoTigoMoneyMobile.svg';
import LogoTigoNone from '../assets/logoTigo/LogoTigoNone.png';
import UserInfoHeader from "../components/credit/userInfoHeader";
import PageHeader from "../components/commons/page-header/page-header";
import { useNavigate, useLocation } from "react-router-dom";
import { getCountry } from "./getCountry";

export default function OnBoardingLayout(
    {
        screen,
        withoutContainer = false ,
        newdashboard = false,
        creditDashboard = false,
        smallcard = true,
        userdata = {},
        isShowingActionHeader,
        headerTitle,
        navigateBack = -1,
        visibilityContent = "visible",
        fullcard = false,
        cardNotification = false,
        cardNotificationNoPadding = false,
        rendersize = false,
        loading = false,
        error = false
    }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [t] = useTranslation("global");

  const navigate = useNavigate();

  const location = useLocation();

  const country = getCountry();

  const getHeaderClassName = () => {
    if (creditDashboard) return 'head-login-container-credit';

    if (newdashboard) return 'head-login-container-v2';

    return 'head-login-container';
  }

  const getCardClassName = () => {
    if(fullcard) return 'body-full-container'

    if(cardNotification && loading) return 'body-card-notification loading'
    
    if(cardNotification && error) return 'body-card-notification error'
    
    if(cardNotification) return 'body-card-notification'

    if(cardNotificationNoPadding) return 'body-card-notification-no-padding'

    if (newdashboard && smallcard) return 'body-login-container-v2';

    return 'body-login-container';
  }

  const selectTigoMoneyLogo =() => {
    if ( country == "PARAGUAY"){
      return LogoTigoNone
    } else {
      return creditDashboard ? LogoTigoMobile : LogoTigo 
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const smallScreen = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xl={11}
          lg={7}
          md={11}
          sm={11}
          xs={11}
          className={getCardClassName()}
        >
          {screen}
        </Grid>
      </Grid>
    )
  }

  const defaultScreen = () => {
    return (
      <Grid
        container
        style={{position: "absolute", top: "90px"}}
      >
        <Grid
          item
          className="body-card-container"
        >
          {screen}
        </Grid>
      </Grid>
    )
  }

  const useScreen = () => {
    if (withoutContainer) {
      return defaultScreen();
    }
    if (rendersize && windowWidth >= 600) {
      return defaultScreen();
    }
    return smallScreen();
  };

  return (
    <div>
      {/* Header */}
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems={isShowingActionHeader ? "left" : "center"}
        className={getHeaderClassName()}
      >

          {isShowingActionHeader && (
              <Box sx={{ display: { xs: 'block', sm: 'none' }}} paddingTop={3}>
                  <PageHeader title={headerTitle} handleBack={()=> { navigate(navigateBack)}} />
              </Box>
          )}

          {!isShowingActionHeader && (
              <Grid item xs={3} display="flex" justifyContent="center"  sx={{ visibility: visibilityContent }}>
                <img
                style={creditDashboard ? { height:"25px", width: "99px", marginBottom: "15px", marginTop: "15px"} : {borderRadius: "8px"} }
                  src={selectTigoMoneyLogo()}
                  alt="tigo money"
                />
              </Grid>
          )}
       {creditDashboard && <UserInfoHeader  userdata={userdata} visibilityContent={visibilityContent} />}
      </Grid>

      {useScreen()}
      {/* Body */}
      {/* Footer */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        className="footer-login-container"
        m={"auto"}
      >
        <Grid
          item
          md={5}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="left"
          textAlign="left"
        >
          <Typography className="text">

            {t("General.Message.Rights")}
          </Typography>
        </Grid>

        <Grid
          item
          md={5}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="space-evenly"
        ></Grid>
      </Grid>
    </div>
  );
}
