import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

const commonStyles = {
    borderRadius: '10px',
};

const themePageToast = makeStyles((theme: Theme) => ({
    warning: {
        ...commonStyles,
        backgroundColor: '#FDF8EB',
    },
    info: {
        ...commonStyles,
        backgroundColor: '#E7EFF9'
    },
    danger: {
        ...commonStyles,
    },
    text: {
        fontFamily: 'Readex Pro !important',
        fontSize: '14px !important'
    }
}));

export default themePageToast;