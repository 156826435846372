import React, { useState, useEffect } from "react";
import GenericForm from "../genericForm"
import dataJson from "../config/signUp-email.json";
import { schema, getYup } from "../../utils/yup";
import {
    signup_, email_signup_
} from "../../actions/axios-api";
import SendEmail from "./sendEmail";

export default function VerifyEmail({ setLoading, showLoginRedirect = true, handleBehavior = () => { }, origin = "", tokenID = "" }) {
    const [data, setData] = useState(dataJson.forms);
    const [validates, setValidates] = useState({});
    const [screen, setScreen] = useState(false);
    const [email, setEmail] = useState("");
    const [body, setBody] = useState({})
    const handleSubmit = async (values) => {
        window.analytics.track("PAGEVIEW_SIGNUP", {
            page: "WC_Signup",
            journey: "WC_Onboarding",
            button_name: "continuar",
            product_group: "Lending",
            product_name: "Working_Capital"
        });
        setLoading(true);
        setEmail(values)

        const response = await signup_({ email: values["email"] })
        if (response.status == 200) {
            setScreen(true); 
            email_signup_(response.data)
            setBody(response.data)
          
        } else {
            
            let newData = { ...data };
            newData.fields[0].props.conditions[0].created = true;
            setData(newData);
      
        }
        handleBehavior(values)
        setLoading(false);
    };

    useEffect(() => {
        let _validates = {};
        data.fields.forEach(({ props, validations }) => {
            _validates[props.name] = schema(validations, props.type);
        });
        setValidates(_validates);
        window.analytics.page("PAGEVIEW_SIGNUP", {
            journey: 'WC_Onboarding',
            name: 'PAGEVIEW_PASSWORD_CREATION',
            page: 'WC_Signup',
            product_group: 'Lending',
            product_name: 'Working_Capital',
        })
        let formStructure = { ...data };
        formStructure.footer.created = showLoginRedirect;
        console.log(formStructure)
        setData(formStructure);
    }, []);

    return (
        <>{screen ? <SendEmail body={body} setLoading={setLoading} /> :
            <GenericForm
                form={data}
                initialValue={{}}
                validates={getYup(validates)}
                onSubmit={handleSubmit}
                isDefault={false}
                setLoginError={() => { }}
                loginError={false}
                disabledButton={false}
                setDisabledButton={() => { }}
            />}
        </>
    );
}
