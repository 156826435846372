import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStylesKym = makeStyles((theme: Theme) => ({
  contentView: {
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "white",
      webkitBoxShadow: "1px 3px 1px #9E9E9E",
      mozBoxShadow: "1px 3px 1px #9E9E9E",
      boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
      borderRadius: "16px",
      margin: "0px 72px 0px 72px",
      p: "5",
    }
  },
  container: {
    height: "100%",
    paddingTop: "0px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px !important",
    }
  },
  box: { 
    width: "100%",
    marginTop: "0px",
  },
}));

export default useStylesKym;
