import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

const themeNotification = makeStyles((theme: Theme) => ({
    staticImage: {
        display: "flex",
        justifyContent: "center",
        marginTop: "63px !important",
        "& img": {
            width: "165px !important",
            height:"93px !important", 
            marginBottom: "15px !important",
            marginTop: "15px !important"
        },
    },
    animatedImage: {
        display: "flex",
        justifyContent: "center",
        "& img": {
            width: "95px !important",
            height:"95px !important",
            marginBottom: "15px !important",
            marginTop: "17px !important",
            position: "absolute",
        },
        "& div": {
            width: "130px",
            height: "130px"
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "63px !important",
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "45px !important",
        },
    },
    imageError:{
        position: "absolute",
        width: "149px",
        height: "160.22px",
        [theme.breakpoints.down("xs")]: {
            top: "120px !important"
        },
        [theme.breakpoints.up("sm")]: {
            top: "35px !important"
        },
    },
    titleContainer: {
        [theme.breakpoints.up("sm")]: {
            paddingLeft: "150px",
            paddingRight: "150px",
        },
        textAlign: "center",
        marginBottom: "5px !important",
        marginTop: "39px !important",
        "& p": {
            fontFamily: "Readex Pro !important",
            fontWeight: "700 !important",
            fontSize: "20px !important",
            lineHeight: "30px !important",
            letterSpacing: "0.25px !important",
        },
    },
    titleContainerError: {
        [theme.breakpoints.down("xs")]: {
            marginBottom: "5px !important",
            marginTop: "300px !important",
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "222px !important",
            maxWidth: "30ch",
            whiteSpace: "pre-wrap"
        },
        textAlign: "center",
        "& p": {
            fontFamily: "Readex Pro !important",
            fontWeight: "500 !important",
            fontSize: "20px !important",
            lineHeight: "25px !important",
            letterSpacing: "0.25px !important",
        },
    },
    messageContainer: {
        textAlign: "center",
    },
    message: {
        fontFamily: "Readex Pro !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "24px !important",
        letterSpacing: "0.25px !important",
    },
    button: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "16px",
        gap: "16px",
        position: "absolute",
        width: "60%",
        height: "44px",
        top: "682px",
        background: "#363853",
        borderRadius: "16px",
    },
    buttonError: {
        [theme.breakpoints.down("xs")]: {
            top: "682px",
            height: "44px",
            width: "311px",
        },
        [theme.breakpoints.up("sm")]: {
            top: "333px",
            height: "48px",
            width: "223px"
        },
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "16px",
        gap: "16px",
        position: "absolute",
        //background: "#363853",
        borderRadius: "16px",
    }
}));


export default themeNotification;