
const buttonName={
    'HISTORY_LOANS_NEXT': 'Siguiente',
    'HISTORY_LOANS_BACK': 'Atrás'
}
const segmentTrack = (name) =>{
    window.analytics.track(name, {
        page: 'WC_History_Loans',
        journey: 'WC_History',
        product_group: 'Lending',
        product_name: 'Working_Capital',
        button_name: buttonName[name]
    });
}
const _back = ({ year, monthNum }) => {
    let newMonthNum = monthNum;
    let newYear = year;
    if (monthNum == 0) {
        newMonthNum = 11;
        newYear = year - 1;

    } else {
        newMonthNum = monthNum - 1;
    }
    return {
        month: newMonthNum,
        year: newYear
    }
}
const _next = ({ year, monthNum }) => {
    let newMonthNum = monthNum;
    let newYear = year;
    if (monthNum == 11) {
        newMonthNum = 0;
        newYear = year + 1;
    } else {
        newMonthNum = monthNum + 1;
    }
    return {
        month: newMonthNum,
        year: newYear
    }
}
const _limit = ({ year, monthNum, limit }) => {
    return year == limit.year && monthNum == limit.month;
}

const _currentDate = ({ year, monthNum, currentDate }) => {
    return year == currentDate.getFullYear() &&
        monthNum == currentDate.getMonth();
}

const _logicOnNext = ({
    year,
    monthNum,
    currentDate,
    onNext,
    onNextSimple,
    onBackSimple
}) => {
    if (_currentDate({
        year: year,
        monthNum: monthNum,
        currentDate: currentDate
    })) {
        onNextSimple(false);
        return;
    }

    let info = _next({
        year: year,
        monthNum: monthNum,
    })
    let newYear = info.year;
    let newMonthNum = info.month;

    onNext({
        newMonthNum: newMonthNum,
        newYear: newYear
    });
    onBackSimple(true);

    if (_currentDate({
        year: newYear,
        monthNum: newMonthNum,
        currentDate: currentDate
    })) {
        onNextSimple(false);
    } else {
        onNextSimple(true);
    }
    segmentTrack('HISTORY_LOANS_NEXT');
    return { 'month': newMonthNum + 1, 'year': newYear }
}
const _logicOnBack = ({
    year,
    monthNum,
    limit,
    onBack,
    onBackSimple,
    onNextSimple
}) => {
    if (_limit({
        year: year,
        monthNum: monthNum,
        limit: limit
    })) {
        onBackSimple(false);
        return;
    }
    onBackSimple(true)

    let info = _back({
        year: year,
        monthNum: monthNum,
    })
    let newYear = info.year;
    let newMonthNum = info.month;

    onBack({
        newMonthNum: newMonthNum,
        newYear: newYear
    });
    onNextSimple(true);

    if (_limit({
        year: newYear,
        monthNum: newMonthNum,
        limit: limit
    })) {
        onBackSimple(false);
    }
    segmentTrack('HISTORY_LOANS_BACK');
    return { 'month': newMonthNum + 1, 'year': newYear }
}
export {
    _back,
    _next,
    _limit,
    _currentDate,
    _logicOnNext,
    _logicOnBack
}