import React, { useEffect, useState, Fragment } from "react";
import MessageError from "../components/loader/messageError";
import Loading from "./Loading";
import TermConditions from "../components/terms-conditions";
import { ThemeBanner } from "../utils/themes/ThemeBanner";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Grid, Card } from "@mui/material";
import themeTAndC from "../utils/themes/ThemeT&C";
import { on_boarding, validate_token, validate_msisdn_exist } from "../actions/axios-api"
import {getData} from "../utils/getKYMdata";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { selectScreen } from "../utils/screen";
export default function TermsAndConditions() {
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state == null ? {} : location.state.data;
    const userEmail = location.state == null ? {} : location.state.userEmail;
    const password = location.state == null ? {} : location.state.password;
    const token = location.state == null ? {} : location.state.token;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const bannerStyles = ThemeBanner();
    const styles_ = themeTAndC();
    const theme = useTheme();

    const _screen =  {state: {
        type: {
            data: {
                image: t("ScreenMessage.Remedy.ReceivedDocument.Image"),
                title: t("ScreenMessage.Remedy.ReceivedDocument.Title"),
                subTitle: t("ScreenMessage.Remedy.ReceivedDocument.SubTitle"),
                default: false,
            },
        },
    },}

    const validate = async (isDeleted) => {
        let data = { "isDeleted": isDeleted, "token": token }
        await validate_token(data)
    }
    const naviagetScreen = async (screen, state) => {
        navigate(screen, {
            replace: true,
            screen: screen,
            state: state
        });
    }
    const logic_legacy = async () => {
        setLoading(true)
        create_account_(); 
    }
    const create_account_=async(account)=>{
        const responseCreateAccount = await on_boarding(account);
        if (responseCreateAccount.status !== 200) {
            setLoading(false)
            setError(true)
        } else {
            let state = {
                id_pdv: data["Negocio"]["facturation-information"]["id_pdv"],
                email: data.email,
            }
            let title = "ScreenMessage.Contract.Title";
            let subTitle = "ScreenMessage.Contract.SubTitle";

            await validate(true)
            setLoading(false);
            const response = await selectScreen(data, _screen)
            if (response == "/MessageScreen"){
                state = {
                    type: {
                        data: {
                            image: "finish-contract",
                            title: title,
                            subTitle: subTitle,
                        },
                    },
                }
            }
            await naviagetScreen(response, state)

        }
    }
    const validateMtsUSer = async () => {
        const response = await validate_msisdn_exist(data['Negocio']?.["owner-information"]["active_cell_number"])

        if (response?.data.have_account) {
            if (data["signed_contract"]) {
                const account = getData(data, password, origin, response.data.have_account, response.data.client_id, true)
                create_account_(account);
            } else {
                navigate("/Contract", {
                    replace: true,
                    screen: "/Contract",
                    state: {
                        data: data,
                        id_pdv: data["Negocio"]["facturation-information"]["id_pdv"],
                        email: userEmail,
                        password: password
                    },
                });
            }

        } else {
            navigate("/MessageScreen", {
                screen: "MessageScreen",
                state: {
                    type: {
                        data: {
                            title: "TitleError",
                            subTitle: "SubTitleError",
                            image: "ImageError"
                        },
                    },
                },
            });
        }

    }
    useEffect(async() => {
        setLoading(true);
        await validateMtsUSer(); 

    }, []);
    let matches = useMediaQuery(theme.breakpoints.up("sm"));
    return (<Fragment>
        {loading && <Loading></Loading>}
        {error && <MessageError></MessageError>}
        <div style={{ display: (loading || error) ? "none" : "block" }}>
            <Grid className={matches ? bannerStyles.bannerOne_web : bannerStyles.bannerOne}>
                <div className={styles_.cardProduct}>
                    <Card className={styles_.CardsProducts}>
                        <Grid container sx={{ padding: { xs: '25px' }, paddingBotton: { sm: '-25px !important' } }}>
                            <Grid mt={2} container display="flex" className={styles_.principalCard}>
                                <Grid item sx={{ marginLeft: { sm: '30px' } }}>
                                    <TermConditions
                                        handleStart={logic_legacy}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            </Grid>
        </div>
    </Fragment>)
}
