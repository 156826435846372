import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

const themeCredit = makeStyles((theme: Theme) => ({
    onBack:{
        marginLeft: '-25px',
        [theme.breakpoints.only("xs")]: {
            display: 'none !important'
        },
        [theme.breakpoints.down("xs")]: {
            display: 'none !important'
        },
    },
    monthText: {
        fontFamily: 'Readex Pro',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '24px',
        letterSpacing: '0.24699999392032623px',
        textAlign: 'left',
        marginLeft: '-13px'
    },
    name: {
        maxWidth: '48%',
        marginTop: '30px !important',
        marginLeft: '7% !important',
        fontFamily: 'Readex Pro !important',
        fontStyle: "normal !important",
    },
    amount: {
        marginTop: '30px !important',
        marginRight: '7% !important',
        fontFamily: 'Readex Pro !important',
        fontStyle: "normal !important",
    },
    circle: {
        background: "#363853",
        borderRadius: "50%",
        width: "27px",
        height: "27px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
    },
    iconUser: {
        background: "#363853"
    },
    textUserName: {
        fontFamily: "Readex Pro",
        fontStyle: "normal",
        fontWeight: "400",
        letterSspacing: "0.247px",
        color: "#343C46",
    },
    textTitleBalance: {
        marginBottom: '2px',
        fontFamily: "Readex Pro !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "12px !important",
        lineHeight: "1.5 !important",
        textAlign: "right !important",
        letterSpacing: "0.8px !important",
        color: "#363853 !important",
        alignItems: "center !important",
        marginRight: "15px !important",
    },
    textTitleBalancePayment: {
        fontFamily: "Readex Pro !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "12px !important",
        lineHeight: "1.5 !important",
        textAlign: "right !important",
        letterSpacing: "0.247px !important",
        color: "#363853 !important",
        alignItems: "center !important",
        marginRight: "20px !important"
    },
    textAmountBalance: {
        fontFamily: 'Readex Pro !important',
        fontStyle: "normal !important",
        fontWeight: "700 !important",
        fontSize: "12px !important",
        lineHeight: "1.5 !important",
        textAlign: "right !important",
        letterSpacing: "0.247px !important",
        color: "#363853 !important",
    },
    gridUserCenter: {
        display: "flex",
        alignItems: "center",
    },
    textBalance: {
        [theme.breakpoints.up("md")]: {
            fontSize: "16px !important",
            lineHeight: "24px !important"
        },
        fontFamily: 'Readex Pro !important',
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        letterSpacing: "0.247px",
        color: "#363853"
    },
    textAmount: {
        [theme.breakpoints.up("md")]: {
            fontSize: "16px !important",
            lineHeight: "24px !important"
        },
        fontFamily: 'Readex Pro !important',
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "12px !important",
        lineHeight: "15px",
        letterSpacing: "0.247px",
        color: "#323232"
    },
    textBalanceSub: {
        [theme.breakpoints.up("md")]: {
            fontSize: "14px !important",
            lineHeight: "22px !important"
        },
        fontFamily: 'Readex Pro !important',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "14px",
        letterSpacing: "0.247px",
        color: "#708195"
    },
    textAmountSub: {
        [theme.breakpoints.up("md")]: {
            fontSize: "16px !important",
            lineHeight: "24px !important"
        },
        fontFamily: 'Readex Pro !important',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        letterSpacing: "0.247px",
        color: "#323232",
    },
    button: {
        [theme.breakpoints.up("sm")]: {
            width: '145px',
        },
        width: "100%",
        height: "44px",
        marginTop: "10px"
    },
    textbutton: {
        [theme.breakpoints.up("sm")]: {
            fontSize: '14px !important',
            height: "48px"
        }
    },
    messageNoCredit: {
        [theme.breakpoints.down("sm")]: {
            //width: "279px",
            //height: "36px",
        },
        paddingLeft: '10px',
        fontFamily: 'Readex Pro !important',
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "20px !important",
        letterSpacing: "0.247px !important",
        color: "#4C5866 !important",
        textAlign: 'left !important'
    },
    messageNoAmount: {
        [theme.breakpoints.up("sm")]: {
            fontSize: "14px !important",
            lineHeight: "22px !important",
            textAlign: 'left !important',
            color: 'var(--text-charcoal-2, #4C5866)'
        },
        fontFamily: 'Readex Pro !important',
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "12px !important",
        lineHeight: "18px !important",
        letterSpacing: "0.247px !important",
        color: "var(--text-charcoal-2, #4C5866) !important",
        textAlign: 'left !important'
    },
    divMessageNoCredit: {
        [theme.breakpoints.up("sm")]: {
            padding: "16px 8px",
        },
        padding: "8px",
        alignContent: "center",
        textAlign: "center",
        //width: "311px",
        //height: "62px",
        background: "#FAEDEA",
        border: "1px solid #FAEDEA",
        borderRadius: "8px",
    },
    divMessageNoAmount: {
        [theme.breakpoints.up("sm")]: {
            padding: '16px'
        },
        backgroundColor: '#FDF8EB',
        padding: '8px'
    },
    // styles for userInfoHeader - end

    // styles for allCredits - start
    containerHomeCredit: {
        [theme.breakpoints.up("sm")]: {
            width: '88% !important',
            borderRadius: '24px',
            boxShadow: '0px 4px 12px 0px rgba(194, 209, 217, 0.46)',
            padding: '60px'
        },
        padding: '0px 20px 0px 20px',
        background: "#FFFFFF",
        marginTop: "20px !important",
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px'
    },
    filterCreditTab: {
        [theme.breakpoints.down("xs")]: {
            height: '100px',
            background: 'linear-gradient(180deg, rgba(255,216,88,1) 22%, rgba(250,190,0,1) 100%)',
            borderBottomLeftRadius: '30px',
            borderBottomRightRadius: '30px'
        },
        [theme.breakpoints.up("sm")]: {
            height: '185px',
            background: 'linear-gradient(180deg, #FABF00 33.4%, #FFD445 61.66%, #FFDC6A 90.17%), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FABE00',
        },
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        maxWidth: "96px",
        minHeight: "32px !important",
        height: "32px !important",
        padding: "0px !important",
        borderRadius: "16px 16px 0px 16px !important",
        fontFamily: 'Readex Pro !important',
        fontStyle: "normal !important",
        fontSize: "12px !important",
        lineHeight: "18px !important",
        letterSpacing: "0.247px !important",
        marginRight: "8px !important",

        "&.MuiTab-root": {
            background: "#F0F2F4",
            fontWeight: "400 !important",
            color: "#708195 !important",
            minHeight: "32px !important",
            height: "32px !important",
        },
        "&.Mui-selected": {
            background: "#FABE00",
            fontWeight: "500 !important",
            color: "#343C46 !important",
        }
    },
    allCreditsContainer: {
        paddingTop: "20px !important",
        background: "#FFFFFF !important",
        borderTopLeftRadius: '21px !important',
        borderTopRightRadius: '21px !important',
    },
    allCreditsContainer_: {
        paddingTop: "20px !important",
        background: "#FFFFFF !important",
        borderTopLeftRadius: '21px !important',
        borderTopRightRadius: '21px !important',
        textAlign: 'center !important',
        overflowY: 'hidden !important'
    },
    filterContainer: {
        marginBottom: "14px !important",
    },
    creditsContainer: {
        [theme.breakpoints.up("sm")]: {
            width: '88% !important',
            borderRadius: '24px',
            boxShadow: '0px 4px 12px 0px rgba(194, 209, 217, 0.46)',
            padding: '60px'
        },
        padding: '0px 20px 0px 20px',
        background: "#FFFFFF",
        marginTop: "20px !important",
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px'
    },
    creditsContainer_: {
        overflowY: 'hidden !important',

        [theme.breakpoints.up("sm")]: {
            width: '88% !important',
            borderRadius: '24px',
            boxShadow: '0px 4px 12px 0px rgba(194, 209, 217, 0.46)',
            padding: '60px'
        },
        padding: '0px 20px 0px 20px',
        background: "#FFFFFF",
        marginTop: "20px !important",
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px',
        textAlign:'center !important'
    },
    gridCreditContainer: {
        width: "100% !important",
        "&.MuiGrid-root": {
            maxWidth: "100%"
        },
    },
    gridCreditContainer_: {
        overflowY: 'hidden !important',
        width: "100% !important",
        textAlign:'center !important',

        "&.MuiGrid-root": {
            maxWidth: "100%"
        },
    },
    gridCreditsContainer: {
        maxHeight: "80% important"
    },
    paperContainer: {
        height: 650,
        overflow: "auto",
        [theme.breakpoints.down("xl")]: {
            height: 500,
            width: '100%'
        },
        [theme.breakpoints.down("lg")]: {
            height: 280,
            width: '100%'
        },
        [theme.breakpoints.down("sm")]: {
            height: 400,
            width: '100%'
        },
        [theme.breakpoints.down("xs")]: {
            height: 550,
            width: "89%",
        },
    },
    paperContainer_: {
        overflowY: 'hidden !important',
        height: '100%',
        overflow: "auto",
        width: '100%'
        // [theme.breakpoints.down("xl")]: {
        //     height: 500,
        //     width: '100%'
        // },
        // [theme.breakpoints.down("lg")]: {
        //     height: 280,
        //     width: '100%'
        // },
        // [theme.breakpoints.down("sm")]: {
        //     height: 400,
        //     width: '100%'
        // },
        // [theme.breakpoints.down("xs")]: {
        //     height: 550,
        //     width: "89%",
        // },
    },
    titleProduct: {
        marginLeft: "23px !important",
        fontFamily: 'Readex Pro !important',
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        letterSpacing: "0.247px",
        color: "#4C5866",
        paddingBottom: "16px"
    },
    infoProductTitle: {
        marginLeft: "17px !important",
        width: "280px !important",
        fontFamily: 'Readex Pro',
        fontWeight: "500 !important",
        fontSize: "16px",
        lineHeight: "18px",
        letterSpacing: "0.247px",
        color: "#4C5866",
    },
    infoProductImageTitle: {
        marginLeft: "4px !important",
        width: "280px",
        fontFamily: 'Readex Pro !important',
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        color: "#4C5866",
    },
    infoImageDivEMoney: {
        backgroundColor: "#FFD551",
        textAlign: 'center',
        borderRadius: "2px",
        display: "block"
    },
    infoImageDivEPin: {
        backgroundColor: "#DEEDFF",
        textAlign: 'center',
        borderRadius: "2px",
        display: "block",
    },
    infoImage: {
        marginTop: '5px',
        width: '20px'
    },
    infoProductMoney: {
        marginBottom: "35px !important",
        width: "280px",
        fontFamily: 'Readex Pro',
        fontWeight: "300",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.247px",
        color: "#4C5866",
    },
    infoProductPin: {
        marginBottom: "7px !important",
        width: "280px",
        fontFamily: 'Readex Pro',
        fontWeight: "300",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.247px",
        color: "#4C5866",
    },
    gridProduct: {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    CardsProducts: {
        background: "#FFFFFF",
        border: "1px solid #F3F3F3 !important",
        boxShadow: "2px 4px 4px rgba(185, 185, 185, 0.25) !important",
        borderRadius: "21px !important",
        paddingBottom: '8px',
        marginTop: '15px'
    },
    CardsProductsInfo: {
        background: "#FFFFFF",
        border: "1px solid #F3F3F3 !important",
        boxShadow: "2px 4px 4px rgba(185, 185, 185, 0.25) !important",
        borderRadius: "21px !important",
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    // styles for allCredits - end

    // styles for wowMoment - start
    boxOptReq: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "-52px",
            paddingBottom: "3px",
            paddingLeft: "36px",
            paddingRight: "36px",
        }
    },
    gridContainer: {
        [theme.breakpoints.up("sm")]: {
            paddingRight: "60px",
            paddingLeft: "60px"
        }
    },
    wowMomentLogo: {
        [theme.breakpoints.up("sm")]: {
            position: "absolute",
            marginTop: "-74px !important",
            width: '279.14px',
        },
    },
    wowMomentCard: {
        [theme.breakpoints.down("xs")]: {
            background: 'transparent !important',
            boxShadow: '0px 0px 0px 0px !important',
        },
        [theme.breakpoints.up("sm")]: {
            borderRadius: '24px !important',
            boxShadow: '2px 4px 4px rgba(185, 185, 185, 0.25) !important',
            width: "598px  !important",
            justifyContent: "center  !important",
            alignItems: "flex-start  !important",
            background: '#ffffff !important',
            marginTop: "80px !important",
            paddingBottom: "40px !important"
        },
    },
    wowMomentGridText: {
        [theme.breakpoints.down("xs")]: {
            paddingBottom: "15%",
            marginTop: "30px !important"
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "215px !important",
        }
    },
    wowMomentText: {
        fontFamily: 'Readex Pro !important',
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "20px !important",
        lineHeight: "30px !important",
        textAlign: "center !important",
        letterSpacing: "0.247px !important",
        color: "#343C46",
        [theme.breakpoints.down("xs")]: {
            paddingBottom: "50%",
        },
        [theme.breakpoints.up("sm")]: {
            paddingBottom: "25%",
        },
    },
    // styles for wowMoment - end
}));


export default themeCredit;