
import React from "react";
import { useTranslation } from "react-i18next";
import { ThemeBanner } from "../../utils/themes/ThemeBanner";
import { Grid, Stack } from "@mui/material";
import PageHeader from "../commons/page-header/page-header";
import { useNavigate } from "react-router-dom";
export default function ReturnPage({children, matches}){
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const bannerStyles = ThemeBanner();
    const handleClickBack = () => {
        return async () => {
            await window.analytics.track("REQUEST_PRODUCT_BACK", {
                page: "WC_Request_Product",
                journey: "WC_Loans",
                product_group: "Lending",
                product_name: "Working_Capital",
                button_name: "Regresar"
            });
            navigate("/Credit")
        }
    }

    return <Stack className={matches ? bannerStyles.bannerOne_web : bannerStyles.bannerOne} spacing={2}>
        <Grid sx={{ display: { xs: 'block', sm: 'none' } }} paddingY={3}>
            <PageHeader

                title={t("Credit.products.title")} handleBack={ handleClickBack() } />
        </Grid>
        {children}

    </Stack>
}