import React from 'react'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';
import {useTranslation} from "react-i18next";
import {styled} from '@mui/material/styles';

export default function LoadWallet() {
    const [t] = useTranslation("global");

    const BootstrapTooltip = styled(({className, ...props}: TooltipProps) => (
        <Tooltip {...props} arrow classes={{popper: className}}/>
    ))(({theme}) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: "#363853",
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#363853",
            maxWidth: 200,
            marginLeft: "90px"
        }
    }));

    return (
        <BootstrapTooltip title={t("Credit.header.message")} enterTouchDelay={1}>
            {/* <IconButton sx={{ padding: 0 }}> */}
                <AddCircleOutlineRoundedIcon fontSize="small"  data-testid="icon-btn" sx={{
                    color: "#363853", marginTop:'-5px', marginLeft:'-5px'
                }}/>
            {/* </IconButton > */}
        </BootstrapTooltip>

    )
}
