import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const actualWidth = window.innerWidth;

const themeForgotPassword = makeStyles((theme: Theme) => ({
 imageBanner:{
    display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "65px"
 }

}));

export default themeForgotPassword;
