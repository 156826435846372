import React from "react";
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";

const LinkComponent = (props) => {
  const [t] = useTranslation("global");
  const reloadPage = () =>{
    window.location = window.location.href.split("?")[0];
  }
  const saveSigninRedirect = () => {
    window.analytics.track('SIGNIN_REDIRECT', {
      page: 'WC_Login',
      journey: 'WC_Onboarding',
      product_group: 'Lending',
      button_name: 'Olvidé mi contraseña',
      product_name: 'Working_Capital',
    });
  }
  const functionReturn ={
    reloadPage: reloadPage,
    saveSigninRedirect: saveSigninRedirect,
  }
  const elements = {
    to: props.props.route,
    onClick: functionReturn[props.props.onClick]
  }

  return <div style={props.props.style}>
    <Link {...elements} style={props.props.extraStyles} className="text-link">
      {t(props.props.textlink)}
    </Link>
  </div>;

};

export default LinkComponent;
