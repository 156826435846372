import React from "react";
import { useField } from "formik";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = (_value) =>
  makeStyles((_theme) => ({
    root: {
      "& .Mui-error": {
        color: "#000000 !important",
      },
      "& .MuiFormHelperText-root": {
        color: "#b00020 !important",
      },
      "& Label.Mui-error": {
        color: "#b00020 !important",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: `8px`,
        },
      },
    },
  }));

export default function Email({ props, loginError, setLoginError, setDisabledButton }) {
  const [field, meta] = useField(props.name);
  const [t] = useTranslation("global");
  const configTextField = {
    ...field,
    ...props,
    label: t(props.label),
  };
  const handleChange= (e)=>{
    field.onChange(e);
    setLoginError(false);
    setDisabledButton(false);
  }  
  const classes = useStyles();
  return (
    <div>
      <TextField
        error={
          (meta.touched && meta.error !== undefined) ||
          props.conditions[0].created || loginError?.type == "password"
        }
        {...configTextField}
        variant="outlined"
        style={ props.style }
        className={classes.root}
        onInput={(event) => {
          if (event.target.value === "") {
            props.conditions[0].created = false;
          }
        }}
        onChange={(e) => handleChange(e)}
      />

      {props.conditions[0].created ? (
        <div>
          <span className="text">
            {t(props.conditions[0].text)}{" "}
            <Link to={props.conditions[0].route}>
              {t(props.conditions[0].link)}
            </Link>
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
