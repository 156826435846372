import BlockedPaths from './BlockedPaths.json'
import { encode, decode } from '../encode-decode'
const CreateSession = (props) => {
    let session = {}
    for (let key in props) {
        if (props[key] !== undefined) {
            session[key] = props[key]
        }
    }
    sessionStorage.setItem('ClientSesion', encode(JSON.stringify(session)));

}
const UpdateSession = (props) => {
    let session = {}
    for (let key in props) {
        session[key] = props[key];
    }
    sessionStorage.setItem('ClientSesion', encode(JSON.stringify(session)));

}
const DeleteSession = () => {
    sessionStorage.removeItem('ClientSesion');
}
const GetSession = () => {
    let decode_ = decode(sessionStorage.getItem('ClientSesion')); 
    let ClientSession = JSON.parse(decode_)
    return ClientSession;
}
const StandarName = (value) => {
    return value[0].toUpperCase() + value.slice(1);
}
const PersistSession = (path, handleUpdate, ClientSession) => {
    if (!ClientSession?.['AuthToken'] && window.location.pathname === '/Credit') {
        window.location.href = '/SignIn';
    }
    if (path || path !== undefined) {
        return
    }
    if (window.location.pathname !== '/Credit' && !path) {
        handleUpdate(true);
    }
    if (BlockedPaths[window.location.pathname.replace('/', '').toUpperCase()]) {
        if (!isReloaded()) {
            window.location.href = '/Credit';
        }
    }
}

const isReloaded = () => {
    return performance.navigation.type == performance.navigation.TYPE_RELOAD;
}
export {
    UpdateSession,
    CreateSession,
    GetSession,
    DeleteSession,
    StandarName,
    BlockedPaths,
    PersistSession,
    isReloaded
}