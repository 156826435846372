import React, {useEffect, useState} from 'react'
import {Button, Grid, Typography, Stack} from "@mui/material";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import Bar from "./bar"
import {useTranslation} from "react-i18next";
import useStylesCredit from "../../utils/themes/ThemeCredit";
import {getCurrency} from "../../utils/getCountry";
import currencyCode from "../../utils/translations/countryCode.json"
import { formatNumber } from "../../utils/formatNumber"
import { useNavigate } from "react-router-dom";
import PageToast from "../commons/page-toast/page-toast";
import {useCreditContext} from "../../utils/providers/CreditProvider";

export default function Balance({props}) {
    const [t] = useTranslation("global");
    const theme = useStylesCredit();
    const currency = getCurrency()
    const [flag, setFlag] = useState(false)
    const [code, setCode] = useState("")
    const navigate = useNavigate();
    const {isAbbreviate, isOverLimit, documentStatus, session} = useCreditContext();

    useEffect(() => {
        setCode(currencyCode[props.currency])
        if (props.available_credit < 100000) {
            setFlag(true)
        }
    }, []);

    const handleUploadDocument = () => {
        navigate('/Remedy', {"state": {"id_pdv": session?.idpdv}})
    };

    const needAllowUploadDocuments = () =>{
        return (isAbbreviate && isOverLimit && documentStatus === 'rejected');
    }
    const needValidDocuments = () =>{
        return (isAbbreviate && isOverLimit && documentStatus === 'pending');
    }
    const onCreditRequest = async () => {
        window.analytics.track('HOME_REQUEST_START', {
            page: 'WC_Home',
            journey: 'WC_Loans',
            product_group: 'Lending',
            product_name: 'Working_Capital',
            button_name: 'Confirmar'
        });
        navigate("/Product")
    };
    const component = () => {

        const isArrears = props.arrears === "true";
        const availableCredit = props.available_credit < 100000;

        const isNeedingValidDocumentation = needValidDocuments();
        const allowUploadDocuments = needAllowUploadDocuments();
        const isNeedingDocumentationText = t("Credit.balance.isNeedingDocumentationText");

        if (allowUploadDocuments) {
            return (
               <Stack spacing={3} pt={3}>
                   <PageToast message={isNeedingDocumentationText} type='warning' />
                   <Button
                       onClick={handleUploadDocument}
                       variant="contained">
                       {t("Credit.balance.buttons.uploadDocuments")}
                       {/*TODO: Translation */}
                   </Button>
               </Stack>
            )
        }


        if (isNeedingValidDocumentation) {
            return (
                <Stack pt={3}>
                    <PageToast message={isNeedingDocumentationText} type='warning' />
                </Stack>
            );
        }

        if (isArrears) {
            return (
                <Grid container className={theme.divMessageNoCredit} mt={2}>
                    <Grid item xs={1} sm={2} lg={1}>
                        <ReportProblemOutlinedIcon style={{color: "#D95A3A", marginTop: "4px"}}/>
                    </Grid>
                    <Grid item xs={11} sm={10} lg={11}>
                        <Typography className={theme.messageNoCredit} component="div" align="center">
                            {t("Credit.balance.messageCredit")}
                        </Typography>
                    </Grid>
                </Grid>
            );
        }

        if (availableCredit) {
            return (
                <Grid container className={theme.divMessageNoAmount} mt={2}>
                    <Grid item xs={12}>
                        <Typography component="div" align="center" className={theme.messageNoAmount}>
                            {t("Credit.balance.messageNotAmount")}
                        </Typography>
                    </Grid>
                </Grid>
            );
        }



        return (
            <div className={theme.button}>
                <Button className={theme.textbutton}
                    onClick={onCreditRequest}
                    variant="contained">
                    {t("Credit.buttons.creditRequest")}
                </Button>
            </div>
        );
    }

    return (
        <>{code &&
            <Grid mt={4} container display="flex">
                <Grid item xs={6}>
                    <Typography className={theme.textBalance} align="left">
                        {t("Credit.balance.credit")}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={theme.textAmount} align="right">
                        {currency} {formatNumber(code, props.credit_amount)}
                    </Typography>
                </Grid>
                <Grid item xs={12}mb={2} mt={2}>
                    <Bar props={props} flag={flag}/>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="column">
                        <Grid className={theme.textBalanceSub} align="left">{t("Credit.balance.balanceConsumed")}</Grid>
                        <Grid className={theme.textAmountSub}
                              align="left">{currency} {new Intl.NumberFormat(code).format(props.consumed_credit)}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} mb={2}>
                    <Grid container direction="column">
                        <Grid item xs={6} className={theme.textBalanceSub}
                              align="right">{t("Credit.balance.balanceAvailable")}</Grid>
                        <Grid item xs={5} className={theme.textAmountSub}
                              align="right">{currency} {new Intl.NumberFormat(code).format(props.available_credit)}</Grid>
                    </Grid>
                </Grid>
                <>{component()}</>
            </Grid>
        }</>)
}
