import jwt_decode from "jwt-decode";
import sign from "jwt-encode";

const getClientId = (auth) => {
  return getMtsToken(auth)?.CLIENT_ID;
};

const getDeviceId = (auth) => {
  return getMtsToken(auth)?.DEVICE_ID;
};

const getDeviceKey = (auth) => {
  return getMtsToken(auth)?.DEVICE_KEY;
};

const getMtsToken = (auth) => {
  return getMtsTokenId(auth?.authToken?.IdToken);
};

const getMtsTokenId = (IdToken) => {
  const token = IdToken ? jwt_decode(IdToken) : undefined;
  return token ? JSON.parse(window.atob(token?.mtsToken)) : undefined;
};

const encodeJWT = (data) => {
  const secret = "m3rch4nT";
  return sign(data, secret);
};

export {
  getClientId,
  getDeviceId,
  getMtsToken,
  getMtsTokenId,
  getDeviceKey,
  encodeJWT,
};
