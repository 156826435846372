import React, { useEffect, useState } from "react";
import { Card, CardActions, CardContent, Collapse, Grid, IconButton, Typography } from "@mui/material";
import CardCredit from "./active-credits/card-credit";
import { useTranslation } from "react-i18next";
import themeActiveCredit from "../../utils/themes/ThemeCredit";
import themeProduct from "../../utils/themes/ThemeProduct";
import PageHeader from "../commons/page-header/page-header";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Emoneys from '../../assets/credit/emoneyshort.svg';
import Epins from '../../assets/credit/epinshort.svg';
import { useCreditContext } from "../../utils/providers/CreditProvider";
import { useNavigate } from "react-router-dom";
import { useAPIContext } from "../../utils/providers/APIProvider";

const HomeProduct = ({ validateError, loading, matches, client }) => {
    let productSelected = [];
    const [t] = useTranslation("global");
    const styles = themeActiveCredit();
    const styles_ = themeProduct();
    const [expanded, setExpanded] = useState(false);
    const { setProductName, setProducts, setProduct, products, setReloadInfo, setReloadInfoProduct, reloadInfoProduct, handleBack, setReloadDocument} = useCreditContext();
    const navigate = useNavigate();
    const {get_products} = useAPIContext();
    let response;
    useEffect(async () => {
        await window.analytics.page("PAGEVIEW_REQUEST_PRODUCT", {
            page: "WC_Request_Product",
            journey: "WC_Loans",
            product_group: "Lending",
            product_name: "Working_Capital"
        });
        loading(true)
        if (!client) {
            return navigate("/Credit");
        }
        if(reloadInfoProduct){
            response = await get_products({params: `is_pdv=${client?.is_pdv}`})
        }
        if (response?.status === 400) {
            loading(false)
            validateError(true)
            setReloadInfoProduct(true)
        }
        else {
            loading(false)
            setReloadInfoProduct(false)
        }
        for (const x in response?.data) {
            productSelected.push(response.data[x].productName)
        }
        setProducts(response.data)
    }, []);

    const handleExpandClick = () => {
        window.analytics.track("REQUEST_INFO", {
            page: "WC_Request_Product",
            journey: "WC_Loans",
            product_group: "Lending",
            product_name: "Working_Capital",
            button_name: "Conoce más"
          });
        setExpanded(!expanded);
    };

    const handlerClick = async ({product}) => {
        await window.analytics.page(`REQUEST_PRODUCT_${product?.productName?.toLowerCase() == 'tigo money' ? 'EMONEY' : 'EPIN'}`, {
            page: "WC_Request_Product",
            journey: "WC_Loans",
            product_group: "Lending",
            product_name: "Working_Capital",
            buton_name: product.productName
        });

        await setProductName(product.productName)
        await setProduct(product)
        navigate("/ConfigureCredit")
    };

    const handleClickBack = () => {
        setReloadDocument(false)
        if(handleBack){
            setReloadInfo(true)
        }else{
            setReloadInfo(false)
        }
        return async () => {

            await window.analytics.track("REQUEST_PRODUCT_BACK", {
                page: "WC_Request_Product",
                journey: "WC_Loans",
                product_group: "Lending",
                product_name: "Working_Capital",
                button_name: "Regresar"
            });
            navigate("/Credit")
        }
    }
    
    const buildCard = (product) => {
        let cardInfo = {
            generic: true,
            type: product.productName,
            url: "/",
            style: {},
            isFunction: true,
            handlerFunction: handlerClick,
            product: product
        };
        return <Grid item xs={12} sm={6} className={styles.gridProduct} ><CardCredit {...cardInfo} /></Grid>
    }
    return (products ?
        <div>
            
            <div className={styles_.cardProduct}>
            
                <Card className={styles_.CardsProducts}>
                <Grid sx={{ display: { xs: 'none', sm: 'block' }, marginBottom:'10px' }} paddingY={0}>
                        <PageHeader
                            title={t("Buttons.Back")} handleBack={ handleClickBack() } />
                    </Grid>
                    <Grid 
                        mt={2} 
                        container
                        justifyContent = {'start'}
                        className={styles_.principalCard}>
                        <Grid item xs={12} md={1}>
                            <Typography className={styles_.titleProduct}>
                                {!matches ? t("Credit.products.header") : t("Credit.products.header_web")}
                            </Typography>
                        </Grid>
                        <Grid container>
                            {products.map((product) => {
                                return buildCard(product)
                            })}
                        </Grid>
                    </Grid>
                </Card>
            </div>
            <div className={styles_.secondCard}>
                <Card  className={styles.CardsProductsInfo}>
                    <CardActions disableSpacing>
                        <Typography className={styles.infoProductTitle}>{t("Credit.products.infoTitle")}</Typography>
                        <IconButton
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            style={{ marginLeft: "auto" }}
                            data-testid= "expanded-product-info"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item xs={1}>
                                    <div className={styles.infoImageDivEMoney}>
                                        <img
                                            className={styles.infoImage}
                                            src={Emoneys}
                                            alt={"Tigo Money"}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={8} >
                                    <Typography className={styles.infoProductImageTitle}>{t("Credit.products.infoTitleProductEMoney")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography className={styles.infoProductMoney}>{t("Credit.products.infoEmoney")}</Typography>
                            </Grid>
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item xs={1}>
                                    <div className={styles.infoImageDivEPin}>
                                        <img
                                            className={styles.infoImage}
                                            src={Epins}
                                            alt={"Tigo Money"}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={5} >
                                    <Typography className={styles.infoProductImageTitle}>{t("Credit.products.infoTitleProductEPin")}</Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography className={styles.infoProductPin}>{t("Credit.products.infoEpin")}</Typography>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Collapse>
                </Card>
            </div>
        </div> : <></>
    )
}
export default HomeProduct;