import React, { useState, useEffect, Fragment } from 'react';
import { useLocation, useNavigate} from "react-router-dom";
import { useAPIContext } from '../utils/providers/APIProvider';
import themeDetails from '../utils/themes/ThemeDetails';
import CardCredit from '../components/credit/active-credits/card-credit';
import { Grid, Typography, Stack, Card} from '@mui/material';
import Divider from '@mui/material/Divider';
import { useTranslation } from "react-i18next";
import Button from '../components/inputs/button';
import formatter from '../utils/translations/formatter';
import { calculateDueDate_loan } from '../utils/getCardInfo';
import { getFormattedDate } from "../utils/functions/utils";
import { InteresAndTotal } from '../utils/getProductInfo';
import { ThemeBanner } from '../utils/themes/ThemeBanner';
import PageHeader from '../components/commons/page-header/page-header'
import { useCreditContext } from "../utils/providers/CreditProvider";
import UserInfoHeader from '../components/credit/userInfoHeader';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import themeConfigureCredit from '../utils/themes/ThemeConfigureCredit';
import themeProduct from '../utils/themes/ThemeProduct';
import InvoiceCard from '../components/invoice/invoice-card';

const days = {
    "DAYS": 1,
    "MONTHS": 30,
    "YEAR": 365
}

const DetailsScreen = () => {
    const styles_ = themeProduct();
    const styles_cc = themeConfigureCredit();
    const [dataDetails, setDataDetails] = useState({});
    const location = useLocation();
    const styles = themeDetails();
    const [t] = useTranslation("global");
    const data = location.state.data;
    const navigate = useNavigate();
    const theme = useTheme();
    const {
        get_invoices,
        get_invoice_URL
    } = useAPIContext();

    const [invoicesData, setInvoicesData] = useState([]);

    let btnDesktopConfig = {
        btn: {
            type: 'button',
            color: 'primary',
            name: 'next',
            "styles": {
                "height": "48px",
                "width": "129px",
                "margin-top": "50px"
            },
            testid: "btnDesktopBack",
            "styleClassName": "action-button",
            label: t("Credit.Details.Pay"),
        }
    }

    let btnMobileConfig = {
        btn: {
            type: 'button',
            color: 'primary',
            name: 'next',
            "styles": {
                "height": "48px",
            },
            testid: "btnMobileBack",
            "styleClassName": "action-button",
            label: t("Credit.Details.Pay"),
        }
    }

    let buttonConfig = {
        btnNext: () => onClickPayment()
    }

    const onClickPayment = () => {
        window.analytics.track('ACTIVE_LOANS_PAY', {
            page: 'WC_Active_Loans',
            journey: 'WC_Loans',
            product_group: 'Lending',
            product_name: 'Working_Capital',
            button_name: 'Pagar'
        });
        navigate('/Payment')
    }

    const onInvoiceClick = (event) => {
        window.analytics.track('HISTORY_LOANS_DETAIL_BILL', {
            page: 'WC_History_Loans_Detail',
            journey: 'WC_History',
            button_name: 'Descargar_factura',
            product_group: 'Lending',
            product_name: 'Working_Capital'
        });
        getInvoiceURL(event);
    }

    const getInvoiceURL = async (index) => {
        const date_gen = invoicesData[index].date_generate.split(" ");
        const response = await get_invoice_URL({params:{
            loan_account_id: data.loan.id,
            invoice_id: invoicesData[index].invoice_id,
            date_generated: date_gen[0].toString()
        }});
        if (response.status === 200) {
            window.open(response.data.pre_signed_url);
        }
    }

    const generateValues = () => {
        const { expenses, total } = InteresAndTotal(data)

        setDataDetails({
            "Amount": {
                "title": [t('Credit.Details.amount')],
                "value": formatter(data.loan.loanAmount),
                "column": "A"
            },
            "DueDate": {
                "title": [t('Credit.Details.DueDate')],
                "value": getFormattedDate(calculateDueDate_loan(data.loan)),
                "column": "A"
            },
            "Period": {
                "title": [t('Credit.Details.Period')],
                "value": `${days[data.loan.scheduleSettings.repaymentPeriodUnit] * data.loan.scheduleSettings.repaymentPeriodCount} ${t('ConfigureCredit.days')}`,
                "column": "B"
            },
            "TaxRate": {
                "title": [t('Credit.Details.TaxRate')],
                "value": `${data.loan.taxRate}%`,
                "column": "A"
            },
            "InterestMoratorio": {
                "title": [t('Credit.Details.interestMoratorio')],
                "value": `30%`,
                "column": "A"
            },
            "InterestPunitorio": {
                "title": [t('Credit.Details.interestPunitorio')],
                "value": `9%`,
                "column": "A"
            },
            "AdministrativeExpenses": {
                "title": [t('Credit.Details.AdministrativeExpenses')],
                "value": formatter(expenses),
                "column": "A"
            },
            "NumberOfPayments": {
                "title": [t('Credit.Details.NumberOfPayments')],
                "value": data.loan.scheduleSettings.repaymentInstallments,
                "column": "B"
            },
            "AccountDisbursed": {
                "title": [t('Credit.Details.AccountDisbursed')],
                "value": data.loan.id,
                "column": "A"
            },
            "TotalPayment": {
                "title": [t('Credit.Details.TotalPayment')],
                "value": formatter(total),
                "column": "A"
            }
        })
    }

    const detailColumn = (key, title, value) => {
        return (
            <>
                <Stack direction="row" justifyContent="space-between" className={styles.rowContent}>
                    <Typography className={styles.textFieldKeyDetails} key={key} >
                        {title}
                    </Typography>
                    <Typography className={`${styles.textFieldValueDetails} ${styles[key]}`} key={key} textAlign="right">
                        {value}
                    </Typography>
                </Stack>
            </>
        );
    }

    const getBtnConfig = (isDesktop) => {
        if (isDesktop) {
            btnDesktopConfig["btnNext"] = buttonConfig["btnNext"]
            return btnDesktopConfig
        } else {
            btnMobileConfig["btnNext"] = buttonConfig["btnNext"]
            return btnMobileConfig
        }
    }

    const get_invoices_loan = async () => {
        const responseInvoices = await get_invoices({param: data.loan.id});

        if (responseInvoices.status === 200){
            if (responseInvoices.data.length > 0)
                setInvoicesData(responseInvoices.data);
        }
        if(location.state.data.origin === 'history') {
            window.analytics.page('PAGEVIEW_HISTORY_LOANS_DETAIL', {
                page: 'WC_History_Loans_Detail',
                journey: 'WC_History',
                product_group: 'Lending',
                product_name: 'Working_Capital'
            });
        }
    }

    useEffect(() => {
        get_invoices_loan()
        generateValues()
    }, []);

    let matches = useMediaQuery(theme.breakpoints.up("sm"));

    const handleBack = () => {
        var segmentData = {
            page: 'WC_Active_Loans_Detail',
            journey: 'WC_Loans',
            button_name: 'Regresar',
            product_group: 'Lending',
            product_name: 'Working_Capital'
        }

        if(location.state.data.origin === 'dashboard'){
            window.analytics.track('ACTIVE_LOANS_BACK', segmentData);
        }else if(location.state.data.origin === 'history') {
            segmentData.page = 'WC_History_Loans_Detail';
            segmentData.journey = 'WC_History';
            window.analytics.track('HISTORY_LOANS_DETAIL_BACK', segmentData);
        }

        navigate(-1)
    }
    return (
        <Grid sx={{ marginLeft: {sm:'7%'}, marginRight: {sm:'7%'}, marginTop: {sm: '30px', xs:'-10px'}}}>
            <Card className={styles_.CardsDetails}>
                <Grid sx={{ display: { xs: 'none', sm: 'block' }, marginBottom: '10px' }} paddingY={0}>
                    <PageHeader
                        title={t("Buttons.Back")} handleBack={handleBack} />
                </Grid>
                <Grid mt={2} container display="flex" className={styles_cc.principalCard}>
                    <Grid item xs={12} md={1}>
                        <Typography className={styles.titleHeader}>
                            {t("Credit.Details.credit_details")}
                        </Typography>
                    </Grid>
                    <Grid container spacing={2} sx={{ justifyContent: { xs: 'start', sm: 'start' }, marginLeft: { sm: '-60px !important' } }}>
                        <Grid item xs={12} className={styles.dueText}>
                            {t("Credit.Details.credit_due")}
                        </Grid>
                        <Grid item className={styles.cardDetails} sx={{marginLeft: { xs: '15px !important', sm: '30px !important' } }}>
                            <CardCredit {...location.state.data} details={true} generic />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className={styles.divider} />
                        </Grid>
                        <Grid item sx={{ marginLeft: { xs: '35px !important' }, marginRight: { xs: '35px !important' }, marginTop: { xs: '-30px !important' }}}>
                            {!matches ? <>
                                {Object.keys(dataDetails).map((key) => {
                                    return (
                                        <Grid key={`${key} grid`} className={styles.detail} container justify="center" alignItems="center">
                                            <Grid key={`${key} item`} item xs={6} justifyContent="flex-start">
                                                <Typography className={styles.textFieldKeyDetails} key={key} >
                                                    {dataDetails[key].title}
                                                </Typography>
                                            </Grid>
                                            <Grid key={`${key} item 2`} item xs={6}>
                                                <Typography className={`${styles.textFieldValueDetails} ${styles[key]}`} key={key} textAlign="right">
                                                    {dataDetails[key].value}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                                <Grid className={styles.buttonContainer}>
                                    { (data.loan.accountState !== 'CLOSED') ?
                                        <Button {...getBtnConfig(false)} />:null
                                    }
                                </Grid>
                            </> :
                                <Stack
                                    direction={{ xs: 'column', sm: 'row' }}
                                    sx={{ pt: 3 }}
                                    spacing={2}
                                    className={styles.detailsContainerColumns}
                                >
                                    <Stack direction="column" className={styles.columA}>
                                        {Object.keys(dataDetails).map((key) => {
                                            if (dataDetails[key].column == "A")
                                                return (
                                                    detailColumn(key, dataDetails[key].title, dataDetails[key].value)
                                                )
                                        })}
                                        { (data.loan.accountState !== 'CLOSED') ?
                                            <Button {...getBtnConfig(true)} /> :null
                                        }
                                    </Stack>
                                    <Stack direction="column" className={styles.columA}>
                                        {Object.keys(dataDetails).map((key) => {
                                            if (dataDetails[key].column == "B")
                                                return (
                                                    detailColumn(key, dataDetails[key].title, dataDetails[key].value)
                                                )
                                        })}
                                    </Stack>
                                </Stack>}
                            <Grid item xs={12}>
                                <Divider className={styles.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{display: invoicesData.length > 0 ? 'block' : 'none' }}>
                                <Typography className={styles.subtitleHeader}>
                                    {'Facturas'}
                                </Typography>
                                <Grid container spacing={2}
                                //  justify="center"
                                //  alignItems="center"       
                                 >
                                    {invoicesData.map((invoices, index) =>
                                        <Grid item key={invoices.id} >
                                            <InvoiceCard index={index} date={invoices.date_generate} onDownloadClick={onInvoiceClick}/>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                </Grid>
            </Card>
        </Grid>
    );
};


export default function Details() {
    const { client } = useCreditContext();
    const bannerStyles = ThemeBanner();
    const navigate = useNavigate();

    useEffect(async () => {
        window.analytics.page('PAGEVIEW_ACTIVE_LOANS_DETAIL', {
            page: 'WC_Active_Loans_Detail',
            journey: 'WC_Loans',
            product_group: 'Lending',
            product_name: 'Working_Capital',
        });
        if (!client) navigate('/Credit');
    }, []);

    return (
        <Fragment>
            {client && (
                <Grid spacing={5} className={bannerStyles.bannerCredit}>
                    <Grid sx={{ display: { xs: 'block', sm: 'none' } }} paddingY={3}>
                        <PageHeader title={'Detalle del crédito'} product={''} handleBack={() => navigate(-1)}/>
                    </Grid>
                    <UserInfoHeader userdata={client} visibilityContent={false} />
                    <DetailsScreen style={{overflowY:'scroll'}}/>
                </Grid>
            )}
        </Fragment>
    )
}