import React from 'react';
import { Stack, Grid, Typography, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PageHeader = ({ title, handleBack, product, px=2, classNameButton }) => {
 return (
     <Stack sx={{ px: px }} direction="row" alignItems="center" spacing={2}>  
          <Grid>
              <IconButton onClick={handleBack} data-testid='backButton' className={classNameButton}>
                  <ArrowBackIcon style={{ color: "#343C46" }}  />
              </IconButton>
          </Grid>

          <Grid>
            <Typography 
                style={{fontFamily:'Readex Pro', fontWeight:'400', size: '16px',  color: "#343C46"}}
                variant="p">
                    {title}<Typography style={{ fontWeight: 'bold', color: "#343C46"}} variant="p">{product}</Typography>
            </Typography>
          </Grid>
     </Stack>
 );
};

export default PageHeader;