import React, { useEffect, useState, Fragment } from "react";
import Iframe from "react-iframe";
import { useNavigate } from "react-router-dom";
import OnBoardingLayout from "../../utils/on-boarding-layout";
import { Box, Container, Grid } from "@mui/material";
import useStylesKym from "../../utils/themes/ThemeFormKym";
import { useTranslation } from "react-i18next";
import { validate_token} from "../../actions/axios-api";
import Loading from "../../views/Loading";
import MessageError from "../loader/messageError";
import { useCreditContext } from "../../utils/providers/CreditProvider";
import { v4 as uuidv4 } from "uuid";
function FormScreen({ FormId, idpdv, email, remedy, token, userEmail, password, legacyUserWc, origin, tokenID, validateError, loading }) {
    const { isAbbreviate, hasActiveCredit, setDocumentStatus, setOrigin } = useCreditContext();
    const classesKym = useStylesKym();
    const i18n = useTranslation("global").i18n;
    const [userId, setUserId] = useState("");
    const [kym_data, setkym_data] = useState("");
    const navigate = useNavigate();
    const [t] = useTranslation("global");
    let uuid = uuidv4();
    let _url = `${process.env.REACT_APP_KYM_DOMAIN}/?form_id=${FormId}&country=${'py'}&email=${email}&idUser=${idpdv}&remedy=${remedy}&user_email=${userEmail}&legacyUserWc=${legacyUserWc}&appwc=true&isAbbreviate=${isAbbreviate}&origin=${origin}&tokenID=${tokenID}&uuid=${uuid}`;
    const {
        setReloadDocument
    } = useCreditContext();
    const goToContract = () => {
        navigate("/Contract", {
            state: {
                token: token,
                email: userEmail,
                password: password,
                data: kym_data,
                tokenID: tokenID
            }
        });
    };

    const validate = async (isDeleted) => {
        let data = { "isDeleted": isDeleted, "token": token }
        await validate_token(data)
    }

    function goToMessageScreen(prefix = "ScreenMessage.Remedy.ReceivedDocument.") {
        let prefixSuccess = prefix;
        let titleSuccess = "Title";
        let subTitleSuccess = "SubTitle";
        let imageSuccess = "Image";
    
        navigate("/MessageScreen", {
            screen: "MessageScreen",
            state: {
                type: {
                    data: {
                        image: t(prefixSuccess + imageSuccess),
                        title: t(prefixSuccess + titleSuccess),
                        subTitle: t(prefixSuccess + subTitleSuccess),
                        default: false,
                    },
                },
            },
        });
    }

    const navigateScreen = async (screen, state) => {
        navigate(screen, {
            replace: true,
            screen: screen,
            state: state
        });
    }
 
    useEffect(() => {
        loading(true);
        window.addEventListener("message", async (e) => {
            if (e.origin !== process.env.REACT_APP_KYM_DOMAIN) {
                return
            }
            switch (e.data.message) {
                case "SUCCESS":
                    if (userId !== "") {
                        setOrigin(e.data.data?.origin)
                        goToContract();
                    }
                    break;
                case "CREATE":
                    setOrigin(e.data.data?.origin)
                    setkym_data(e.data.data);
                    setUserId("somethingIDgenerated")
                    break;
                case "UPDATED_REMEDY_DOCS":
                    loading(true);
                    setOrigin(e.data.data?.origin)
                    if (!e.data.isDeleted) {
                        return
                    }
                    await validate(e.data.isDeleted)
                    if (e.data?.isLegacyMomoUser || (isAbbreviate && !hasActiveCredit)) {
                        await navigateScreen("/SignIn", {})
                        return
                    }
                    if (!isAbbreviate) {
                        loading(true);
                        goToMessageScreen()
                        loading(false);
                        return
                    }
                    setReloadDocument(true)
                    setDocumentStatus("pending")
                    await navigateScreen("/Credit", {})
                    loading(false);
                    break;
                case "LEGACY":
                    navigateScreen("/TermsAndConditions", {data: e.data.data, userEmail:userEmail, password: password, token:token})
                    break;
            }
        });
        loading(false);
    }, []);

    useEffect(() => {
        if (userId !== "") {
            goToContract();
        }
    }, [userId]);

    return (
        <div className="fragmentIframe">
            <Box className={classesKym.box} style={{ "height": "100%" }} mt={12}>
                <Container
                    className={classesKym.container}
                    maxWidth={false}
                >
                    <Box className={classesKym.contentView}>
                        <Grid container style={{ "height": "100%" }}>
                            <Grid item xs={0} sm={0} md={1}></Grid>
                            <Grid item xs={12} sm={12} md={10}>
                                <Iframe
                                    url={_url}
                                    width="100%"
                                    height="100%"
                                    className="iFrame"
                                />
                            </Grid>
                            <Grid item xs={0} sm={0} md={1}></Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </div>
    );
}

export default function Form({ FormId, idpdv, email = "", remedy = false, token, userEmail, password, legacyUserWc, origin = "", tokenID = "" }) {
    const [error, seterror] = useState(false)
    const [loading, setloading] = useState(false)
    return (
        <Fragment>
            {loading && <Loading></Loading>}
            {error && <MessageError path={'SignUp'}></MessageError>}
            {<div style={{ display: (loading || error) ? "none" : "block" }}>
                {<OnBoardingLayout
                    screen={<FormScreen
                        FormId={FormId}
                        idpdv={idpdv}
                        email={email}
                        remedy={remedy}
                        token={token}
                        userEmail={userEmail}
                        password={password}
                        legacyUserWc={legacyUserWc}
                        origin={origin}
                        tokenID={tokenID}
                        validateError={seterror}
                        loading={setloading}
                    />}
                    newdashboard={false}
                    fullcard={false}
                    smallcard={false}
                    cardNotificationNoPadding={true}
                    rendersize={true} />}
            </div>}
        </Fragment>
    )
}
