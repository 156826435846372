
const days = {
    "DAYS": 1,
    "MONTHS": 30,
    "YEARS": 365,
    "WEEK": 7,
}
const calculate_total_pay = (Capital, product) => {
    Capital = parseInt(Capital)
    let InteresEfectivoAnual = product['interestRate'] / 100;
    let Period = product['repaymentPeriodCount'];
    let Unit = product['repaymentPeriodUnit'];
    let FeeAdministrativo = product['feePercentageAmount'];

    let Dias = Math.round(days[Unit] * Period);
    let Fee = Math.round(Capital * FeeAdministrativo) / 100;
    let Interes = Math.round((InteresEfectivoAnual / 365) * Dias* Capital) ;
    let Total = Math.round(Capital + Fee + Interes);
    const _ = {
        "InteresEfectivoAnual": InteresEfectivoAnual,
        "Period": Period,
        "Unit": Unit,
        "FeeAdministrativo": FeeAdministrativo,
        "Dias": Dias,
        "Fee": Fee,
        "Interes": Interes,
        "Total": Total
    }
    return Total
}

const calculate_fee = (Capital, product) => {
    let Period = product['repaymentPeriodCount'] ? product['repaymentPeriodCount'] : product['defaultRepaymentPeriodCount'];
    let Unit = product['repaymentPeriodUnit'];
    let FeeAdministrativo = product['feePercentageAmount'];
    let Fee = Math.round(Capital * FeeAdministrativo) / 100;
    let InteresEfectivoAnual = product['interestRate'] / 100;
    let Dias = Math.round(days[Unit] * Period);
    let Interes = Math.round((InteresEfectivoAnual / 365) * Dias* Capital) ;
    return {
        "fee": Fee,
        "interes": Interes
    }
}
const calculate_total_pay_disbursementloan = (loan) => {
    let balances = loan['balances'];
    let total_balance = balances['redrawBalance'] + balances['principalBalance'] + balances['interestBalance'] + balances['feesBalance'] + balances['penaltyBalance'] + balances['holdBalance'];
    return total_balance; 
}
const get_days_or_month = (record) => {
    const map = {
        'DAYS': 'dias',
        'MONTHS': 'meses'
    }
    return map[record]
}
const calculateInterestExpensesAndLoan = (data)  => {
    let interest = calculate_fee(data.loan.loanAmount, data.productDetail).interes
    let expenses = calculate_fee(data.loan.loanAmount, data.productDetail).fee
    let total = calculate_total_pay_disbursementloan(data.loan) + interest
    return { expenses, total }
}
const InteresAndTotal = (data)=>{
    const {loan, productDetail} = data; 
    let expenses = calculate_fee(loan.loanAmount, productDetail).fee; 
    let total = loan["balances"]["principalBalance"] + loan["balances"]["interestBalance"] +
        loan["balances"]["penaltyDue"] + loan["balances"]["feesBalance"] +
        loan["accruedPenalty"] + loan["accruedInterest"]
    return {
        expenses, total
    }
}
export {
    calculate_total_pay,
    calculate_fee,
    calculate_total_pay_disbursementloan,
    calculateInterestExpensesAndLoan,
    InteresAndTotal,
    get_days_or_month
}