import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

export const allCreditsTheme = makeStyles((theme: Theme) => ({
    card: {
        [theme.breakpoints.down("xs")]: {

        },
        [theme.breakpoints.up("sm")]: {
            position: 'absolute',
            top: '85px',
            padding: '50px',
            background: "#FFFFFF",
            marginTop: "5px !important",
            borderRadius: '30px',
            width: '80%',
            margin: '6%',
            display: 'flex',
            flexDirection: 'row !important',
            boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
            minHeight: '480px',
        },
    },
    hideContent: {
        display: 'none !important'
    }
}));