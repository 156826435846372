export function getFormattedDate(date) {
    date = new Date(date);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return `${day}/${month}/${year}`;
}
export function getFormattedDate_(date) {
    const parts = date.split('/');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    const newDate = new Date(`${year}-${month}-${day}`);
    const newYear = newDate.getFullYear();
    const newMonth = (1 + newDate.getMonth()).toString().padStart(2, '0');
    const newDay = newDate.getDate().toString().padStart(2, '0');
    return `${newDay}/${newMonth}/${newYear}`;
}
export function getDate(date) {
    date = new Date(date);
    let year = date.getFullYear();
    let month = (date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return `${day}/${month}/${year}`;
}
export function getDate_(date) {
    const parts = date.split('/');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    const newDate = new Date(`${year}-${month}-${day}`);
    const newYear = newDate.getFullYear();
    const newMonth = (newDate.getMonth()).toString().padStart(2, '0');
    const newDay = newDate.getDate().toString().padStart(2, '0');
    return `${newDay}/${newMonth}/${newYear}`;
}
export function getFormattedDateWithDash(dateString) {
    if(dateString){
        const dateParts = dateString.split("-");
        const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);

        const month = date.getMonth() + 1; 
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month.toString().padStart(2, "0")}/${day.toString().padStart(2, "0")}/${year}`
    }
}

export function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}