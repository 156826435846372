
const countryCode = {
    py: {
        currency: 'PYG',
        format: 'es-PY'
    },
    hn: {
        currency: 'HNL',
        format: 'es-HN'
    },
    gt: {
        currency: 'GTQ',
        format: 'es-GT'
    },
    bo: {
        currency: 'BOB',
        format: 'es-BO'
    },
    sv: {
        currency: 'USD',
        format: 'es-SV'
    },
}

const formatterPyAmount = ( formattedAmount ) => {
    const amount = formattedAmount.substring(3)
    const currency = formattedAmount.substring(3, -formattedAmount.length)

    return `${currency.replace(".", "")}${amount}`
}

const formatter = (amount , country = 'py') => {
    const options = { style: 'currency', currency: countryCode[country].currency};
    // minimumFractionDigits: 2, maximumFractionDigits: 2
    const formatter = new Intl.NumberFormat(countryCode[country].format, options);

    return country === 'py' ? formatterPyAmount(formatter.format(amount) ) : formatter.format(amount)  
};

export default formatter;