import React, {useEffect, useState} from 'react'

import {Grid, Stack, Typography} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import CardCredit from './active-credits/card-credit'
import themeCredit from '../../utils/themes/ThemeCredit';
import GenericBackdrop from "../../utils/GenericBackdrop";
import {formatCard} from '../../utils/getCardInfo';
import Button from '../inputs/button';
import PageHeader from "../commons/page-header/page-header";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useCreditContext} from "../../utils/providers/CreditProvider";

export default function Credits({tabs, buttonConfig, client, loading, validateError}) {
    const theme = themeCredit();
    const [indexTab, setIndexTab] = useState(0);
    const [filter, setFilter] = useState(tabs[indexTab]);
    const [responseCredits, setResponseCredits] = useState({});
    let [responseCreditsFilter, setResponseCreditsFilter] = useState({});
    const [loader, setLoader] = useState(false);
    const [actionTabs, setActionTabs] = useState(false);
    const navigate = useNavigate();
    const [t] = useTranslation("global");
    const match = {
        'epin': 'e-pin',
        'emoney': 'e-money',
        'all': 'all'
    }
    const {
        loansInfo
    } = useCreditContext();
    useEffect(async () => {
        if (!actionTabs) {
            loading(true)
            setLoader(true)
            setResponseCredits(loansInfo)
            setResponseCreditsFilter(loansInfo)
            loading(false)
            setLoader(false)
        }
    }, [filter])

    const handleChangeTab = (event, newIndexTab) => {
        const loanType = tabs[newIndexTab]?.loanType === '' ? 'all' : tabs[newIndexTab]?.loanType
        setActionTabs(true)
        setIndexTab(newIndexTab);
        setFilter(tabs[newIndexTab])
        const typeLoan = match[loanType]
        const objectAux = Object.assign({}, responseCredits)
        objectAux['loans'] = responseCredits['loans']?.filter((data) => {
            if (data.loanName.includes(typeLoan)) {
                return data
            }
        })
        if (typeLoan !== 'all') {
            setResponseCreditsFilter(objectAux)
        } else {
            setResponseCreditsFilter(responseCredits)
        }
    };

    const buildCardCredit = (credit) => {
        const cardInfo = formatCard(credit, '/Details', responseCreditsFilter.products_details);
        return (<CardCredit {...cardInfo} />)
    }
    const handleBack = () => {
        window.analytics.track("ACTIVE_LOANS_BACK", {
            page: "WC_Active_Loans",
            journey: "WC_Loans",
            button_name: "Regresar",
            product_group: "Lending",
            product_name: "Working_Capital"
        });
        navigate(-1);
    }
    return (
        <Box sx={{width: '100%'}}>
            <Stack sx={{display: {xs: 'none', sm: 'block'}}} pr={2}>
                <Stack mb={4}>
                    <PageHeader title={'Regresar'} handleBack={handleBack}/>
                </Stack>
                <Stack pl={2} direction="row" justifyContent="space-between">
                    <Typography style={{
                        fontFamily: 'Readex Pro',
                        fontWeight: '600',
                        fontSize: '22px',
                        color: "#343C46"
                    }}> {t("Credit.creditActive.activeCredits")}</Typography>
                    <div>
                        <Button
                            {...buttonConfig}
                            btn={{...buttonConfig.btn, styles: {padding: '6px 32px'}}}
                        />
                    </div>
                </Stack>
            </Stack>
            <GenericBackdrop open={loader}/>
            <Grid
                direction="column"
                container
                display="flex"
                sx={{
                    justifyContent: {xs: 'center', sm: 'start'},
                    paddingLeft: {xs: '0', sm: '16px'},
                    paddingRight: {xs: '0', sm: '16px'}
                }}
                className={theme.allCreditsContainer}
            >
                {/* filter credits */}
                <Grid
                    container
                    direction="row"
                    display="flex"
                    sx={{justifyContent: {xs: 'center', sm: 'start'}}}
                    className={theme.filterContainer}
                >
                    <Tabs
                        value={indexTab}
                        onChange={handleChangeTab}
                        TabIndicatorProps={{
                            style: {display: 'none', minHeight: "32px"}
                        }}
                    >
                        {tabs.map((tab, index) => (
                            <Tab
                                label={tab.label}
                                className={theme[tab.class]}
                                id={`${index}-btn-filter`}
                                key={`tab-filter-${tab.label}`}
                            />
                        ))}
                    </Tabs>
                </Grid>

                {/* credits */}
                <Grid className={theme.creditsContainer}>
                    <div className={theme.gridCreditsContainer}>
                        <Grid
                            container
                            display="flex"
                            sx={{justifyContent: {xs: 'center', sm: 'start'}}}
                        >
                            <Paper elevation={0} className={theme.paperContainer}>
                                <Grid
                                    container
                                    rowSpacing={{xs: 2, sm: 4}}
                                    columnSpacing={{xs: 2, sm: 3}}
                                >
                                    {responseCreditsFilter?.loans?.map((credit) => (
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            key={`loan-component-${credit.id}`}
                                            id={credit.id}
                                            className={theme.gridCreditContainer}
                                        >
                                            {buildCardCredit(credit)}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Paper>
                        </Grid>
                    </div>
                    <Grid
                        sx={{display: {xs: 'block', sm: 'none'}}}
                        mt={2.5}
                        id="div-buttton"
                    >
                        <Grid
                            container
                            display="flex"
                            sx={{justifyContent: {xs: 'center', sm: 'start'}}}
                            spacing={2}
                            className="grid-buttons"
                        >
                            <Grid
                                item
                                xs={10}
                                key={'button-credit'}
                            >
                                <div>
                                    <Button {...buttonConfig} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
