import React, { useState } from "react";
import { Button, Checkbox, Grid, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AlertDialog from "../commons/alertDialog";
import themeDialog from "../../utils/themes/ThemeDialog";


const DialogTermsConditions = ({ setShowDialog, checkNoticePrivacy, checkTermsConditions, setCheckNoticePrivacy, setTermsConditions }) => {
  const [t] = useTranslation("global");
  const theme = themeDialog();
  const [open, setOpen] = useState(true);
  const Usetheme = useTheme();

  let matches = useMediaQuery(Usetheme.breakpoints.down('sm'))

  const handleChange = (value, setValue) => {
    setValue(value);
  };

  const handleClose = (isCloseDialog = true) => {
    setOpen(false)
    setShowDialog(false)
    if (isCloseDialog) {
      setCheckNoticePrivacy(false)
      setTermsConditions(false)
    }
  }

  const dialogActions = () => (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
    >
      <Button
        variant="contained"
        size="large"
        disabled={(checkNoticePrivacy && checkTermsConditions) ? false : true}
        onClick={_e => handleClose(false)}
        className={`action-button ${theme.termsNoticePrivacyBtnAcept}`}
        data-testid="btn-accept-temrms-privacy"
      >
        {t("Contract.DialogNoticePrivacyAndTerms.BtnAccept")}
      </Button>
    </Grid>
  )

  const dialogContent = () => (
    <>
      <Grid item xs={12}>
        <Typography
          className="contractTermsNoticePrivacySubtitle"
          variant="title"
          component="div"
          gutterBottom>
          {t("Contract.DialogNoticePrivacyAndTerms.SubTitle")}
        </Typography>
      </Grid>

      <Grid container className={theme.noticePrivacyGrid}>
        <Grid
          item xs={1}
          justifyContent="flex-start"
          alignItems="flex-start"
          className="grid-checkbox"
        >
          <Checkbox
            checked={checkNoticePrivacy}
            onChange={event => handleChange(event.target.checked, setCheckNoticePrivacy)}
            sx={{
              padding: "0px"
            }}
            inputProps={{
              "aria-label": "controlled",
              "data-testid": "cbox-notice-privacy",
            }}
          />
        </Grid>
        <Grid item xs={11} >
          <Typography
            className="contractTermsNoticePrivacy"
            variant="title"
            component="div"
            gutterBottom>
            {t("Contract.DialogNoticePrivacyAndTerms.TextNoticePrivacyPart1")}<Link className="contractTermsNoticePrivacyLink" to={t("Contract.DialogNoticePrivacyAndTerms.Url")}>{t("Contract.DialogNoticePrivacyAndTerms.LinkTextNoticePrivacy")}</Link>{t("Contract.DialogNoticePrivacyAndTerms.TextNoticePrivacyPart2")}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item xs={1}
          justifyContent="flex-start"
          alignItems="flex-start"
          className="grid-checkbox"
        >
          <Checkbox
            checked={checkTermsConditions}
            onChange={event => handleChange(event.target.checked, setTermsConditions)}
            sx={{
              padding: "0px"
            }}
            inputProps={{
              "aria-label": "controlled",
              "data-testid": "cbox-terms-conditions",
            }}
          />
        </Grid>
        <Grid item xs={11} style={{}}>
          <Typography
            className="contractTermsNoticePrivacy"
            variant="title"
            component="div"
            gutterBottom>
            {t("Contract.DialogNoticePrivacyAndTerms.TextTermsPart1")}<Link className="contractTermsNoticePrivacyLink" to={t("Contract.DialogNoticePrivacyAndTerms.Url")}>{t("Contract.DialogNoticePrivacyAndTerms.LinkTextTerms")}</Link>{t("Contract.DialogNoticePrivacyAndTerms.TextTermsPart2")}
          </Typography>
        </Grid>
      </Grid>
    </>
  )

  const dialogButtonRigth = () => (
    <Grid container justifyContent="center"
      alignItems="center">
      <Grid item xs={9}>
        {dialogContent()}
      </Grid>
      <Grid item xs={3}>
        {dialogActions()}
      </Grid>
    </Grid>
  )

  return <AlertDialog
    title={t("Contract.DialogNoticePrivacyAndTerms.Title")}
    dialogContent={matches ? dialogContent : dialogButtonRigth}
    dialogActions={matches && dialogActions}
    open={open}
    handleClose={handleClose}
  />
}

export default DialogTermsConditions;