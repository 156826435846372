import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core";

const themeGenericMessage = makeStyles((theme: Theme) => ({
    BoxTypography:{
        justifyContent: "center",
        display: "flex",
        background: "white",
        borderRadius: "16px",
    },
    BoxStyle:{
        boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
        borderRadius: "16px",
        background: "#fff",
        [theme.breakpoints.down("xs")]: {
            boxShadow: "0px 0px 0px",
        },
    },
    BoxSize: {
        maxWidth: "560px",
        margin: "0 auto",
    },
    contentView: {
        maxWidth: "false",
        height: "100%",
        webkitBoxShadow: "1px 3px 1px #9E9E9E",
        mozBoxShadow: "1px 3px 1px #9E9E9E",
        padding: "0px"
    },
    PrincipalBox:{
        marginTop: "0px"
    },
    PrimaryTypography: {
        textAlign: "center",
        fontFamily: 'Roboto',
        fontStyle: "normal",
        color: "#404A56",
        fontWeight: "600",
        fontSize: "42px",
        lineHeight: "36px",
        marginTop: "7%",
        [theme.breakpoints.down("xs")]: {
            marginTop: "35%",
            fontSize: "24px",
            position:"relative",
        },
    },
    SecondaryTypography: {
        textAlign: "center",
        fontFamily: 'Roboto',
        fontStyle: "normal",
        color: "#404A56",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "18px",
        marginTop: "10px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "10px",
            fontSize: "15px",
            lineHeight: "18px",
            position:"relative",
        },
    },
    ThirdTypography: {
        textAlign: "center",
        fontFamily: 'Roboto',
        fontStyle: "normal",
        color: "#343C46",
        fontWeight: "700",
        fontSize: "38px;",
        lineHeight: "150%",
        marginTop: "30px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "35%",
            fontSize: "24px",
            position:"relative",
        },
    },
    FourthTypography: {
        textAlign: "center",
        fontFamily: 'Roboto',
        fontStyle: "normal",
        color: "#404A56",
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "24px",
        marginTop: "10px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "10px",
            fontSize: "15px",
            lineHeight: "18px",
            position:"relative",
        },
    },
    ContentImage:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    Image:{
        width: "400px",
        height: "400px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "10px",
            width: "160px",
            height: "160px"
        },
    },
}));

export default themeGenericMessage;
