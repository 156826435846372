import React, { useState, useEffect, Fragment } from "react";
import { get_user_record, update_user_record } from "../actions/axios-api";
import { useLocation } from "react-router-dom";

import onboardingSteps from "../utils/onboardingSteps.json"
import SignUp from "../components/commons/steps/onboarding/SignUp";
import Welcome from "../components/commons/steps/onboarding/welcome";

export default function Onboarding() {
  const search = useLocation().search;
  const [step, setStep] = useState();
  const [screen, setScreen] = useState();
  const [session, setSession] = useState({});
  const agent = new URLSearchParams(search).get("agent");
  const steps = onboardingSteps.gt;

  useEffect(async () => {
    const response = await get_user_record(agent);
    if (response.status == 200) {
      const updatedRecord = response.data;
      setStep(updatedRecord.next_step.step - 1);
      setSession(updatedRecord.session);
      setScreen(updatedRecord.next_step.name)
    } 
    if (response?.response?.status == 404) {
      setScreen(steps[0].name);
    }
  }, [])

  useEffect(async () => {
    screenSelect();
  }, [screen])

  const onSubmitScreen = async (action) => {
    if (action == "next") {
      const record = {
        "user": agent,
        "session": session,
        "step": screen,
        "flow": "MiTienda"
      }
      const response = await update_user_record(record);
      const updatedRecord = response.data;
      setStep(updatedRecord.next_step.step -1);
      setScreen(updatedRecord.next_step.name);
    } else {
      setScreen(steps[step - 1].name);
      setStep(step - 1);
    }
  };

  const onChangeSessionValues = async (value, setValue, fieldName) => {
    setValue(value);
    session[fieldName] = value;
  };

  const prefilledInputs = (field, session) => {
    if (field?.isPrefilledField && session?.[field?.fieldName]) {
      field.value = session[field.fieldName];
    }
  }

  const screenSelect = () => {
    if (screen == steps[0].name) {
      return <SignUp
        session={session}
        onChangeSessionValues={onChangeSessionValues}
        prefilledInputs={prefilledInputs}
        onSubmitScreen={onSubmitScreen}
      />
    }
    if (screen == steps[1].name) {
      return <Welcome
        session={session}
        onChangeSessionValues={onChangeSessionValues}
        prefilledInputs={prefilledInputs}
        onSubmitScreen={onSubmitScreen}
      />
    }
  }

  return (
    <Fragment>
      {screenSelect()}
    </Fragment>
  )

}
