import React, { useState, Fragment } from 'react'
import { Grid, Stack } from "@mui/material";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CardCredit from '../active-credits/card-credit.jsx';
import themeCredit from '../../../utils/themes/ThemeCredit.js';
import { formatSimpleCard, getMonth, monthNames } from '../../../utils/getCardInfo';
import { useTranslation } from "react-i18next";
import CreateAccount from '../../../assets/generic/CreateAccount.svg'
import themeActiveCredit from '../../../utils/themes/ThemeActiveCredit';
import { _logicOnNext, _logicOnBack } from '../../../utils/getSimpleList.js'
import PropTypes from 'prop-types';
import ButtonCreditList from './button-credit-list.jsx';
import PageHeader from '../../commons/page-header/page-header.jsx';
export default function CreditList({
    loans,
    handleLoans,
    handleCard,
    products,
    handleArrowBack
}) {

    const styles = themeActiveCredit();

    const limit = getMonth();
    const theme = themeCredit();
    const currentDate = new Date();
    const [next, setNext] = useState(false);
    const [back, setBack] = useState(true);
    const [t] = useTranslation("global");
    const [monthNum, setMonthNum] = useState(currentDate.getMonth());
    const [month, setMonth] = useState(monthNames[monthNum]);
    const [year, setYear] = useState(currentDate.getFullYear());

    const buildCardCredit = (loan) => {
        const cardInfo = formatSimpleCard(loan, '/Details', products);
        return (<CardCredit
            {...cardInfo}
            loanStatus={loan.accountState}
            isFunction
            handlerFunction={handleCard}
            simpleCard
        />)
    }
    const onNextBack = ({ newMonthNum, newYear }) => {
        setMonthNum(newMonthNum);
        setMonth(monthNames[newMonthNum]);
        setYear(newYear);
    }

    const onBackSimple = (value) => {
        setBack(value);
    }
    const onNextSimple = (value) => {
        setNext(value);
    }
    const handleNext = () => {
        if (next) {
            let response = _logicOnNext({
                year: year,
                monthNum: monthNum,
                currentDate: currentDate,
                onNext: onNextBack,
                onNextSimple: onNextSimple,
                onBackSimple: onBackSimple

            })

            handleLoans(response);
        }

    }
    const handleBack = () => {
        if (back) {

            let response = _logicOnBack({
                year: year,
                monthNum: monthNum,
                limit: limit,
                onBack: onNextBack,
                onBackSimple: onBackSimple,
                onNextSimple: onNextSimple

            })
            handleLoans(response);

        }

    }
    return (
        <Grid
        container
            justifyContent="center" 
        >
            
            <Grid className={theme.creditsContainer_} justifyContent="center">

                <div className={theme.gridCreditsContainer_}>
                <Grid sx={{ display: { sm: 'block', xs: 'none' } }} paddingY={3}>
                    <PageHeader title={'Regresar'} handleBack={handleArrowBack}/>
                </Grid>
                    <Grid
                        container
                        sx={{ justifyContent: { xs: 'center', sm: 'center' } }}
                    >

                        <Paper elevation={0} className={theme.paperContainer_}>
                            <Grid container justifyContent="space-between" padding={3}>
                                <Grid item xs={10} className={theme.monthText}>
                                    {t(month)} {year}
                                </Grid>
                                <ButtonCreditList
                                    back={back}
                                    next={next}
                                    handleBack={handleBack}
                                    handleNext={handleNext}

                                />
                            </Grid>
                            {loans.length > 0 ? <Grid
                                container
                                rowSpacing={{ xs: 2, sm: 4 }}
                                columnSpacing={{ xs: 2, sm: 3 }}
                            >

                                {loans.map((loan) => (
                                    <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        key={`loan-component-${loan.id}`}
                                        id={loan.id}
                                        className={theme.gridCreditContainer}
                                    >
                                        {buildCardCredit(loan)}
                                    </Grid>
                                ))}
                            </Grid> :
                                <Fragment>
                                    <Grid item xs={12} className={styles.gridMessage} >
                                        <img
                                            className={styles.imageMessage_}
                                            src={CreateAccount}
                                            alt={'CreateAccount'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={styles.textMessage}>
                                        {t("Credit.creditActive.message_filter")}
                                    </Grid>
                                </Fragment>
                            }
                        </Paper>
                    </Grid>
                </div>

            </Grid></Grid>
    )
}
CreditList.propTypes = {
    loans: PropTypes.array.isRequired,
    handleLoans: PropTypes.func.isRequired,
    handleCard: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    handleArrowBack: PropTypes.func
};