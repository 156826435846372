import React from 'react';
import themePageToast from "../../../utils/themes/ThemePageToast";
import { Stack, Typography } from "@mui/material";

const PageToastType = {
    info: 'info',
    warning: 'warning',
    danger: 'danger'
}

const PageToast = ({ type = PageToastType.info, message = '' }) => {

    const styledTheme = themePageToast();

    const getStylesPerType = () => {
        if (type === PageToastType.warning) return styledTheme.warning;

        //if (type === PageToastType.danger) return styledTheme.danger;

        //return styledTheme.info;
    };

    return (
        <Stack px={2} py={0.5} className={getStylesPerType()}>
            <Typography className={styledTheme.text}>
                {message}
            </Typography>
        </Stack>
    );
};

export default PageToast;