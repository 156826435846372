import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core";

export const creditDetailsTheme = makeStyles((theme: Theme) => ({
    fontTitleCredit:{
        color: "#343C46 !important",
        fontFamily: "Readex Pro !important",
        fontSize: "24px !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        lineHeight: "34px !important",
        letterSpacing:"0.247px !important",
        paddingBottom: "28px"
    },
    fontTitle: {
        fontWeight: "400 !important",
        fontSize: '16px !important',
        lineHeight: '24px !important',
        color: '#4C5866 !important',
        fontFamily: 'Readex Pro !important',
    },
    fontPrice: {
        fontWeight: "700 !important",
        fontSize: '28px !important',
        lineHeight: '54px !important',
        color: '#4C5866 !important',
        fontFamily: 'Readex Pro !important',
    },
    fontDate: {
        fontWeight: "400 !important",
        fontSize: '12px !important',
        lineHeight: '18px !important',
        color: '#708195 !important',
        fontFamily: 'Readex Pro !important'
    },
    fontLabel: {
        fontWeight: "400 !important",
        fontSize: '14px !important',
        lineHeight: '22px !important',
        color: '#708195 !important',
        fontFamily: 'Readex Pro !important'
    },
    fontValue: {
        fontWeight: "500 !important",
        fontSize: '14px !important',
        lineHeight: '22px !important',
        color: '#4C5866 !important',
        fontFamily: 'Readex Pro !important'
    },
    fontTotal: {
        [theme.breakpoints.up("sm")]: {
            fontSize: '22px !important',
        },
        fontWeight: "700 !important",
        fontSize: '14px !important',
        lineHeight: '22px !important',
        color: '#4C5866 !important',
        fontFamily: 'Readex Pro !important'
    },
    boxBorder: {
        [theme.breakpoints.down("xs")]: {
            paddingBottom: "16px",
        },
        [theme.breakpoints.up("sm")]: {
            paddingBottom: "32px",
        },
        borderBottom: '1px solid #E6E6E6 !important'
    },
    principalBox: {
        [theme.breakpoints.down("xs")]: {
            paddingTop: "24px",
            paddingBottom: "24px",
        },
        [theme.breakpoints.up("sm")]: {
            marginBottom: '3px',
            minHeight: '90px',
            padding: "28px 100px 60px 100px",
        }
    },
    cardContainer: {
        boxShadow: '2px 4px 4px rgba(185, 185, 185, 0.25)',
        background: '#ffffff',
        borderRadius: '21px',
        padding: '30px'
    },
    principalStack: {
        [theme.breakpoints.down("xs")]: {
            direction: "column",
            alignItems: "center"
        },
        [theme.breakpoints.up("sm")]: {
            display: "flex",
            flexDirection: "column",
            alignSelf: "stretch",
        }
    },
    stackContainer: {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
            paddingTop: "24px",
            flexDirection: "column",
        },
        [theme.breakpoints.up("sm")]: {
            paddingTop: "28px",

        }
    },
    row: {
        flex: "50%",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "space-between",
        },
    }
}));