import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

const themeInvalidToken = makeStyles((theme: Theme) => ({
    boxOptReq: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "-52px",
            paddingBottom: "3px",
            paddingLeft: "36px",
            paddingRight: "36px",
        }
    },
    gridContainer: {
        [theme.breakpoints.up("sm")]: {
            paddingRight: "60px",
            paddingLeft: "60px"
        }
    },
    logo: {
        position: "absolute",
        [theme.breakpoints.down("xs")]: {
            marginTop: "147px !important",
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "68px !important",
        },
    },
    card: {
        [theme.breakpoints.down("xs")]: {
            background: 'transparent !important',
            boxShadow: '0px 0px 0px 0px !important',
        },
        [theme.breakpoints.up("sm")]: {
            borderRadius: '24px !important',
            boxShadow: '2px 4px 4px rgba(185, 185, 185, 0.25) !important',
            width: "598px  !important",
            justifyContent: "center  !important",
            alignItems: "flex-start  !important",
            background: '#ffffff !important',
            marginTop: "150px !important",
            paddingBottom: "40px !important"
        },
    },
    gridText: {
        textAlign: "center !important",
        [theme.breakpoints.down("xs")]: {
            marginTop: "340px !important"
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "230px !important",
        }
    },
    text: {
        fontFamily: 'Readex Pro !important',
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "20px !important",
        lineHeight: "30px !important",
        letterSpacing: "0.247px !important",
        color: "#343C46",
        [theme.breakpoints.down("xs")]: {
            paddingBottom: "50% !important",
        },
        [theme.breakpoints.up("sm")]: {
            paddingBottom: "8% !important",
        },
    },
    buttonContainer: {
        [theme.breakpoints.up("sm")]: {
            marginLeft: "25% !important"
        }
    }
}));


export default themeInvalidToken;