import PropTypes from 'prop-types';
import React, { Fragment } from "react";

import GenericInput from "../../../genericInput";
import Button from "../../../inputs/button";

const SignUp = ({
  session, 
  onChangeSessionValues, 
  prefilledInputs, 
  onSubmitScreen
}) => {
  const buttonConfigNext = {
    btn: {
        testid: 'continue-signup',
        type: 'button',
        color: 'primary',
        name: 'next',
        "styles": {
            "height": "48px"
        },
        "styleClassName": "action-button",
        label: "Continuar",
    },
    btnNext: () => onSubmitScreen("next")
  }
  let fields = [
    {
      "props": {
        "id": 0,
        "testId": "0-phone",
        "type": "textFieldMaterial",
        "name": "phone",
        "fieldName": "test_numer",
        "isPrefilledField": true,
        "label": "Phone",
        "value": null,
        "placeholder": "",
        "readOnly": false,
        "title": "",
        "created": true,
        "conditions": [
          {
            "type": "length",
            "title": "SignUp.Password.FieldPassword.Props.Conditions.Length"
          },
          {
            "type": "capital",
            "title": "SignUp.Password.FieldPassword.Props.Conditions.Capital"
          },
          {
            "type": "lower",
            "title": "SignUp.Password.FieldPassword.Props.Conditions.Lower"
          },
          {
            "type": "number_symbol",
            "title": "SignUp.Password.FieldPassword.Props.Conditions.NumSym"
          }
        ],
        "style": {
          "paddingBottom": "25px",
          "fontSize": "14px"
        }
      }
    },
    {
      "props": {
        "id": 1,
        "testId": "1-password",
        "type": "textFieldMaterial",
        "name": "password",
        "fieldName": "password",
        "isPrefilledField": false,
        "label": "Password",
        "value": null,
        "placeholder": "Pass",
        "readOnly": false,
        "title": "",
        "created": true,
        "conditions": [
          {
            "type": "length",
            "title": "SignUp.Password.FieldPassword.Props.Conditions.Length"
          },
          {
            "type": "capital",
            "title": "SignUp.Password.FieldPassword.Props.Conditions.Capital"
          },
          {
            "type": "lower",
            "title": "SignUp.Password.FieldPassword.Props.Conditions.Lower"
          },
          {
            "type": "number_symbol",
            "title": "SignUp.Password.FieldPassword.Props.Conditions.NumSym"
          }
        ],
        "style": {
          "paddingBottom": "25px",
          "fontSize": "14px"
        }
      }
    },
    {
      "props": {
        "id": 2,
        "testId": "2-confirm-password",
        "type": "textFieldMaterial",
        "name": "confirm-password",
        "fieldName": "password",
        "isPrefilledField": false,
        "label": "Confirm password",
        "value": null,
        "placeholder": "Confirm",
        "readOnly": false,
        "style": {
          "fontSize": "14px"
        }
      }
    }
  ]

  const renderFields = (fields) => {
    return fields.map((field) => {
      prefilledInputs(field.props, session)

      return <div key={field.props.id}>
        <GenericInput {...field} onChange={onChangeSessionValues} />
      </div>
    })
  }

  return (
    <Fragment>
      {renderFields(fields)}
      <Button {...buttonConfigNext} />
    </Fragment>
  )
}


SignUp.propTypes = {
  session: PropTypes.any.isRequired,
  onChangeSessionValues: PropTypes.any.isRequired,
  prefilledInputs: PropTypes.any.isRequired,
  onSubmitScreen: PropTypes.any.isRequired
};

export default SignUp;
