import React, {useEffect} from 'react';

import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import paymentCheckImg from '../../../assets/payment/paymentCheck.svg';
import { paymentsTheme } from "../../../utils/themes/ThemePayment";
import {
    primaryButtonStyles,
    secondaryButtonStyles,
    setButtonLabel
} from "../../../utils/themes/ThemeButtons";
import Button from "../../inputs/button";
import formatter from "../../../utils/translations/formatter";
import {useTranslation} from "react-i18next";
import {approvalType} from "../../../constants/WowMoment";
import thumbUpImg from "../../../assets/payment/thumbUp.svg";

const PaymentWow = ({
                        isAccountActive = false,
                        amount,
                        duePending,
                        creditsPaid,
                        creditPending,
                        onSubmit,
                        onCancel,
                        paymentType,
                        canCredit
                    }) => {
    const [t] = useTranslation("global");
    const paymentsStyles = paymentsTheme();

    const accountActiveDescription = t("Credit.WowMoment.Titles.descriptionActive");

    const pendingCreditDescription = t("Credit.WowMoment.Titles.descriptionPending");

    const textDescription = isAccountActive ? accountActiveDescription : pendingCreditDescription;
    const nameEvent = {
        'total': 'PAGEVIEW_PAYMENT_WOW_OK',
        'optional': 'PAGEVIEW_PAYMENT_PENDING',
        'required': 'PAGEVIEW_PAYMENT_WOW_ARREARS'
    }
    const namePage = {
        'total': 'WC_Payment_WOW_OK',
        'optional': 'WC_Payment_WOW_Pending',
        'required': 'WC_Payment_WOW_Arrears'
    }
    useEffect( () => {
        window.analytics.page(nameEvent[paymentType], {
            page: namePage[paymentType],
            journey: 'WC_Loans',
            product_group: 'Lending',
            product_name: 'Working_Capital',
        });
    }, []);

    const getComponentsCustom = () => {
        return (
            <Box className={paymentsStyles.boxContainer}>
                <Stack
                    sx={{mb: 2}}
                    direction="row"
                    justifyContent="space-between"
                >
                    <Typography
                        className={paymentsStyles.title}>{t("Credit.WowMoment.Titles.detailPayment")}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                    <Typography className={paymentsStyles.label}>{t("Credit.WowMoment.Titles.amount")}</Typography>
                    <Typography className={paymentsStyles.value}>{formatter(amount)}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                    <Typography className={paymentsStyles.label}>{t("Credit.WowMoment.Titles.duePending")}</Typography>
                    <Typography className={paymentsStyles.value}>{formatter(duePending)}</Typography>
                </Stack>
                {(paymentType === approvalType.optional || paymentType === approvalType.required) &&
                    <Stack direction="row" justifyContent="space-between">
                        <Typography
                            className={paymentsStyles.label}>{t("Credit.WowMoment.Titles.creditsPaid")}</Typography>
                        <Typography className={paymentsStyles.value}>{creditsPaid}</Typography>
                    </Stack>
                }
                {(paymentType === approvalType.optional || paymentType === approvalType.required) &&
                    <Stack direction="row" justifyContent="space-between">
                        <Typography
                            className={paymentsStyles.label}>{t("Credit.WowMoment.Titles.creditPending")}</Typography>
                        <Typography className={paymentsStyles.value}>{creditPending}</Typography>
                    </Stack>
                }
            </Box>
        )
    }

    return (
        <>
            {(paymentType === approvalType.optional || paymentType === approvalType.required) && (
                <Stack direction="column"
                       alignItems="center"
                       justifyContent="center" className={paymentsStyles.boxOptReq}>
                    <Card className={paymentsStyles.cardOptReq}>
                        <Grid className={paymentsStyles.gridContainer}>
                            <Grid
                                container
                                display="flex"
                                justifyContent="center"
                                style={{marginBottom:'25px'}}
                            >
                                <img
                                    className={paymentsStyles.checkPaymentImage}
                                    src={paymentCheckImg}
                                    alt="pending-credit-check"
                                />
                            </Grid>

                            <Grid container
                                  justifyContent="center" className={paymentsStyles.gridTextOptReq} rowSpacing={2}>
                                <Grid item>
                                    <Typography
                                        className={paymentsStyles.congratsText}>{t("Credit.WowMoment.Titles.paymentSuccess")}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={paymentsStyles.description}>{textDescription}</Typography>
                                </Grid>
                            </Grid>

                            <Grid className={paymentsStyles.gridContainerText}>
                                <Stack direction="column" sx={{mb: 5}} spacing={1} className={paymentsStyles.boxBorder}>
                                    {getComponentsCustom()}
                                </Stack>
                            </Grid>
                            <Box sx={{width: '100%'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Button
                                            {...setButtonLabel(primaryButtonStyles, t("Credit.WowMoment.Buttons.PaymentCredits"), creditPending <= 0)}
                                            btnNext={onSubmit}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Button
                                            {...setButtonLabel(secondaryButtonStyles, t("Credit.WowMoment.Buttons.Home"))}
                                            btnNext={onCancel}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Card>
                </Stack>
            )}
            {paymentType === approvalType.total && (
                <Stack sx={{p: 3}}
                       direction="column"
                       alignItems="center"
                       justifyContent="center">
                    <Card className={paymentsStyles.card}>
                        <Grid className={paymentsStyles.gridContainer}>
                            <Grid
                                container
                                display="flex"
                                justifyContent="center"
                            >
                                <img
                                    className={paymentsStyles.totalPaymentImage}
                                    alt="total-payment"
                                    src={thumbUpImg}
                                />
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                className={paymentsStyles.gridTextWow} rowSpacing={2}
                            >
                                <Grid item className={paymentsStyles.textWow} xs={12} md={12}>
                                    <Typography
                                        className={paymentsStyles.congratsText}>{t("Credit.WowMoment.Titles.congratulations")}</Typography>
                                </Grid>
                                <Grid item className={paymentsStyles.textWow} xs={12} md={12} marginBottom={2}>
                                    <Typography
                                        className={paymentsStyles.title}>{t("Credit.WowMoment.subTitles.successCredit")}</Typography>
                                </Grid>

                            </Grid>
                            <Grid className={paymentsStyles.gridContainerText}>
                                <Stack direction="column" sx={{mb: 5}} spacing={1} className={paymentsStyles.boxBorder}>
                                    {getComponentsCustom()}
                                </Stack>
                            </Grid>
                            <Box sx={{width: '100%'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Button
                                            {...setButtonLabel(primaryButtonStyles, t("Credit.WowMoment.Buttons.creditRequest"), !canCredit)}
                                            btnNext={onSubmit}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Button
                                            {...setButtonLabel(secondaryButtonStyles, t("Credit.WowMoment.Buttons.Home"))}
                                            btnNext={onCancel}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Card>
                </Stack>
            )}
        </>
    );
}

export default PaymentWow;