import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const themeConfigureCredit = makeStyles((theme: Theme) => ({
    titleDesktop: {
        fontFamily: 'Readex Pro !important',
        fontWeight: "600 !important",
        fontSize: "24px !important",
        lineHeight: "20px",
        letterSpacing: "0.247px",
        color: "black",
        paddingBottom: "16px",
        display: 'inline-block',
        whiteSpace: 'nowrap',
        marginBottom: '5px'
    },
    configureCreditBody: {
        background: 'linear-gradient(180deg, rgba(255,216,88,1) 22%, rgba(250,190,0,1) 100%)',
        fontFamily: "Readex Pro !important",
    },
    scheduleButtonsGrid: {
        margin: '0px 10px 0px 10px'
    },
    buttonsGrid: {
        padding: '0px 30px 0px 30px',
        [theme.breakpoints.down("lg")]: {
            padding: 'auto !important'
        },

        [theme.breakpoints.up("lg")]: {
            margin: '0 25% 0 25% !important',

        }
    },
    buttonsText: {
        marginTop: '10px',
        [theme.breakpoints.down("lg")]: {
            fontSize: '14px !important',
            fontWeight: '500 !important',
        },

        [theme.breakpoints.up("lg")]: {
            fontSize: '18px !important',
            fontWeight: '600 !important',
        }
    },
    productSelected: {

        height: '30px !important',
        borderRadius: '12px !important',
        background: '#FABE00 !important',
        boxShadow: 'none !important',
        color: "#000 !important",
        "& p": {
            color: "#343C46 !important",
            fontSize: '12px !important',
            fontWeight: '400 !important',
            lineHeight: '18px !important',
            margin: '0 !important',
            position: 'absolute !important',
            fontFamily: "Readex Pro !important",

            clear: 'both',
            display: 'inline-block',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }
    },
    productUnselected: {
        background: '#F0F2F4 !important',
        height: '30px !important',
        borderRadius: '12px !important',
        boxShadow: '0px 2px 4px rgba(112, 129, 149, 0.2) !important',
        color: "#708195 !important",
        "& p": {
            color: "#708195 !important",
            fontSize: '12px !important',
            fontWeight: '400 !important',
            lineHeight: '18px !important',
            margin: '0 !important',
            position: 'absolute !important',
            fontFamily: "Readex Pro !important",
            clear: 'both',
            display: 'inline-block',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }
    },
    scheduleTextGrid: {
        fontWeight: '400',
        fontSize: '12px !important',
        lineHeight: '18px',
        letterSpacing: '0.247px',
        color: '#4C5866',
        [theme.breakpoints.down("xs")]: {
            padding: '25px 10px 20px 10px',
            margin: '0px 20px 0px 20px !important'
        },
        [theme.breakpoints.up("sm")]: {
            padding: '25px 10px 20px 10px',
            margin: '0px 5px 0px 5px !important',
            fontSize: '14px !important',
        },
        [theme.breakpoints.up("md")]: {
            fontSize: '16px !important',
        }
    },
    principalCard: {
        [theme.breakpoints.down("xs")]: {

        },
        [theme.breakpoints.down("sm")]: {
            padding: '0px 16px 0px 16px'
        },
        [theme.breakpoints.down("lg")]: {
            padding: '0px 32px 0px 32px'
        },
        [theme.breakpoints.up("lg")]: {
            padding: '0px 120px 0px 120px'
        },
    },
    dividerLine: {
        borderTop: '1px solid #E6E6E6',
        margin: '0px 15px 0px 15px !important'
    },
    firstCard: {
        boxShadow: '0px 0px 5px 1px #E6E6E6',
        borderRadius: '16px',
        background: '#FFFFFF'
    },
    configTextGrid: {
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0.25px',
        color: '#4C5866',
        [theme.breakpoints.down("xs")]: {
            padding: '20px 10px 15px 10px',
            margin: '0px 20px 0px 20px !important'
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: '14px',
            padding: '20px 10px 15px 10px',
            margin: '0px 5px 8px 5px !important'
        },
        [theme.breakpoints.up("md")]: {
            fontSize: '16px',
        }
    },
    sliderAmountPrincipal: {
        textAlign: 'center !important',
        marginBottom: '30px'
    },
    sliderAmount: {
        [theme.breakpoints.down("xs")]: {
            padding: '0px 30px'
        }, [theme.breakpoints.up("sm")]: {
            padding: '0 20%'
        }
    },
    amountText: {
        background: "#F6F6F6",
        boxShadow: 'none',
        border: 'none',
        "&:hover": {
            boxShadow: 'none',
            border: 'none'
        },
        "&:focus": {
            boxShadow: 'none',
            border: 'none'
        },
        width: '90px',
        height: '40px',
        borderRadius: '10px',
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '20px',
        paddingLeft: '20px'
    },
    amountTextBottonLeft: {
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0.247px',
        color: '#708195',
        textAlign: "left",
        paddingLeft: "17px",
        clear: 'both',
        display: 'inline-block',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    amountTextBottonRigth: {
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0.247px',
        color: '#708195',
        textAlign: "right",
        paddingRight: "15px",
        clear: 'both',
        display: 'inline-block',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    unitTextTop: {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.247px',
        color: '#4C5866',
        margin: 'auto',
        paddingRight: '10px'
    },
    inputAmount: {
        margin: 'auto !important',
    },
    inputAmountText: {
        textAlign: "right",
        padding: "0px",
        fontFamily: "Readex Pro",

        [theme.breakpoints.down("xs")]: {
            fontWeight: "700",
            fontSize: "18px",
        },
        [theme.breakpoints.up("up")]: {
            fontWeight: "700",
            fontSize: "16px",
        }

    },
    amountTextTop: {
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0.247px',
        color: '#708195',

    },
    gridContainerAmountInput: {
    },
    secondCard: {
        borderRadius: '16px',
        paddingBottom: '30px',
        background: '#F9F9F9',
        padding: '15px 25px 15px 25px',
        boxShadow: 'none',
    },
    titleCard: {
        color: '#404A56',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.25px',
        marginBottom: '5px !important',
    },
    dueDate: {
        color: '#4C5866',
        fontWeight: 300,
        fontSize: '12px',
        lineHeight: '22px',
        letterSpacing: '0.25px',
        marginBottom: '2px !important',
    },
    dueDateValue: {
        color: '#4C5866',
        fontWeight: 300,
        fontSize: '12px',
        lineHeight: '22px',
        letterSpacing: '0.25px',
        textAlign: 'right',
        clear: 'both',
        display: 'inline-block',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    totalAmount: {
        color: '#4C5866',
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '22px',
        letterSpacing: '0.25px'
    },
    totalAmountValue: {
        color: '#4C5866',
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '22px',
        letterSpacing: '0.25px',
        textAlign: 'right',

        clear: 'both',
        display: 'inline-block',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
}));

export default themeConfigureCredit;