import React from 'react';
import "./App.css";
import "@fontsource/readex-pro";
import { Routes, Route } from "react-router-dom";
import Integrations from "./views/Integrations";
import Contract from "./views/Contract";
import Form from "./views/Form";
import Remedy from "./views/FormRemedy";
import FormLegacy from './views/FormLegacy';
import PendingDocuments from "./views/PendingDocuments";
import CompleteDocuments from "./views/CompleteDocuments";
import Product from "./views/Product";
import MessageScreen from "./views/MessageScreen";
import ForgotPassword from "./views/ForgotPassword";
import SignUp from "./views/SignUp";
import SignIn from "./views/SignIn";
import Credit from "./views/Credit";
import AllCredits from './components/credit/allCredits';
import ConfigureCredit from './views/ConfigureCredit';
import Details from './views/Details';
import WowMoment from './components/credit/wowMoment';
import CreditDetails from "./views/CreditDetails";
import { RequireAuth } from "react-auth-kit";
import { CreditProvider } from "./utils/providers/CreditProvider";
import { APIProvider } from './utils/providers/APIProvider';
import Payment from "./views/Payment";
import TermsAndConditions from "./views/TermsAndConditions";
import {SideMenuProvider} from "./utils/providers/SideMenuProvider";
import SideMenu from "./components/side-menu/SideMenu";
import UserControlCenter from "./components/user-control-center/UserControlCenter";
import Onboarding from './views/Onboarding';
import MyHistory from './views/MyHistory';

function App() {

  return (
    <CreditProvider>
      <SideMenuProvider>
        <APIProvider>
          <SideMenu>
            <UserControlCenter />
          </SideMenu>
          <Routes>
            <Route path="TermsAndConditions" element={<TermsAndConditions />} /> 
            <Route path="Integrations" element={<Integrations />} />
            <Route path="Contract" element={<Contract />} />
            <Route path="Form" element={<Form />} />
            <Route path="Remedy" element={<Remedy />} />
            <Route path="FormLegacy" element={<FormLegacy />} />
            <Route path="PendingDocuments" element={<PendingDocuments />} />
            <Route path="CompleteDocuments" element={<CompleteDocuments />} />
            <Route path="Product" element={<Product />} />
            <Route path="MessageScreen" element={<MessageScreen />} />
            <Route path="ForgotPassword" element={<ForgotPassword />} />
            <Route path="SignUp" element={<SignUp />} />
            <Route path="SignIn" element={<SignIn />} />
            <Route path="Credit" element={<Credit />} />
            <Route path="AllCredits" element={<AllCredits />} />
            <Route path="ConfigureCredit" element={<ConfigureCredit />} />
            <Route path="Details" element={<Details />} />
            <Route path="WowMoment" element={<WowMoment />} />
            <Route path="CreditDetails" element={<CreditDetails />} />
            <Route path="Payment" element={<Payment />} />
            <Route path="Onboarding" element={<Onboarding />} />
            <Route path="MyHistory" element={<MyHistory/>} />
            <Route
                path="/"
                element={
                  <RequireAuth loginPath={"/SignUp"}>
                    {" "}
                    <SignIn />{" "}
                  </RequireAuth>
                }
            />
          </Routes>
        </APIProvider>
      </SideMenuProvider>
    </CreditProvider>
  );
}

export default App;