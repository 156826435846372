import React from 'react';
import {Grid, Box, Typography} from '@mui/material';
import theme from "../../utils/themes/ThemeActiveCredit";
import FileInvoice from '../../assets/generic/fileInvoice.svg'
import Download from '../../assets/generic/download.svg'
import themeDetails from "../../utils/themes/ThemeDetails";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const InvoiceCard = (props) => {
    const {
        index,
        date,
        onDownloadClick,
    } = props;

    const styles = theme();
    const stylesDetails = themeDetails();
    const [t] = useTranslation("global");

    const fileInvoice = () => {
        return (
            <Grid item xs={12} style={{textAlign: '-webkit-left'}}>
                <div className={stylesDetails.detailDivImage} style={{width: "40px", height: "40px"}}>
                    <img
                        className=""
                        src={FileInvoice}
                        alt={"InvoiceFile"}
                    />
                </div>
            </Grid>
        )
    }

    const invoiceName = () => {
        return (
            <Grid item xs={12} >
                {/* <div style={{whiteSpace: 'nowrap'}}> */}
                    <Typography style={{
                        fontSize: "14px",
                        font: "Readex Pro",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "150px",
                        marginLeft: '5px',
                    }}>{t("InvoiceCard.Invoice") + " - " + date}</Typography>
                {/* </div> */}
            </Grid>
        )
    }

    const downloadIcon = () => {
        const handleClick = (event) => {
            onDownloadClick(event.currentTarget.id);
        };

        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && event.keyCode === 13) {
                event.preventDefault();
            }
        };

        return (
            <Grid item xs={12} style={{textAlign: '-webkit-left'}}>
                <div className={stylesDetails.detailDivImage}>
                    <button
                        id={index}
                        onClick={handleClick}
                        onKeyUp={handleKeyDown}
                        data-testid={"download-click"}
                        style={{
                            border: 'none',
                            background: 'none',
                            padding: 0,
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            src={Download}
                            alt={"downloadFile"}
                        />
                    </button>
                </div>
            </Grid>
        )
    }

    return (
        <div className={styles.invoiceCard}>
            <Box className={styles.boxCardInvoice}>
                <Grid
                    className={styles.cardBody}
                    container
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={2}>
                        {fileInvoice()}
                    </Grid>
                    <Grid item xs={8}>
                        {invoiceName()}
                    </Grid>
                    <Grid item xs={2} alignItems="flex-end">
                        {downloadIcon()}
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

InvoiceCard.propTypes = {
    onDownloadClick: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
};

export default InvoiceCard;