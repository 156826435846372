import React from "react";
import { Box } from "@mui/material";

const Image = ({ img, classname }) => {
  return (
    <Box mb={3} sx={{ justifyContent: "center", display: "flex" }}>
      <img src={img} alt="Finish" className={classname}></img>
    </Box>
  );
};

export default Image;
