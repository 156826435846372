import React, { Fragment, useEffect, useState } from 'react';
import CreditDetailCard from "../components/credit/CreditDetailCard/CreditDetailsCard";
import {Grid, Card, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from '../components/inputs/button';
import { useCreditContext } from "../utils/providers/CreditProvider";
import PageHeader from "../components/commons/page-header/page-header";
import Loading from './Loading';
import MessageError from '../components/loader/messageError';
import { approvalType } from "../constants/WowMoment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import UserInfoHeader from '../components/credit/userInfoHeader';
import { ThemeBanner } from "../utils/themes/ThemeBanner";
import themeProduct from "../utils/themes/ThemeProduct";
import { useAPIContext } from '../utils/providers/APIProvider';
import { calculate_total_pay, calculate_fee, get_days_or_month } from '../utils/getProductInfo';
import formatter from '../utils/translations/formatter';

const CreditDetails = () => {
    const [t] = useTranslation("global");
    const pageTitle = t("Credit.creditDetails.headTitle");
    const {
        credit,
        client,
        productName,
        product,
        subProduct,
        dueDate,
        setPaymentType,
        setPendingApprovals,
        pendingApprovals,
        isAbbreviate,
        setDisabledCredit,
        setHasActiveCredit,
        setIsOverLimit,
        setDocumentStatus,
        isOverLimit,
        clientId,
        msisdn
    } = useCreditContext();
    const {create_transaction} = useAPIContext(); 
    const [error, seterror] = useState(false)
    const [loading, setloading] = useState(false)
    const navigate = useNavigate()
    const theme = useTheme();
    let matches = useMediaQuery(theme.breakpoints.up("sm"));
    const bannerStyles = ThemeBanner();
    const styles_ = themeProduct();
    const monthLimit = 5000000;
    const creaditDetailsInfo = {
        loan_amount: credit?.loanAmount,
        dueDate: dueDate,
        period: `${subProduct?.repaymentPeriodCount} ${get_days_or_month(subProduct?.repaymentPeriodUnit)}`,
        taxRate: `${credit?.taxRate}%`,
        interestMoratorio: "30%",
        interestPunitorio: "9%",
        administrativeExpenses: calculate_fee(credit.loanAmount, subProduct).fee,
        accountWillBeDisbursed: credit.id,
        totalPayment: formatter(calculate_total_pay(credit.loanAmount, subProduct)),
        productName: productName,
        productNameOriginal: product.name,
        idpdv: client.client_id,
        bonus: product.bonus
    }
    const transaction_created = async () => {
        window.analytics.page('REQUEST_CONFIRMATION', {
            page: 'WC_Request_Config',
            journey: 'WC_Loans',
            button_name: 'Entendido',
            product_group: 'Lending',
            product_name: 'Working_Capital',
          });
        setloading(true)
        const data = {
            credit_arrangement_key: client?.credit_arrangement_key,
            loan_key: credit?.encodedKey,
            currency: client?.currency,
            external_id: credit?.id,
            report_name: product?.descriptionProduct,
            fees_key: credit?.disbursementDetails.fees[0].predefinedFeeEncodedKey,
            client_id: client?.client_id,
            ...creaditDetailsInfo
        }
        const response = await create_transaction({params: {data: data, param:`client_id=${clientId}&msisdn=${msisdn}`}});
        if (response.status === 400) {
            setloading(false)
            seterror(true)
        }
        else {
            setPaymentType(approvalType.available)
            let newPendings = removeApproval(pendingApprovals, data.loan_key)
            setPendingApprovals(newPendings)
            if (!isOverLimit && isAbbreviate && (parseFloat(client?.limit_out) + credit?.loanAmount) >= monthLimit) {
                setHasActiveCredit(true)
                setIsOverLimit(true)
                setDocumentStatus("rejected")
                setDisabledCredit(true)
            }
            setloading(false)
            navigate('/WowMoment')
        }
    }

    const removeApproval = (pendingApprovals, loan_id) => {
        let newPendings = []
        for (const element of pendingApprovals) {
            if (element.loanId == loan_id) {
                continue
            }
            newPendings.push(element)
        }
        return newPendings
    }

    const configCreditDetail = {
        btn: {
            disabled: client ? credit?.loanAmount > client['available_credit'] : false,
            type: 'button',
            color: 'primary',
            name: 'next',
            "styles": !matches ? {
                "height": "48px",
                "width": "330px"
            } : { height: "44px", width: "150px", marginLeft: "100px" },
            "styleClassName": "action-button",
            label: t("Credit.creditDetails.understand"),
        },
        btnNext: () => transaction_created()
    }

    useEffect(async () => {
        if (!client) {
            return navigate("/Credit");
        }
    })

    const button = () => {
        return <Button {...configCreditDetail} />
    }
    const handleBack = () => {
        window.analytics.page('PAGEVIEW_REQUEST_SUMMARY', {
            page: 'WC_Request_Summary',
            journey: 'WC_Loans',
            product_group: 'Lending',
            product_name: 'Working_Capital',
        });
        navigate(-1);
    }
    return (
        <Fragment>
            {loading && <Loading></Loading>}
            {error && <MessageError></MessageError>}
            {client && <div style={{ display: (loading || error) ? "none" : "block" }}>
                <Grid className={matches ? bannerStyles.bannerOne_web : bannerStyles.bannerOne}>
                    {!matches ? <Grid paddingY={3}>
                        <PageHeader title={t('Credit.creditDetails.headTitle')} handleBack={handleBack} product={''} />
                    </Grid> : ''}
                    <UserInfoHeader userdata={client} visibilityContent={false} />
                    <div className={styles_.cardProduct}>
                        <Card className={styles_.CardsProducts}>
                            <Grid container sx={{ padding: { xs: '25px' }, paddingBotton: { sm: '-25px !important' } }}>
                                {matches ? <Grid sx={{ marginBottom: '10px' }} paddingY={0}>
                                    <PageHeader
                                        title={t("Buttons.Back")} handleBack={handleBack} />
                                </Grid> : ''}
                                <Grid mt={2} container display="flex" className={styles_.principalCard}>
                                    <Grid item xs={12} md={1} sx={{ display: { xs: 'none', sm: 'block' }, fontWeight: { sm: '700 !important' } }}>
                                        <Typography className={styles_.titleProduct}>
                                            {pageTitle}
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ marginLeft: { sm: '30px' } }}>
                                        <CreditDetailCard
                                            creditDetails={creaditDetailsInfo}
                                            credit={credit}
                                            matches={matches}
                                        />
                                    </Grid>

                                </Grid>
                                <Grid item style={{ marginTop: '40px' }} sx={{ marginLeft: { sm: '45px' }, display: { xs: 'none', sm: 'block' } }} xs={12}>
                                    { matches && button()}
                                </Grid>
                            </Grid>
                        </Card>
                        <Grid item style={{ marginTop: '40px' }} sx={{ marginLeft: { sm: '45px' }, display: { xs: 'block', sm: 'none' } }} xs={12}>
                            {!matches && button()}
                        </Grid>
                    </div>
                </Grid>
            </div>
            }
        </Fragment>
    );
};

export default CreditDetails;