import React, { useState } from "react";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import TermsConditionsLogo from '../assets/generic/TermsConditionsLogo.svg'
import { useTranslation } from "react-i18next";
import themeTAndC from "../utils/themes/ThemeT&C";
import Button from "./inputs/button";

const TermConditions = ({ handleStart }) => {
    const styles = themeTAndC();
    const [t] = useTranslation("global");
    const [privacity, setPrivacity] = useState(false);
    const [tyc, setTyc] = useState(false);

    const createLink = (texts) => {
        const url = " https://www.tigo.com.py/legales#tigo-money-contrato-de-servicio-tigo-money";
        return <div style={{
        }}>
            {texts[0]}<a target="_blank" href={url} className={styles.url}> {texts[1]} </a>{texts[2]}.
        </div>
    }
    let buttonConfig = {
        btn: {
            disabled: !privacity || !tyc, 
            type: 'button',
            color: 'primary',
            name: 'next',
            "styles": {
                "height": "48px",
                "width": "100%",
            },
            "styleClassName": "action-button",
            label: "Iniciar",
        },
        btnNext: (privacity && tyc) ? () => handleStart(): null


    }
    return (
        <Grid container className={styles.body}>
            <Grid item xs={12} style={{ textAlign: 'center', marginTop: '-10px !important'}}>
                <img src={TermsConditionsLogo} />
            </Grid>
            <Grid item xs={12} className={styles.title}>{t('T&C.Title')}</Grid>
            <Grid item xs={12} className={styles.checkTitle}>{t('T&C.CheckTitle')}</Grid>
            <Grid container xs={12} className={styles.tyc}>
                <FormControlLabel
                    label={createLink([t('T&C.Check1_first'), t('T&C.privacity'), t('T&C.Check1_second')])}
                    control={
                        <Checkbox
                            data-testid='checkbox-privacity'
                            id='checkbox-privacity'
                            checked={privacity}
                            onChange={()=>setPrivacity(!privacity)}
                            sx={{
                                color:'#4C5866',
                                '&.Mui-checked': {
                                  color: '#4C5866',
                                },
                              }}
                        />
                    }
                />
                 <FormControlLabel
                    label={createLink([t('T&C.Check2_first'), t('T&C.tyc'), t('T&C.Check2_second')])}
                    control={
                        <Checkbox
                            data-testid='checkbox-tyc'
                            id='checkbox-tyc'
                            checked={tyc}
                            onChange={()=>setTyc(!tyc)}
                            sx={{
                                color: '#4C5866',
                                '&.Mui-checked': {
                                  color: '#4C5866',
                                },
                              }}
                        />
                    }
                />
               
            </Grid>
            <Grid item xs={12} className={styles.button}><Button {...buttonConfig} /></Grid>
            </Grid>
            )
}
            export default TermConditions; 