import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

const themeTAndC = makeStyles((theme: Theme) => ({
  cardProduct: {
    [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        padding: '0px 20px 0px 20px',
        zIndex: 0,
        position: "absolute",
        top: "65px",
        right: "0px",
        left: "0px"
    },
    [theme.breakpoints.up("sm")]: {
        justifyContent: "center",
        padding: '0px 200px 0px 200px',
        zIndex: 0,
        position: "absolute",
        top: "90px !important",
        right: "0px",
        left: "0px",


    }
},
principalCard: {
  [theme.breakpoints.down("xs")]: {

  },
  [theme.breakpoints.up("sm")]: {
      padding: '0 10% 0 10%',
  }
},
CardsProducts: {
  border: "1px solid #F3F3F3 !important",
  boxShadow: "2px 4px 4px rgba(185, 185, 185, 0.25) !important",
  borderRadius: "21px !important",
  background: "#FFFFFF",
  maxWidth: '1270px !important',
  margin: 'auto',
  [theme.breakpoints.down("xs")]: {
      paddingBottom: '8px',
      marginTop: '15px'
  },
  [theme.breakpoints.up("sm")]: {
      maxWidth: "100%",
      padding: '60px 20px 50px 20px',
      minWidth: '400px',

  }
},
    body:{
    },
  image:{
    display: 'block !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important'
  },
  title:{
    fontSize: '20px !important',
    fontWeight: '700',
    textAlign:'center',
    marginTop: '20px !important',
  },
  checkTitle:{
    fontSize: '18px !important',
    fontWeight: '500',
    marginBottom: '20px !important',
    marginTop: '40px !important'
  },
  tyc:{
    fontSize: '16px !important',
    fontWeight: '400',
    marginBottom: '20px',
    color: '#4C5866'
  },
  url:{
    fontSize: '16px !important',
    fontWeight: '400',
    marginBottom: '20px',
    color: '#4C5866'
  },
  button:{
    marginTop:'30px !important',
    textAlign:'center',
    [theme.breakpoints.up("sm")]: {
        marginLeft: "15% !important",
        marginRight:"15% !important"
    }
  }
}));
export default themeTAndC;