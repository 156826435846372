import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { fontFamily, maxWidth, width } from "@mui/system";

const themeDialog = makeStyles((theme: Theme) => ({
    topScrollPaperDialog: {
        alignItems: "flex-end",
    },
    topPaperScrollBodyDialog: {
        verticalAlign: "bottom",
    },
    dialogContainer: {
        maxWidth: "99% !important",
        width: "100% !important",
        margin: "0px 2px 10px 2px !important",
        borderRadius: "12px !important"
    },

    termsNoticePrivacyBtnAcept: {
        height: "48px",
        width: "90% !important",
        marginBottom: "10px !important",
        justifyContent: "center !important",
        alignItems: "center !important"
    },
    noticePrivacyGrid: {
        marginBottom: "10px !important", 
        marginTop: "10px !important"
    }
}));

export default themeDialog;
