import React, {useState, useEffect, Fragment } from "react";
import OnBoardingLayout from "../utils/on-boarding-layout";
import VerifyEmail from "../components/login/verify-email"
import VerifyPassword from "../components/login/verify-password"
import {useLocation} from "react-router-dom";
import {validate_token} from "../actions/axios-api";
import jwtDecode from "jwt-decode";
import { Stack } from "@mui/material";
import { useCreditContext } from "../utils/providers/CreditProvider";
import Loading from "./Loading";
import MessageError from "../components/loader/messageError";
import InvalidTokenComponent from "../components/invalid-token/invalidToken";
import { ThemeBanner } from "../utils/themes/ThemeBanner";

export default function SignUp() {
  const bannerStyles = ThemeBanner();
  const [stage, setStage] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("")
  const [isLegacyMomoUser, setIsLegacyMomoUser] = useState(false)
  const [signedContract, setSignedContract] = useState(false)
  const [origin, setOrigin] = useState("")
  const [tokenID, setTokenID] = useState("")
  const location = useLocation();
  const { setSession, setNavigateConfig} = useCreditContext();
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    setNavigateConfig({ "previousScreen": "SignUp" });
    const query = new URLSearchParams(location.search);
    const tok = query.get("token")
    setLoading(true)
    if (!tok) {
      setStage("email")
      setLoading(false)
      return
    }
    const decodedToken = jwtDecode(tok);
    let data = {"token": tok}
    const expiryDate = new Date(decodedToken.exp * 1000);
    const dateNow = new Date();
    if (expiryDate < dateNow.getTime()) {
      setStage("invalidToken")
      setError(true)
      setLoading(false)
      return
    }
    const response = await validate_token(data)
    if (response.status !== 200) {
      setError(true)
      setLoading(false)
      return
    }
    if (decodedToken?.origin === ""){
      setOrigin("Digital V2")
    }else{
      setOrigin(decodedToken?.origin)
    }
    setEmail(decodedToken.email);
    setIsLegacyMomoUser(decodedToken?.isLegacyMomoUser ? decodedToken.isLegacyMomoUser : false)
    setSignedContract(decodedToken?.signedContract ? decodedToken.signedContract : false)
    setTokenID(decodedToken?.token_id)
    setSession({"email": decodedToken?.email, "idpdv": decodedToken?.id_pdv})
    setStage("password");
    setToken(tok)
    setLoading(false)
  }, []);

  switch (stage) {
    case "email":
      return <Fragment>
        {loading && <Loading></Loading>}
        {<div style={{ display: (loading || error) ? "none" : "block" }}>
            <OnBoardingLayout screen={<VerifyEmail setLoading={setLoading}/>} newdashboard={true} />
        </div>
        }
      </Fragment>
    case "password":
      return <OnBoardingLayout screen={<VerifyPassword origin={origin} tokenID={tokenID} signedContract={signedContract} token={token} email={email} isLegacyMomoUser={isLegacyMomoUser} />} newdashboard={true} />;
    case "invalidToken":
      return <Stack style={{ display: (error) ? "block" : "none" }} className={bannerStyles.bannerWowMomentOptional}>
        <Stack className={bannerStyles.bannerWowMomentCard}>
          <InvalidTokenComponent />
        </Stack>
   </Stack>
    default:
      return (
          <Fragment>
            {loading && <Loading></Loading>}
            {error && <MessageError></MessageError>}
          </Fragment>
      )
  }

}
