import React from "react";
import { ErrorMessage as Err } from "formik";
import { useTranslation } from "react-i18next";

const ErrorMessage = ({ name, errors }) => {
  const [t] = useTranslation("global");
  const err = errors[name] ? t(errors[name]) : undefined;
  return (
    <Err
      name={name}
      component={() => (
        <div
          className="error"
          style={{
            color: "#B00020",
            marginLeft: "16px",
            fontFamily: "roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.4px",
          }}
        >
          {err}
        </div>
      )}
    />
  );
};

export default ErrorMessage;
