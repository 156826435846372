import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {Grid, Stack} from "@mui/material";
import { ThemeBanner } from '../../utils/themes/ThemeBanner';
import Credits from './credits';
import { useCreditContext } from "../../utils/providers/CreditProvider";
import Loading from "../../views/Loading";
import MessageError from "../loader/messageError";
import PageHeader from "../commons/page-header/page-header";
import {allCreditsTheme} from "../../utils/themes/ThemeAllCredits";
import UserInfoHeader from "./userInfoHeader";

export default function AllCredits() {
    const bannerStyles = ThemeBanner();
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false)
    const { client } = useCreditContext();
    const [t] = useTranslation("global");
    const navigate = useNavigate();

    const tabs = [
        {
            position: 0,
            label: t("Credit.AllCredits.Tabs.All.Title"),
            class: "filterCreditTab",
            loanType: ""
        },
        {
            position: 1,
            label: t("Credit.AllCredits.Tabs.Epin.Title"),
            class: "filterCreditTab",
            loanType: "epin"
        },
        {
            position: 2,
            label: t("Credit.AllCredits.Tabs.Emoney.Title"),
            class: "filterCreditTab",
            loanType: "emoney"
        }
    ]

    const paymentNavigate = () => {
        window.analytics.track("ACTIVE_LOANS_PAY",{
            page: "WC_Active_Loans",
            journey: "WC_Loans",
            button_name: "Pagar",
            product_group: "Lending",
            product_name: "Working_Capital"
        });
        navigate("/Payment")
      };

    let buttonConfig = {
        btn: {
            type: 'button',
            color: 'primary',
            name: 'next',
            "styles": {
                "height": "48px"
            },
            "styleClassName": "action-button",
            label: t("Credit.AllCredits.Buttons.pay"),
        },
        btnNext: () => paymentNavigate()
    }

    useEffect(() => {
        window.analytics.page("PAGEVIEW_ACTIVE_LOANS",{
            page: "WC_Active_Loans",
            journey: "WC_Loans",
            product_group: "Lending",
            product_name: "Working_Capital"
        });
        if (!client) {
            navigate("/Credit");
         }
    }, []);

    const handleBack = () => {
        window.analytics.track("ACTIVE_LOANS_BACK",{
            page: "WC_Active_Loans",
            journey: "WC_Loans",
            button_name: "Regresar",
            product_group: "Lending",
            product_name: "Working_Capital"
        });
    
        navigate("/Credit");
    }
    const allCreditsStyles = allCreditsTheme();

    return (
        <div>
            { loading && <Loading></Loading> }
            { error && <MessageError></MessageError> }
            {client && (
                <Stack className={(loading || error) ? allCreditsStyles.hideContent : bannerStyles.allCreditBanner}>
                    <Grid sx={{ display: { xs: 'block', sm: 'none' } }} paddingY={4}>
                        <PageHeader title={t("Credit.AllCredits.HandleBack.Title")} handleBack={handleBack} />
                    </Grid>
                        <UserInfoHeader userdata={client} visibilityContent={false} />
                    <Stack className={allCreditsStyles.card}>
                        <Credits tabs={tabs} buttonConfig={buttonConfig} client={client.client_id} loading={setloading} validateError={seterror}/>
                    </Stack>
                </Stack>
            )}
        </div>
    );
}
