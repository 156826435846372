import countries from "../utils/translations/country.json";
import currency from "../utils/translations/currency.json";

function seacrhParam(defaultValue, json ) {
  const result = window.location.origin;
  const div = result.split(".");
  let country = defaultValue;
  for (const x in div) {
    for (const y in Object.keys(json)) {
      if (div[x] === Object.keys(json)[y]) {
        country = Object.values(json)[y];
      }
    }
  }
  return country;
}

function getCountry() {
  const country = "PARAGUAY";
  return seacrhParam(country, countries);
}

function getCurrency() {
  const country = "GS";
  return seacrhParam(country, currency);
}

export { getCountry, getCurrency };
