import { origins } from "../../constants/Contract"

export const screen_to_render = (user_data) => {
    if (!user_data?.signed_contract) {
        return "/Contract"
    }
    if (user_data?.document_status === "approved" && user_data?.flow === origins.MOMO) {
        return "/CompleteDocuments"
    }
    if ((user_data?.document_status === "approved" && (user_data?.flow === origins.DIGITAL_V2 || user_data?.flow === origins.MI_TIENDA)) || (user_data?.is_abbreviate && user_data?.is_over_limit && user_data?.has_active_credit)) {
        return "/Credit"
    }
    if (user_data?.document_status === "pending") {
        return "/PendingDocuments"
    }
    if (user_data?.document_status === "rejected") {
        return "/Remedy"
    }
}