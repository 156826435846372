import React, {Fragment} from 'react'
import { useTranslation } from "react-i18next";
import { Grid, Typography, Avatar, Stack } from "@mui/material";
import countryCode from '../../utils/translations/countryCode.json'
import { getCurrency } from '../../utils/getCountry';
import LoadWallet from "./loadWallet";
import PageHeader from '../commons/page-header/page-header';
import themeCredit from '../../utils/themes/ThemeCredit';
import { useTheme } from "@mui/material/styles";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuItemsIcon from '../../assets/generic/MenuItems.svg';

export default function UserInfoHeader(
    {
        userdata,
        visibilityContent,
        navigate,
        title,
        onMenuItemsClick,
    }) {
    const [t] = useTranslation("global");
    const styles = themeCredit();
    const currency = getCurrency()
    const theme = useTheme();

    const upperCase = () => {
        return userdata?.first_name.charAt(0).toUpperCase() + userdata?.first_name.slice(1);
    }

    const component = () => {
        if (navigate && title) {
            return (
                <Grid item xs={6} className={styles.amount}>
                    <PageHeader title={title} handleBack={navigate} />
                </Grid>
            )
        } else {
            return (
                <Fragment>
                    <Stack display="flex" alignItems="center" direction="row" item sx={6} className={styles.name}>

                        {onMenuItemsClick && (
                            <Stack sx={{ cursor: 'pointer' }} role="button" mr={2} onClick={onMenuItemsClick}>
                                <img src={MenuItemsIcon} alt="menu"/>
                            </Stack>
                        )}

                        <Avatar sx={{ bgcolor: "#363853", float: 'left', width: 35, height: 35 }}>
                            <PersonOutlineIcon style={{ textAlign: 'center' }} />
                        </Avatar>
                        <Typography className={styles.textUserName} style={{ marginLeft: "10px", marginTop: '5px', float: 'left' }}>
                            {t("Credit.header.greeting")}, <strong>{upperCase()} </strong>
                        </Typography>
                    </Stack>
                </Fragment>
            )
        }
    }

    let matches = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Fragment>
        {(matches || visibilityContent) ?
        <Grid
            container
            justifyContent="space-between"
        >
            {component()}
            <Grid item sx={6} className={styles.amount}>
                    <Grid item sx={12}>
                        <Typography className={styles.textTitleBalance}>
                            {t("Credit.header.titleBalance")}
                        </Typography>
                    </Grid>
                    <Grid item sx={12}>
                        <Typography mr={1} className={styles.textAmountBalance} style={{float: 'left'}}>
                            {currency.toUpperCase()} {new Intl.NumberFormat(countryCode[userdata?.currency],{minimumFractionDigits: 0,maximumFractionDigits: 0}).format(userdata?.total_balance)}
                        </Typography>
                        <LoadWallet style={{float: 'left'}}/>
                    </Grid>
            </Grid>
        </Grid>: ""}
        </Fragment>
    )
}