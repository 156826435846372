import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

export const ThemeUserControlCenter = makeStyles((theme: Theme) => ({
    mainCard: {
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        height: 'calc(100vh - 82px)',
        width: '98%',
        right: 0,
        top: '82px'
    },
    avatar: {
        position: 'absolute',
        top: '64px',
        left: '24px',
        background: '#FFFFFF',
        padding: '4px',
        borderRadius: '50%',
        zIndex: 3,
    },
    userInfo: {
        position: 'absolute',
        top: '88px',
        left: '108px',
        zIndex: 3,
    },
    userNameTypography: {
        fontSize: '16px',
        color: '#4C5866',
        fontFamily: 'Readex Pro'
    },
    emailTypography: {
        fontSize: '12px',
        color: '#4C5866',
        fontFamily: 'Readex Pro'
    },
    closeSessionWrapper: {
        position: 'absolute',
        bottom: '24px',
        left: '0',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeButtonWrapper: {
        cursor: 'pointer',
        position: 'absolute',
        right: '0',
        top: '16px',
        backgroundColor: '#363853',
        width: '40px',
        height: '40px',
        borderBottomLeftRadius: '16px',
        borderTopLeftRadius: '16px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    ghostButton: {
        color: '#363853',
        fontFamily: 'Readex Pro',
        fontSize: '14px',
        fontWeight: '600'
    },
    myHistory: {
        position: 'absolute',
        top: '100px',
        left: '0',
        width: '216px',
        height: "23px",
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontFamily: 'Readex Pro',
        color: '#4C5866',
        padding: '0 20px',
        boxSizing: 'border-box',
        fontSize: '16px',
    }
}));