import { Grid } from "@mui/material";
import React, { useEffect, useState, Fragment } from "react";
import themePayment from "../../../utils/themes/ThemePaymentOptions";
import CardPayment from "./card-payment";
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {
    withStyles
} from "@material-ui/core/styles";
import Button from '../../../components/inputs/button';
import { useTranslation } from "react-i18next";
import { useCreditContext } from "../../../utils/providers/CreditProvider";
import {
    resetNumber,
    _formatter
} from "../../../utils/getCardInfo";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { getFormattedDateWithDash, getFormattedDate } from "../../../utils/functions/utils";
import PageHeader from "../../commons/page-header/page-header";
import { useAPIContext } from "../../../utils/providers/APIProvider";

const CustomTooltip = withStyles({
    tooltip: {
        color: "white",
        backgroundColor: "#363853"
    }
})(Tooltip);
export default function OptionPayment({
    _paymentTypes,
    _dataPayment,
    validateError,
    loading,
    matches,
    backnavigate,
    title
}) {
    const {
        client,
        setPaymentType,
        setPaymentOptions,
        setPayment,
        clientId,
        emoneyNumber,
        epinNumber
    } = useCreditContext();
    const {create_payment} = useAPIContext()
    const [t] = useTranslation("global");
    const navigate = useNavigate();


    const _paymentConfig = {
        "required": {
            "title": t("OptionPayment.RequiredTitle"),
            "titleArrears": t("OptionPayment.RequiredTitleArrears"),
            "date": "",
            "amount": "",
            "currency": "PYG"
        },
        "total": {
            "title": t("OptionPayment.TotalTitle"),
            "amount": "",
            "currency": "PYG"
        },
        "optional": {
            "title": t("OptionPayment.OptionalTitle"),
            "legend": t("OptionPayment.OptionalLegend"),
            "error": t('OptionPayment.Error')
        }
    };
    const alerts = {
        "normal": {
            "text": t("OptionPayment.NormalAlert"),
            "tooltip": false
        },
        "arrears": {
            "text": t("OptionPayment.ArrearsAlert"),
            "tooltip": false
        },
        "due": {
            "text": t("OptionPayment.DueAlert"),
            "tooltip": true,
            "tooltipInfo": t("OptionPayment.DueTooltip")

        }
    }
    const [paymentConfig, setPaymentConfig] = useState({});
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [optionalAmount, setOptionalAmount] = useState();
    const [data, setData] = useState({});
    const [optionSelect, setOptionSelect] = useState('required');
    const [lineStatus, setLineStatus] = useState("due");
    const [error, setError] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [focus, setFocus] = useState(false);
    const [errorText, setErrorText] = useState();
    const onColor = 'required';
    const styles = themePayment();
    const [_optionalAmount, set_OptionalAmount] = useState();

    const inputValidates = (value) => {
        if (value === '' || value === '0' || value === 0) {
            setErrorText(t('OptionPayment.Error2'));
            setError(true);
        } else {
            setErrorText(paymentConfig['optional'].error)
            setError(resetNumber(parseInt(value), '.') > data.total_credits)
        }

    }

    function containsOnlyNumbers(str) {
        return /^\d+$/.test(str);
    }
    const handleOnChange = (e) => {
        let value = e.target.value;
        if (containsOnlyNumbers(value) || value === '') {
            setOptionalAmount(value);
            setErrorText()
            setError(false);
        }
    }
    const handleOnBlur = (e) => {
        let value = e.target.value;
        if (containsOnlyNumbers(value)) {
            inputValidates(value);
            setOptionalAmount(value);
            set_OptionalAmount(_formatter(value));
        }
        if (value === '') { set_OptionalAmount('') }
        setFocus(false);
    }
    const handleOnFocus = (e) => {
        setFocus(true);
    }
    const handleOnClick = (e) => {
        if (e.target) {
            let value = e.target.value;

            setOptionSelect(value);
            setDisabledButton(dictionaryDisabled[value])
            if (value !== 'optional') {
                setError(false);
            } else {
                setError(parseInt(optionalAmount) > data.total_credits);
            }
        }
        
    }
    const handleOnClickGrid = (value) => {
        const nameButton = {
            'required': 'Credito por vencer',
            'total': 'Deuda total',
            'optional': 'Otra cantidad',
        }
        const nameSegment = {
            'required': 'PAYMENT_ALL',
            'total': 'PAYMENT_CURRENT',
            'optional': 'PAYMENT_CUSTOM',
        }
        window.analytics.track(nameSegment[value], {
            page: 'WC_Payment',
            journey: 'WC_Loans',
            product_group: 'Lending',
            product_name: 'Working_Capital',
            button_name: nameButton[value]
        });

        handleOnClick({
            e: {
                target: {
                    value: value
                }
            }
        })
        setDisabledButton(dictionaryDisabled[value])
    }
    const dictionaryDisabled ={
        "required": data?.total_credits_expire <= 0,
        "total": data?.total_credits <= 0,
        "optional": false
    }

    const buildCard = (paymentType) => {
        let card = (paymentType === 'optional');
        return <Grid item xs={12} sm={4} key={paymentType} onClick={() => handleOnClickGrid(paymentType)}>
            <CardPayment
                paymentConfig={paymentConfig}
                paymentType={paymentType}
                optionSelect={optionSelect}
                handleOnClick={handleOnClick}
                lineStatus={lineStatus}
                onColor={onColor}
                otherCard={card}
                focus={focus}

                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                value={optionalAmount}
                _value={_optionalAmount}
                error={error}
                errorText={errorText}
            /></Grid>
    }
    let buttonConfig = {
        btn: {
            disabled: disabledButton, 
            type: 'button',
            color: 'primary',
            name: 'next',
            "styles": {
                "height": "48px",
                "width": !matches ? "105px": "100%",
            },
            "styleClassName": "action-button",
            label: "Pagar",
        },
        btnNext: () => handlePayment(),


    }
    const tooltipElement = () => {
        return <CustomTooltip title={alerts[lineStatus].tooltipInfo} arrow placement="top" enterTouchDelay={1}>
            <HelpOutlineOutlinedIcon className={styles.questionIcon} />
        </CustomTooltip>
    }

    const _LineStatus = (line) => {//realizar pruebas de logica
        if (client.arrears === 'false' && line) {
            return 'due';
        } else if (client.arrears === 'true') {
            return 'arrears';
        }
        return 'normal';
    }
    const nextView = async (response, _paymentOptions) => {
        _paymentOptions["optional"].length = response.data.loans.length;
        _paymentOptions["optional"].loans = response.data.loans;
        setPaymentOptions(_paymentOptions);
        await setPayment(response.data?.loans);
    }
    const handlePayment = async () => {
        window.analytics.track('PAYMENT_CONFIRMATION', {
            page: 'WC_Payment',
            journey: 'WC_Loans',
            product_group: 'Lending',
            product_name: 'Working_Capital',
            button_name: 'Pagar'
        });
        if (!error) {
            let _error = false;
            if ((optionalAmount === '' || optionalAmount === undefined) && (optionSelect === 'optional')) {
                setErrorText(t('OptionPayment.Error2'));
                setError(true);
                _error = true;
            }
            if (!error && !_error) {
                loading(true)
                setPaymentType(optionSelect);
                let _paymentOptions = {
                    "required": {
                        "length": data.lot_credits_expire,
                        "amount": data.total_credits_expire,
                        "loans": data.loans_expire
                    },
                    "total": {
                        "length": data.lot_credits,
                        "amount": data.total_credits,
                        "loans": data.loans
                    },
                    "optional": {
                        "length": 0,
                        "amount": optionalAmount,
                        "loans": []

                    }
                }
                let body = {
                    "amount": String(_paymentOptions[optionSelect].amount),
                    "client_id": client.client_id,
                    "loans": _paymentOptions[optionSelect].loans,
                    "currency": client.currency,
                    "type": optionSelect.toUpperCase(),
                    "external_id": `${client.client_id}-${uuidv4()}`,
                    "epin_number": epinNumber,
                    "emoney_number": emoneyNumber,
                }

                    const response = await create_payment({params: {data: body, param: `client_id=${clientId}`}});
                    if (response.status === 400) {
                        loading(false)
                        validateError(true)
                    }
                    else {
                        const nameEvent = {
                            'total': 'WOW_OK_VALUE',
                            'optional': 'WOW_PENDING_VALUE',
                            'required': 'WOW_ARREARS_VALUE'
                        }
                        const namePage = {
                            'total': 'WC_Payment_WOW_OK',
                            'optional': 'WC_Payment_WOW_Pending',
                            'required': 'WC_Payment_WOW_Arrears'
                        }
                        window.analytics.track(nameEvent[optionSelect],{
                            page: namePage[optionSelect],
                            journey: "WC_Loans",
                            product_group: "Lending",
                            product_name: "Working_Capital",
                            value: String(_paymentOptions[optionSelect].amount)
                        });
                        loading(false)
                        await nextView(response, _paymentOptions);
                        navigate("/WoWMoment");
                    }
                
            }
        }
    }
    const _PaymentConfig = (config) => {
        let cardConfig = _paymentConfig;
        if(config?.credit_date_expire.includes('-')){
            cardConfig['required'].date = getFormattedDateWithDash(config?.credit_date_expire);
        }else{
            cardConfig['required'].date = getFormattedDateWithDash(getFormattedDate(config?.credit_date_expire));
        }
        cardConfig['total'].amount = config?.total_credits;
        cardConfig['required'].amount = config?.total_credits_expire;
        return cardConfig;
    }

    useEffect(() => {
        if (!client) {
            return navigate("/Credit");
        }
        if (_dataPayment) {
            setLineStatus(_LineStatus(_dataPayment.credit_info?.credit_today_expire));
            setPaymentConfig(_PaymentConfig(_dataPayment.credit_info));
            setPaymentTypes(_paymentTypes);
            setData(_dataPayment.credit_info);
            setDisabledButton(_dataPayment.credit_info?.total_credits_expire <= 0)
        }
    }, [_dataPayment]);

    const button = () => {
        return (
            <Grid
                item
                xs={12}
                sm={2}
                key={'button-credit'}
                style={{ marginTop: !matches? "0px": '35px',  textAlign: !matches ? "end": "center" }}
            >
                <div>
                    <Button {...buttonConfig} />
                </div>
            </Grid>
        )
    }

    return (
        <Fragment>
            <div className={styles.divContainer}>
                    {!matches && <PageHeader title={title} handleBack={backnavigate} px={0} classNameButton={'pageHeaderButton'}/>}
                <div>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={10} className={styles.optionPaymentTilte}>
                            {t('OptionPayment.Title')}
                        </Grid>
                        {!matches && button()}
                        <Grid container xs={12} className={styles.optionPayment}>
                            {paymentTypes.map((paymentType) => {
                                return buildCard(paymentType)
                            })}

                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: '40px' }}>
                            <p className={styles.Alert}>
                                {alerts[lineStatus].text} {alerts[lineStatus].tooltip && tooltipElement()}
                            </p>
                        </Grid>
                    </Grid>
                </div>
                {matches && button()}
            </div>
        </Fragment>
    )
}
