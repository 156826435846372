import React, { useState, Fragment } from "react";
import HomeProduct from "../components/credit/homeProduct";
import UserInfoHeader from "../components/credit/userInfoHeader";
import Loading from "./Loading"
import MessageError from "../components/loader/messageError";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReturnPage from "../components/credit/ReturnPage";
import { useCreditContext } from "../utils/providers/CreditProvider";

export default function Product() {
    const { client } = useCreditContext();
    const [error, seterror] = useState(false)
    const [loading, setloading] = useState(false)
    const theme = useTheme();

    let matches = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Fragment>
            {loading && <Loading></Loading>}
            {error && <MessageError></MessageError>}
            {<div style={{ display: (loading || error) ? "none" : "block", marginTop:'-15px' }}>
                <ReturnPage matches={matches}>
                    <UserInfoHeader userdata={client} visibilityContent={false} />
                    <HomeProduct matches={matches} validateError={seterror} loading={setloading} client={client} />
                </ReturnPage>
            </div>
            }
        </Fragment>
    );
}