import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const actualWidth = window.innerWidth;

const themeAlert = makeStyles((theme: Theme) => ({
    simpleAlert: {
        display: 'absolute', 
        paddingBottom: '30px',
        alignContent: "center", 
        textAlign: "center",

    },
    bodyAlert:{
        borderRadius: '8px', 
        height: "5%", 
        width: "auto", 
        margin: '0 auto',
        padding: "0px 5px 0px 5px"

    },
    tableAlert:{
        margin: '0 auto !important'
    },
    iconAlert:{
        textAlign: 'center', 
        paddingTop: '5px'
    },
    textAlert:{
        textAlign: 'center'
    }
}));

export default themeAlert;
