import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const themePayment = makeStyles((theme: Theme) => ({
    boxCard: {
        borderColor: "#EEEEEE",
        borderRadius: "18px",
        border: '2px solid',

    },
    optionPayment: {
        width: "100%"
    },
    divContainer: {
        [theme.breakpoints.up("sm")]: {
            width:'80% !important',
            borderRadius: '24px',
            boxShadow: '0px 4px 12px 0px rgba(194, 209, 217, 0.46)',
            padding:'60px'
        },
        padding: '0px 20px 0px 20px',
        background: "#FFFFFF",
        marginTop: "20px !important",
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px'
    },
    titleCard: {
        [theme.breakpoints.up("md")]: {
            fontSize: "18px",
            lineHeight: "26px",
            letterSpacing: "0.247px",
            color:"var(--text-charcoal-1, #343C46)",
        },
        fontFamily: 'Readex Pro !important',
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "22px",
        letterSpacing: "0.25px",
        color: "#4C5866"
    },
    dateCard: {
        [theme.breakpoints.up("md")]: {
            fontSize: "12px",
            lineHeight: "18px",
            letterSpacing: "0.247px",
            color:"var(--text-charcoal-2, #4C5866)"
        },
        fontFamily: 'Readex Pro !important',
        fontWeight: "400",
        fontSize: "10px",
        lineHeight: "24px",
        letterSpacing: "0.25px",
        color: "#4C5866"
    },
    legendCard: {
        fontFamily: 'Readex Pro !important',
        fontWeight: "400",
        fontSize: "10px",

        color: "#4C5866"
    },
    ErrorCard: {
        fontFamily: 'Readex Pro !important',
        fontWeight: "400",
        fontSize: "10px",

        color: "#D95A3A"
    },
    comunCard: {
        [theme.breakpoints.up('lg')]: {
            height: '60px !important',
        },
        [theme.breakpoints.up("sm")]: {
            borderRadius: '16px',
            background:'#FFF',
            boxShadow:'none',
            height: "100%"
        },
        boxShadow:'2px 3px 4px 0px rgba(194, 209, 217, 0.30)',
        border:'1px solid #EEE',
        padding: '2px',
        borderRadius: '14px',
        width: "100%",
        height: "100%"
    },
    specialCard: {
        [theme.breakpoints.up('lg')]: {
            height: '100% !important',
        },
        [theme.breakpoints.up("sm")]: {
            borderRadius: '16px',
            background:'#FFF',
            boxShadow:'none',
            height: "100%"
        },
        boxShadow:'2px 3px 4px 0px rgba(194, 209, 217, 0.30)',
        border:'1px solid #EEE',
        padding: '2px',
        borderRadius: '14px',
        width: "100%",
        height: "100%"
    },
    AmountCard: {
        [theme.breakpoints.up("md")]: {
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.247px",
            color:"var(--text-charcoal-1, #343C46)"
        },
        fontFamily: 'Readex Pro !important',
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: "0.25px",
        color: "#4C5866"
    },
    Alert: {
        [theme.breakpoints.up("sm")]: {
            color:'var(--text-charcoal-3, #708195)',
            textAlign:'start',
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.247px"
        },
        fontFamily: 'Readex Pro !important',
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.25px",
        color: "var(--text-charcoal-2, #4C5866)",
        textAlign:"center"
    },
    questionIcon:{
        width: '15px !important',
        marginLeft: "0px !important",
        display: 'inline !important',
        marginBottom: "-8px !important"
    },
    optionPaymentTilte:{
        [theme.breakpoints.up("sm")]: {
            color:'var(--text-charcoal-1, #343C46)',
            fontSize: "24px",
            lineHeight: "34px",
            letterSpacing: "0.247px",
            fontWeight: "600",
            marginLeft: "0px",
        },
        fontFamily: 'Readex Pro !important',
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.25px",
        color: "#363853",
        marginLeft: "15px",
        paddingTop: "20px",
        paddingBottom: "18px"

    }
}));

export default themePayment;