import React, {useEffect, useState} from "react";
import FormForgotPassword from "../components/reset-password/formForgotPassword";
import OnBoardingLayout from "../utils/on-boarding-layout";
import MessageError from "../components/loader/messageError";
import Loading from "./Loading";

export default function ForgotPassword() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        window.analytics.page('PAGEVIEW_PASSWORD_RECOVERY', {
            page: 'WC_Password_Recovery',
            journey: 'WC_Onboarding',
            product_group: 'Lending',
            product_name: 'Working_Capital',
        });
    }, []);
    return (<div>
        {error && <MessageError path={"SignIn"}/>}
        {loading && <Loading/>}
        <div style={{display: (loading || error) ? "none" : "block"}}>
            <OnBoardingLayout screen={<FormForgotPassword
                loading={loading}
                setLoading={setLoading}
                error={error}
                setError={setError}
            />} newdashboard={true} smallcard={true}/>
        </div>
    </div>)
}
