import React from 'react'
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";

import OnBoardingLayout from "../utils/on-boarding-layout";
import Notification from "./Notification";
import loader from '../assets/loader/Loader.svg';
import loaderAnimation from '../assets/loader/66265-loader.json';

export default function Loading() {
    const [t] = useTranslation("global");
    
    return(
        <OnBoardingLayout 
            screen={
                <Notification 
                    animation={<Lottie animationData={loaderAnimation} loop={true} />}
                    image={loader}
                    title={t('Notifications.Loader.Title')}
                    isAnimation={true}
                />}
            newdashboard={false}
            fullcard={false}
            smallcard={false}
            cardNotification={true}
            loading={true}
        />
    );
}
