import React, {useEffect} from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

export default function Bar({props, flag}) {
    const [progress, setProgress] = React.useState(0);
    let resultColor;
    if(flag === true && props.arrears === 'false'){
        resultColor = '#E29836'
    }else if(props.arrears === 'true'){
        resultColor = '#D95A3A'
    }else{
        resultColor = '#66BC6B'
    }
    useEffect(() => {
        setProgress((props.consumed_credit * 100) / props.credit_amount)
    }, []);

    return (
        <Box sx={{width: '100%'}} align={"center"}>
            <LinearProgress variant="determinate" value={progress} sx={{backgroundColor: "#CFD0DF",
                height: 11, borderRadius: 16, "& .MuiLinearProgress-barColorPrimary": {
                    backgroundColor: resultColor}}}/>
        </Box>
    )
}
