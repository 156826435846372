import React from "react";
import { Typography } from "@mui/material";

const Title = ({ title, className }) => {
  return (
  <Typography className={`title ${className}`} justifyContent="center" textAlign="center">
      {title}
    </Typography>
  );
};

export default Title;
