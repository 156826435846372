import { React, useEffect, useState } from "react";
import LinkComponent from "../inputs/link";
import GenericForm from "../../components/genericForm";
import { schema, getYup } from "../../utils/yup";
import CustomAlert from "../commons/alert-ux-v2";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Grid, Box
} from "@mui/material";
import ImgForgotPassword from '../../assets/generic/ForgotPassword.svg'
import forgotPasswordData from "../config/forgot-password.json";
import resetPasswordData from "../config/reset-password.json";
import messagePasswordData from "../config/message-forgot-password.json";
import themeOtp from "../../utils/themes/ThemeForgotPassword";
import { send_email_credentials, change_credentials, reset_credentials } from "../../actions/axios-api";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
export default function FormForgotPassword({setLoading}) {
  const [t] = useTranslation("global");
  const [data, setData] = useState(forgotPasswordData.forms);
  const [validates, setValidates] = useState({});
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [_, emptyFunction] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const _params = new URLSearchParams(location.search);
  const _token = _params.get("token");


  const [Icon, setIcon] = useState();
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState(false);
  const [customColor, setCustomColor] = useState(false);
  const Navigate = useNavigate();
  const styles = themeOtp();

  const handleSubmit = async (values) => {
    let body_reset = {
      "email_device": values['email'],
      "email": values['email']
    }
    let body_send_email = {
      "email": values['email']
    }
    setLoading(true);
    window.analytics.track('RECOVERY_STARTED', {
      page: 'WC_Password_Recovery',
      journey: 'WC_Onboarding',
      product_group: 'Lending',
      button_name: 'Conectarse',
      product_name: 'Working_Capital',
    });
    await reset_credentials(body_reset);
    await send_email_credentials(body_send_email);
    messageEmailSend(false);
  };
  const handleSave = async (values) => {
    let body_change = {
      "token": _token,
      "code": values['old_password'].replace(/\s+/g, ''),
      "password": values['password']
    }
    setLoading(true);
    const response = await change_credentials(body_change);

    messagePasswordChanges(response.status == 200 ? false : true);

  };
  const messageEmailSend = (hasError) => {
    setStep(2);
    setData(messagePasswordData.forms);
    setLoading(false);
  };
  const messageFinishOk = async (error) => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000)
    if (!error) {
      setTimeout(() => {
        Navigate("/SignIn")
      }, 1000)
    }
  }
  const messagePasswordChanges = async (hasError) => {

    if (hasError) {
      setMessage(t("ForgotPassword.Step2.TitleError"));
      setColor('red');
      setIcon(CloseIcon);
      setCustomColor({ customColor: '#ab3838' })
    } else {
      setMessage(t("ForgotPassword.Step2.TitleSuccess"));
      setColor('green');
      setIcon(CheckIcon);
      setCustomColor({})
    }
    setLoading(false);
    await messageFinishOk(hasError);

  };
  const screenToRender = () => {

    if (step === 1) {
      if (_token) {
        setStep(3);
        setData(resetPasswordData.forms);
        let _validates = {};
        resetPasswordData.forms.fields.forEach(({ props, validations }) => {
          _validates[props.name] = schema(validations, props.type);
        });
        setValidates(_validates);
      } else {
        setData(forgotPasswordData.forms)
      }
    }
  }

  const labelInfo = {
    "props": {
      "id": "3",
      "type": "link",
      "name": "link",
      "textlink": "ForgotPassword.Step1.ResendCode",
      "route": "",
      "onClick": "reloadPage",
      "readOnly": false,
      "created": false,
      "style": {
        "marginBottom": "40px",
        "textAlign": "center",
        "marginTop": "-10px"
      },
      "errorStyle": {
        "marginBottom": "10px",
        "fontSize": "12px",

      }
    }
  }
  useEffect(() => {
    screenToRender();

  }, [data]);

  return (
    <Box style={{ paddingBottom: "70px" }}>
      <Grid container>
        {(step === 1 || step === 2) &&
          <Grid item xs={12} className={styles.imageBanner} >
            <img src={ImgForgotPassword} alt="ForgotPassword" />
          </Grid>}
        <Grid item xs={12} >
          <GenericForm
            form={data}
            initialValue={{}}
            validates={getYup(validates)}
            onSubmit={step === 1 ? handleSubmit : handleSave}
            setLoginError={emptyFunction}
            disabledButton={disabledButton}
            setDisabledButton={setDisabledButton}
          />
        </Grid>
        {(step === 3) &&
          <Grid item xs={12} >
            <LinkComponent {...labelInfo} /> </Grid>
        }

        <Grid item xs={12} style={{ marginTop: "-25px" }}>
          {open &&
            <CustomAlert Icon={Icon} color={color} {...customColor} text={message} />
          }
        </Grid>
      </Grid>
    </Box>
  );
}
