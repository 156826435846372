import React, {useEffect} from 'react'
import { useTranslation } from "react-i18next";

import OnBoardingLayout from "../utils/on-boarding-layout";
import Notification from "./Notification";
import messageRemedy from '../assets/generic/messageRemedy.svg';
import {wcDefaultSegmentProperties} from '../utils/segment'

export default function CompleteDocuments() {
    const [t] = useTranslation("global");

    useEffect(() => {
        window.analytics.page("PAGEVIEW_DOC_OK", {
            page: "WC_Docs_OK",
            ...wcDefaultSegmentProperties()
        });
    }, []);

    return(
        <OnBoardingLayout screen={<Notification image={messageRemedy} title={t('Credit.legacy.title')} message={t('Credit.legacy.message')}/>} newdashboard={true} fullcard={true} smallcard={false} />
    );
}
