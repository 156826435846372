import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from 'prop-types';

const TextFieldMaterial = ({ props, onChange }) => {
  const [fieldValue, setFieldValue] = useState(props?.value ? props.value : "")
  
  const inputComponent = (data) => {
    const {label, name, id, type, title, placeholder, testId, minLength, maxLength, fieldName } = data;
    const configTextField = {
      label: label,
      name: name,
      id: id,
      type: type,
      title: title,
      placeholder: placeholder
    };
  
    return (
      <TextField
        data-testid={testId}
        variant="outlined"
        {...configTextField}
        inputProps={{ minLength: minLength, maxLength: maxLength }}
        onChange={(e) => onChange(e.target.value, setFieldValue, fieldName)}
        value={fieldValue}
        defaultValue={fieldValue}
      />
    );
  }
  
  return (
    inputComponent(props)
  );

};

TextFieldMaterial.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.any,
    name: PropTypes.any,
    id: PropTypes.any,
    type: PropTypes.any,
    title: PropTypes.any,
    placeholder: PropTypes.any,
    minLength: PropTypes.any,
    maxLength: PropTypes.any,
    fieldName: PropTypes.any,
    testId: PropTypes.any
  }),
  onChange: PropTypes.any.isRequired
};

export default TextFieldMaterial;
