import React, { useEffect, Fragment, useState } from "react";
import ConfigureCreditCard from '../components/credit/config-credit/config-credit-card'
import { useCreditContext } from "../utils/providers/CreditProvider";
import { ThemeBanner } from "../utils/themes/ThemeBanner";
import { Grid } from "@mui/material";
import PageHeader from "../components/commons/page-header/page-header";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import MessageError from "../components/loader/messageError";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import UserInfoHeader from "../components/credit/userInfoHeader";

export default function ConfigureCredit() {
    const navigate = useNavigate();
    const { productName, setNavigateConfig, client,
        isAbbreviate, 
        isOverLimit,
        documentStatus } = useCreditContext();
    const title = `Configura tu `;
    const theme = useTheme();

    const bannerStyles = ThemeBanner();
    const [error, seterror] = useState(false)
    const [loading, setloading] = useState(false)
    const updateValues = async () => {
        setNavigateConfig({})
    }

    useEffect(async () => {
        await updateValues();
    }, []);

    const handleBack = () => {
        window.analytics.track("REQUEST_CONTINUE_BACK",{
            page: "WC_Request_Config",
            journey: "WC_Loans",
            value: "Regresar",
            product_group: "Lending",
            product_name: "Working_Capital"
        });
        navigate(-1);
    }
    let matches = useMediaQuery(theme.breakpoints.up("sm"));
    const lowerLimit = 100000
    return (
        <Fragment>
            {loading && <Loading></Loading>}
            {error && <MessageError></MessageError>}
            {<div style={{ display: (loading || error) ? "none" : "block"}}>
                <Grid className={matches ? bannerStyles.bannerOne_web : bannerStyles.bannerTwo}>
                    <Grid sx={{ display: { xs: 'block', sm: 'none' } }} paddingY={3}>
                        <PageHeader title={title} handleBack={handleBack} product={productName} />
                    </Grid>
                    <UserInfoHeader userdata={client} visibilityContent={false} />
                    <ConfigureCreditCard validateError={seterror} loading={setloading} matches={matches} title={`${title}${productName}`} 
                    lowerLimit={lowerLimit}
                    buttonBlocked={(client['available_credit'] < lowerLimit) || (isAbbreviate && isOverLimit && (documentStatus === 'pending' || documentStatus === 'rejected')) || client.arrears === 'true'}/>
                </Grid>
            </div>
            }
        </Fragment>
    );
}
