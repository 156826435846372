import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const Submit = ({ btn, submit, disabledButton }) => {
  const [t] = useTranslation("global");
  return (
    <Button
      variant="contained"
      fullWidth
      type={btn.type}
      name={btn.name}
      onClick={submit}
      style={btn.styles}
      disabled={disabledButton}
    >
      {t(btn.label)}
    </Button>
  );
};

export default Submit;
