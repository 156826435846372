import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Card, Typography, Stack } from "@mui/material";
import themeProduct from "../../../utils/themes/ThemeProduct";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../commons/page-header/page-header";
import themeConfigureCredit from "../../../utils/themes/ThemeConfigureCredit";
import "./slicebar.css";

const ConfigureCreditMask = ({ SliderAmountComponent, ScheduleCardComponent, ButtonComponent, ResumenComponent, visible, title }) => {
    const styles_p = themeProduct();
    const styles_cc = themeConfigureCredit();

    const dictionary = {
        'first-body-false': styles_cc.configureCreditBody,
        'first-body-true': styles_p.cardProduct,
    }
    const props = {
        SliderAmountComponent,
        ScheduleCardComponent,
        ButtonComponent,
        ResumenComponent,
        visible,
        title
    }
    return <div className={dictionary[`first-body-${visible}`]}>
        {visible ? <Web {...props} /> : <Mobile {...props} />}
    </div>

}
export default ConfigureCreditMask;

const Web = ({ SliderAmountComponent, ScheduleCardComponent, ButtonComponent, ResumenComponent, title }) => {
    const [t] = useTranslation("global");
    const styles_ = themeProduct();
    const navigate = useNavigate();
    const styles_cc = themeConfigureCredit();

    return <Card className={styles_.CardsProducts} style={{
        overflowX: 'auto',
        overflowY: 'hidden',
        whiteSpace: 'nowrap'
    }}>
        <div style={{
            minWidth: '850px',
        }}>
            <Grid sx={{ display: { xs: 'none', sm: 'block' }, marginBottom: '10px' }} paddingY={0}>
                <PageHeader
                    title={t("Buttons.Back")} handleBack={() => navigate(-1)} />
            </Grid>
            <Grid mt={2} container display="flex" className={styles_cc.principalCard}>
                <Grid item xs={12} md={1}>
                    <Typography className={styles_cc.titleDesktop} ml={2}>
                        {title}
                    </Typography>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={7} >
                        <Grid container>
                            <Grid item className={styles_cc.configTextGrid}>{t('ConfigureCredit.chooseAmount')}</Grid>
                            <Grid item xs={12} className={styles_cc.sliderAmount}>
                                {SliderAmountComponent}
                            </Grid>
                            <Grid item className={styles_cc.dividerLine} xs={12}></Grid>
                            <Grid item className={styles_cc.scheduleTextGrid}>
                                {t('ConfigureCredit.chooseTime')}:
                            </Grid>
                            <Grid item xs={12} className={styles_cc.buttonsGrid}>
                                {ScheduleCardComponent}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        alignItems="center"
                        xs={6}
                        sm={5}
                    >
                        <Stack direction="column" spacing={3}>
                            <Grid item xs={12}>
                                {ResumenComponent}
                            </Grid>
                            <Grid item xs={5} sm={4} lg={3} className={styles_cc.buttonsText}>
                                {ButtonComponent}
                            </Grid>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </Card>
}

const Mobile = ({ SliderAmountComponent, ScheduleCardComponent, ButtonComponent, ResumenComponent }) => {

    const [t] = useTranslation("global");
    const styles = themeConfigureCredit();
    return <>
        <div style={{ marginLeft: '4%', marginRight: '4%', }}>
            <Grid container className={styles.firstCard}>
                <Grid item className={styles.configTextGrid}>{t('ConfigureCredit.chooseAmount')}</Grid>
                <Grid item className={styles.sliderAmount} xs={12}>
                    {SliderAmountComponent}
                </Grid>
                <Grid item className={styles.dividerLine} xs={12}></Grid>
                <Grid container className={styles.buttonsGrid}>
                    {ScheduleCardComponent}
                </Grid>
            </Grid>
        </div>
        <div style={{ background: '#FFFFFF', borderRadius: '11px 11px 0 0', marginTop: '30px' }}>
            <div style={{ marginLeft: '8%', marginRight: '8%' }}>
                <div style={{ height: '22px' }}></div>
                {ResumenComponent}
            </div>
            <div style={{ marginTop: "35px", marginLeft: '8%', marginRight: '8%' }} id="div buttton">
                <Grid container spacing={2} className="grid-buttons">

                    <Grid
                        item
                        xs={12}
                        md={12}
                        key={'button-credit'}
                    >
                        <div>
                            {ButtonComponent}
                        </div>
                    </Grid>

                </Grid>
            </div>
        </div>
    </>
}