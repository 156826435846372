import React from 'react';
import {creditDetailsTheme} from "../../../utils/themes/ThemeCreditDetails";
import {Box, Grid, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {getFormattedDate} from "../../../utils/functions/utils";
import { styled } from '@mui/material/styles';
import formatter from '../../../utils/translations/formatter';
const CreditDetailCard = ({creditDetails, credit, matches}) => {

    const [t] = useTranslation("global");

    const styles = creditDetailsTheme();

    const ItemText = styled(Typography)(({ theme }) => ({
        textAlign: 'left',

    }));

    const ItemValue = styled(Typography)(({ theme }) => ({
        textAlign: 'right',
        [theme.breakpoints.up("sm")]: {
            paddingRight: "80px",
        }
    }));

    return (
        <div>
            <Box className={styles.boxBorder}>
                <Stack className={styles.principalStack}>
                    <Typography
                        className={styles.fontTitle}>{t("Credit.creditDetails.titleLoanAmount")} {creditDetails?.productName}:</Typography>
                    <Typography className={styles.fontPrice}>{formatter(creditDetails?.loan_amount)}</Typography>
                    <Typography
                        className={styles.fontDate}>{t("Credit.creditDetails.dateRequest")} {getFormattedDate(credit?.creationDate)}</Typography>
                </Stack>
            </Box>

            <Box className={styles.stackContainer} >
                <Grid container rowSpacing={2}>
                    <Grid item xs={9} md={3}>
                        <ItemText><Typography
                            className={styles.fontLabel}>{t("Credit.creditDetails.labelLoanAmount")}</Typography></ItemText>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <ItemValue><Typography className={styles.fontValue}>{formatter(creditDetails?.loan_amount)}</Typography></ItemValue>
                    </Grid>
                    <Grid item xs={9} md={3}>
                        <ItemText><Typography
                            className={styles.fontLabel}>{t("Credit.creditDetails.paymentDeadline")}</Typography></ItemText>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <ItemValue><Typography className={styles.fontValue}>{creditDetails?.dueDate}</Typography></ItemValue>
                    </Grid>
                    <Grid item xs={9} md={3}>
                        <ItemText className={styles.fontLabel}>{t("Credit.creditDetails.period")}</ItemText>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <ItemValue
                            className={styles.fontValue}>{creditDetails?.period}</ItemValue>
                    </Grid>
                    <Grid item xs={9} md={3}>
                        <ItemText className={styles.fontLabel}>{t("Credit.creditDetails.taxRates")}</ItemText>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <ItemValue
                            className={styles.fontValue}>{creditDetails?.taxRate}</ItemValue>
                    </Grid>
                    <Grid item xs={9} md={3}>
                        <ItemText className={styles.fontLabel}>{t("Credit.creditDetails.interestMoratorio")}</ItemText>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <ItemValue
                            className={styles.fontValue}>{creditDetails.interestMoratorio}</ItemValue>
                    </Grid>
                    <Grid item xs={9} md={3}>
                        <ItemText className={styles.fontLabel}>{t("Credit.creditDetails.interestPunitorio")}</ItemText>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <ItemValue
                            className={styles.fontValue}>{creditDetails.interestPunitorio}</ItemValue>
                    </Grid>
                    <Grid item xs={9} md={3}>
                        <ItemText
                            className={styles.fontLabel}>{t("Credit.creditDetails.administrativeExpenses")}</ItemText>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <ItemValue className={styles.fontValue}>{formatter(creditDetails?.administrativeExpenses)}</ItemValue>
                    </Grid>
                    <Grid item xs={9} md={3}>
                        <ItemText
                            className={styles.fontLabel}>{t("Credit.creditDetails.accountWillBeDisbursed")}</ItemText>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <ItemValue className={styles.fontValue}>{creditDetails.accountWillBeDisbursed}</ItemValue>
                    </Grid>
                    {matches ? <><Grid item xs={9} md={3}></Grid>
                        <Grid item xs={3} md={3}></Grid> </> : <> </>}
                    <Grid item xs={9} md={3}>
                        <ItemText className={styles.fontLabel}>{t("Credit.creditDetails.totalPayment")}</ItemText>
                    </Grid>
                    <Grid item xs={3} md={3}>
                        <ItemValue className={styles.fontTotal}>{creditDetails.totalPayment}</ItemValue>
                    </Grid>
                </Grid>
            </Box>
            </div>
    )
}


export default CreditDetailCard;