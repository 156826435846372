import React, {useState, useEffect, Fragment} from "react";
import OnBoardingLayout from "../utils/on-boarding-layout";
import VerifyEmail from "../components/login/verify-email"
import Loading from "./Loading";
import MessageError from "../components/loader/messageError";
import { updateToken, getIntegrationToken } from "../actions/axios-api";
import { useCreditContext } from "../utils/providers/CreditProvider";
import { screen_to_render } from "../utils/redirecter/redirect";
import { useNavigate, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import { useSignIn } from "react-auth-kit";
import { setAccessToken } from "../components/utils/localStorage";
import { useAPIContext } from '../utils/providers/APIProvider';
import { origins } from "../constants/Contract";

export default function Integrations() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setID] = useState("");
  const [origin, setOrigin_] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const signIn = useSignIn();
  const {
    setAuthToken,
    setSession,
    setIsAbbreviate,
    setHasActiveCredit,
    setIsOverLimit,
    setDocumentStatus,
    setFlow,
    setClientId,
    setOrigin,
    setHost
  } = useCreditContext();
  const {
    get_user
  } = useAPIContext();

  useEffect(async () => {
    setLoading(true)
    const query = new URLSearchParams(location.search);
    const exist = query.get("exist");
    const id = query.get("id")
    const origin = query.get("origin")
    setID(id);
    setOrigin_(origin);
    setOrigin(origin);
    setHost(origins.MI_TIENDA)
    if (!id || !origin) {
      setError(true);
      setLoading(false);
      return
    }
    if (exist === "True") {
      await redirectLogin(id, origin);
      setLoading(false);
      return
    }
    setLoading(false);
  }, [])

  const redirectLogin = async (id, origin) => {
    const login = await getIntegrationToken(encodeURIComponent(id))
    if (login?.status !== 200) {
      setError(true);
      setLoading(false);
      return
    }
    
    await setAuthToken(login.data.token);
    const response_user = await get_user({param: `email=${login.data.email}`, token: login.data.token})
    if (response_user.status !== 200) {
      setError(true);
      setLoading(false);
      return
    }
    let screen = screen_to_render(response_user.data)
    setIsAbbreviate(response_user.data?.is_abbreviate === "true")
    setHasActiveCredit(response_user.data?.has_active_credit)
    setIsOverLimit(response_user.data?.is_over_limit)
    setDocumentStatus(response_user.data?.document_status)
    setFlow(response_user.data?.flow)
    setClientId(response_user.data?.client_id)
    setSession({"email": login.data.email, "idpdv": response_user.data["Negocio"]["facturation-information"]["id_pdv"], "is_abbreviate": response_user.data?.is_abbreviate})
    if(screen === "/Contract") {
      navigate(screen, {
        replace: true,
        screen: screen,
        state: {
          data : response_user.data,
          id_pdv: response_user.data["Negocio"]["facturation-information"]["id_pdv"],
          email: login.data.email,
          password: "",
          origin: origin,
        },
      });
      setLoading(false);
      return
    }

    let tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM,
      dataLayer: {
        event: "signIn",
      },
    };
    TagManager.initialize(tagManagerArgs);

    setAccessToken(login.data.token)
    signIn({
      token: login.data.token,
      tokenType: "Bearer",
      expiresIn: 100000,
      authState: {
        IdToken: login.data.token,
      },
      refreshToken: login.data.token,
    });

    navigate(screen, {
      replace: true,
      screen: screen,
      state: {
        data : response_user.data,
        id_pdv: response_user.data["Negocio"]["facturation-information"]["id_pdv"],
        email: login.data.email,
        password: "",
      },
    });

    setLoading(false);
  }

  const saveToken = async (values) => {
    let email = values["email"];
    let request = await updateToken(encodeURIComponent(id), email);
    if (request.status !== 201) {
      setError(true);
    }
  }

  return <Fragment>
      {loading && <Loading></Loading>}
      {error && <MessageError></MessageError>}
      {
        <div style={{ display: (loading || error) ? "none" : "block" }}>
          <OnBoardingLayout screen={<VerifyEmail origin={origin} tokenID={id} handleBehavior={saveToken} showLoginRedirect={false} setLoading={setLoading}/>} newdashboard={true} />
        </div>
      }
    </Fragment>
}
