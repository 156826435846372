import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const themeActiveCredit = makeStyles((theme: Theme) => ({
    dateText: {
        color: '#708195 !important',
        fontWeight: "400 !important",
        display: 'inline !important',
        fontSize: '12px',
        marginTop: '20px',
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    activeText: {
        color: '#66BC6B !important',
        fontWeight: "500 !important",
        display: 'inline !important',
        marginLeft: "-25px !important",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "-50px !important",
        }
    },
    closedText: {
        color: '#2D6DDD !important',
        fontWeight: "500 !important",
        display: 'inline !important',
        marginLeft: "-20px !important",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "-40px !important",
        }
    },
    divInToolTip: {
        marginTop: "3px"
    },
    questionIcon: {
        width: '20% !important',
        marginLeft: "3px !important",
        display: 'inline !important',
        marginBottom: "-7px !important"
    },
    dueText: {
        color: '#D95A3A !important',
        fontWeight: "500 !important",
        display: 'inline !important',

    },
    dueText_: {
        color: '#D95A3A !important',
        fontWeight: "500 !important",
        display: 'inline !important',
        marginLeft: "-15px !important",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "-35px !important",
        }
    },
    resumeContainer: {
        height: "100%",

    },
    divImage: {
        [theme.breakpoints.down("xs")]: {
            marginBottom: '3px',
            minHeight: '70px',
        },
        [theme.breakpoints.up("sm")]: {
            marginBottom: '3px',
            minHeight: '63px',
            display: 'flex',
            maxHeight: '60px'
        }
    },
    boxCard: {
        borderColor: "#EEEEEE",
        [theme.breakpoints.up("sm")]: {
            maxHeight: "70px important",
        },
        borderRadius: "18px",
        border: '2px solid',
        [theme.breakpoints.down("xl")]: {
            maxHeight: "90px !important"
        },
        [theme.breakpoints.down("lg")]: {
            maxHeight: "70px !important"
        },
        [theme.breakpoints.down("sm")]: {
            maxHeight: "70px !important"
        },
        [theme.breakpoints.down("xs")]: {
            maxHeight: "90px !important"
        },
    },
    gridImage: {
        borderRadius: "16px 0 0 16px",
        maxHeight: "56px",

        [theme.breakpoints.up("sm")]: {
            borderRadius: "16px 0 0 16px",
            maxHeight: "63px"
        }
    },
    cardBody: {
        width: '100%'
    },
    imageTypeCredit: {
        width: '80%',
        margin: 'auto',
        display: 'block'

    },
    imageTypeCreditEpin: {
        [theme.breakpoints.up("sm")]: {
            width: '58%',
        },
        width: '65%',
        margin: 'auto',
        display: 'block',
        paddingTop: '12px'
    },
    imageTypeCreditEpins: {
        width: '50%',
        margin: 'auto',
        display: 'block',
        paddingTop: '15px',

        [theme.breakpoints.between("sm", "md")]: {
            paddingTop: '30% !important',
            width: '70% !important',
        },

        [theme.breakpoints.between("md", "lg")]: {
            marginTop: '1%',
            paddingTop: '15%',
            width: '60% !important',


        },
        maxWidth: '80px',
        maxHeight: '40px',
        minWidth: '40px',
        minHeight: '20px',
    },
    imageTypeCreditEmoney: {
        [theme.breakpoints.up("sm")]: {
            paddingTop: '0px',
        },
        width: '70%',
        margin: 'auto',
        display: 'block',
        paddingTop: '20px',
    },
    imageTypeCreditEmoneys: {
        margin: 'auto',
        display: 'block',
        width: '50%',
        [theme.breakpoints.down("xs")]: {
            paddingTop: '15px',
            marginTop: '-5px',
        },
        [theme.breakpoints.up("xl")]: {
            marginTop: '-5%',
            paddingTop: '2%',
            width: '30% !important',
        },
        [theme.breakpoints.only("lg")]: {
            marginTop: '-10% !important',
            paddingTop: '10% !important',
        },
        [theme.breakpoints.between("sm", "md")]: {
            // marginTop: '40%',
            paddingTop: '15%',
            width: '70% !important',
        },
        [theme.breakpoints.only("sm")]: {
            marginTop: '-1% !important',
            paddingTop: '-5% !important',
            width: '60% !important',
        },
        [theme.breakpoints.between("md", "lg")]: {

            width: '60% !important',
        },
        [theme.breakpoints.up("xl")]: {

            width: '50% !important',
        },
        maxWidth: '100px',
        maxHeight: '60px',
        minWidth: '40px',
        minHeight: '20px',
    },
    textCardMoney: {
        [theme.breakpoints.up("lg")]: {
            fontSize: '16px !important',
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: '12px'
        },
        marginLeft: "20px !important",
        fontSize: "16px",
        fontWeight: "600",
        fontFamily: 'Readex Pro',
        marginTop: "-5px !important"
    },
    textCardMoney_: {
        [theme.breakpoints.up("lg")]: {
            fontSize: '16px !important',
            marginLeft: "-40px !important",
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: '12px'
        },
        [theme.breakpoints.only("sm")]: {
            marginLeft: "-50px !important",
        },
        // [theme.breakpoints.down("xs")]: {
        marginLeft: "-25px !important",
        // },
        fontSize: "16px",
        fontWeight: "600",
        fontFamily: 'Readex Pro',
        marginTop: "-5px !important"
    },
    textCardLegend: {

        marginLeft: "20px !important",
        fontSize: "12px",
        color: "#708195",
        lineHeight: '14px',
        fontWeight: "400",
        fontFamily: 'Readex Pro'
    },
    textCardLegend_: {
        [theme.breakpoints.only("sm")]: {
            marginLeft: "-50px !important",
        },
        [theme.breakpoints.up("lg")]: {
            marginLeft: "-50px !important",
        },
        marginLeft: "-25px !important",
        fontSize: "12px",
        color: "#708195",
        lineHeight: '14px',
        fontWeight: "400",
        fontFamily: 'Readex Pro'
    },
    gridTitleAndLink: {
        [theme.breakpoints.up("sm")]: {
            paddingTop: '30px'
        },
    },
    gridTitle: {
        [theme.breakpoints.up("sm")]: {
            fontSize: '24px',
            padding: 0
        },
        fontFamily: 'Readex Pro',
        fontWeight: '600 !important',
        fontSize: '14px',
        color: '#343C46',
        paddingTop: "50px"
    },
    divViewAll: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    textviewAll: {
        [theme.breakpoints.up("sm")]: {
            fontFamily: 'Readex Pro !important',
            color: '#708195',
            padding: 0,
            textDecoration: 'none',
            fontSize: '16px',
            position: 'relative',
            '&::after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                right: 0,
                bottom: -10,
                width: '150px',
                borderBottom: '1px solid #FABF00',
                transformOrigin: 'right',
                transform: 'scaleX(0.5)',
            },
        },
        fontFamily: 'Readex Pro !important',
        fontStyle: 'normal',
        fontWeight: '400 !important',
        fontSize: '14px',
        lineHeight: '14px',
        textDecoration: 'underline',
        color: '#363853',
        textAlign: 'right',
        cursor: 'pointer',
        paddingTop: '35px'
    },
    gridLink: {
        fontFamily: 'Readex Pro !important',
        fontWeight: '400 !important',
        fontSize: '12px !important',
        color: '#708195 !important',
        textAlign: "end",
        textDecorationColor: "#FABF00 !important"
    },
    gridMessage: {
        textAlign: 'center'
    },
    imageMessage: {
        [theme.breakpoints.up("sm")]: {
            width: '70%',
        },
        width: '100%',
        padding: '30px 0px 10px 0px',
        opacity: 0.6
    },
    imageMessage_: {
        [theme.breakpoints.up("sm")]: {
            width: '40%',
        },
        width: '100%',
        padding: '30px 0px 10px 0px',
        opacity: 0.6
    },
    textMessage: {
        textAlign: 'center',
        paddingBottom: '30px',
        fontSize: '12px',
        fontWeight: '400',
        color: "#363853",
        lineHeight: '18px',
        letterSpacing: '0.25px'
    },
    gridLoan: {
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    gridButton: {

    },
    invoiceCard: {
        [theme.breakpoints.down("xs")]: {
            marginLeft: "20px"
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 10px',
        gap: '8px',
        minWidth: '260px',
        height: '56px',
        background: '#FFFFFF',
        boxShadow: '0px 4px 12px rgba(194, 209, 217, 0.46)',
        borderRadius: '16px',
        flex: 'none',
        order: 0,
        alignSelf: 'stretch',
        flexGrow: 0,
    },
    boxCardInvoice: {
        borderColor: "white",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            maxHeight: "60px important",
        },
        borderRadius: "18px",
        border: '2px solid',
        [theme.breakpoints.down("xl")]: {
            maxHeight: "80px !important"
        },
        [theme.breakpoints.down("lg")]: {
            maxHeight: "60px !important"
        },
        [theme.breakpoints.down("sm")]: {
            maxHeight: "60px !important"
        },
        [theme.breakpoints.down("xs")]: {
            maxHeight: "80px !important"
        },
    }

}));

export default themeActiveCredit;
