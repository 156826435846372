import { useTranslation } from "react-i18next";
import React from "react";
import loadError from "../../assets/loader/loaderError.svg"
import { useNavigate } from "react-router-dom";
import OnBoardingLayout from "../../utils/on-boarding-layout";
import Notification from "../../views/Notification";

export default function MessageError({path, redirect}) {
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const goHome = async () => {
        let _ = window.location.href.split('/')[0];
        if(!redirect || redirect == undefined){
            path ? navigate(`/${path}`) : navigate("/Credit")
        }else{
            window.location.href = `${_}/SignIn`
        }
        
    };


    return (
        <OnBoardingLayout screen={<Notification image={loadError} title={t("Notifications.Error.Message")}
            isError={true} button={t("Notifications.Error.Button")}
            goHome={goHome} />}
            newdashboard={false} fullcard={false} smallcard={false} cardNotification={true} error={true}/>
    );
}