import React, {useEffect, useState, Fragment} from "react";
import HomeCredit from "../components/credit/homeCredit";
import {useCreditContext} from "../utils/providers/CreditProvider";
import UserInfoHeader from "../components/credit/userInfoHeader";
import {Stack} from "@mui/material";
import {ThemeBanner} from "../utils/themes/ThemeBanner";
import Loading from "./Loading"
import MessageError from "../components/loader/messageError";
import {approvalType} from "../constants/WowMoment";
import {useAPIContext} from "../utils/providers/APIProvider";
import {useSideMenuContext} from "../utils/providers/SideMenuProvider";

export default function Credit() {
    const {
        setNavigateConfig,
        setClient,
        clientId,
        session,
        setPaymentType,
        setPersistStatesContext,
        userInfo,
        setCreditInfo,
        setLoansInfo,
        creditInfo,
        reloadInfo,
        setHandleBack,
        reloadDocument,
        authToken,
        setUserInfo
    } = useCreditContext();

    const {get_credit_loan} = useAPIContext();
    const [data, setData] = useState("")
    const bannerStyles = ThemeBanner();
    const [error, seterror] = useState(false)
    const [loading, setloading] = useState(false)
    const {openSideMenu} = useSideMenuContext()
    const [dataReady, setDataReady] = useState(false)
    const {
        get_user
    } = useAPIContext();
    let response;
    const validateCredit = async () => {
        const response = await get_credit_loan({params:`client_id=${clientId}&limit=${1000}`});
        if (response.status === 400) {
            setloading(false)
            seterror(true)
        } else {
            setPaymentType(approvalType.available)
            setCreditInfo(response.data.credit_details)
            setLoansInfo(response.data.loans_info)
            setNavigateConfig({})
        }
    }

    useEffect( async () => {
        window.analytics.page('PAGEVIEW_HOME', {
            page: 'WC_Home',
            journey: 'WC_Loans',
            product_group: 'Lending',
            product_name: 'Working_Capital',
        });
        setloading(true)
        if(reloadInfo){
            setHandleBack(false)
            await validateCredit();
        }
        if(reloadDocument){
            response = await get_user({param: `email=${userInfo.email}`, token: authToken})
            setUserInfo(response.data)
        }
        setPersistStatesContext(true)
        setDataReady(true)
    }, []);

    useEffect( () => {
        if(creditInfo && dataReady){
            setData({
                "client_id":creditInfo.client_id,
                "first_name":userInfo.Negocio["owner-information"]["name"],
                "last_name":userInfo.Negocio["owner-information"]["lastname"],
                "arrears":creditInfo.arrears,
                "currency":creditInfo.currency,
                "limit_out":userInfo.limit_out,
                "total_balance": creditInfo.total_balance,
                "credit_amount": creditInfo.credit_amount,
                "consumed_credit":creditInfo.consumed_credit,
                "available_credit":creditInfo.available_credit,
                "credit_arrangement_key":creditInfo.credit_arrangement_key,
                "email":userInfo.email,
                "is_abbreviate":userInfo.is_abbreviate,
                "is_pdv":creditInfo.is_pdv,
                "encodedKey":userInfo.encoded_key
            })

            setClient({
                "total_balance": creditInfo.total_balance,
                "credit_amount": creditInfo.credit_amount,
                "consumed_credit":creditInfo.consumed_credit,
                "available_credit":creditInfo.available_credit,
                "encodedKey":userInfo.encoded_key,
                "client_id":creditInfo.client_id,
                "first_name":userInfo.Negocio["owner-information"]["name"],
                "last_name":userInfo.Negocio["owner-information"]["lastname"],
                "email":userInfo.email,
                "arrears":creditInfo.arrears,
                "currency":creditInfo.currency,
                "credit_arrangement_key":creditInfo.credit_arrangement_key,
                "is_abbreviate":userInfo.is_abbreviate,
                "limit_out":userInfo.limit_out,
                "is_pdv":creditInfo.is_pdv
            })
        }
    },[creditInfo,dataReady])

    return (
        <Fragment>
            {loading && <Loading></Loading>}
            {error && <MessageError path={'SignIn'}></MessageError>}
            {<div style={{display: (loading || error) ? "none" : "block"}}>
                {data && (
                    <Stack spacing={5} className={bannerStyles.bannerCredit}>
                        <UserInfoHeader onMenuItemsClick={openSideMenu} userdata={data} visibilityContent={true}/>
                        <HomeCredit props={data} validateError={seterror} loading={setloading} client_id={clientId}
                                    email={session.email}/>
                    </Stack>
                )}
            </div>
            }
        </Fragment>)
}
