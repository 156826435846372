import React from "react";
import {
    Grid
  } from "@mui/material";
  import themeAlert from "../../utils/themes/ThemeAlert";

let skin = {
    green: "#b4edb8",
    red: "#e0b4b4"
}

const CustomAlert = ({text, Icon, color, customColor}) => {
    let background = skin[color]; 
    const styles = themeAlert();

    return <Grid container className={styles.simpleAlert}>
        <Grid backgroundColor={background} className={styles.bodyAlert}>
           
            <table className={styles.tableAlert}>
                <tr>
                    <td className={styles.iconAlert}><Icon style={{ color: customColor ? customColor : color }} /></td>
                    <td className={styles.textAlert}>{text}</td>
                </tr>
            </table>
        </Grid>
    </Grid>
}

export default CustomAlert;