import PropTypes from 'prop-types';
import React, { Fragment } from "react";

import Button from "../../../inputs/button";


const Welcome = ({ onSubmitScreen }) => {

  const buttonConfigNext = {
    btn: {
      type: 'button',
      color: 'primary',
      name: 'next',
      "styles": {
        "height": "48px"
      },
      "styleClassName": "action-button",
      label: "Continuar",
      testid: "next-66"
    },
    btnNext: () => onSubmitScreen("next")
  }
  const buttonConfigBack = {
    btn: {
      type: 'button',
      color: 'primary',
      name: 'next',
      "styles": {
        "height": "48px"
      },
      "styleClassName": "action-button",
      label: "Regresar",
      testid: "back-66"
    },
    btnNext: () => onSubmitScreen("back")
  }

  const renderFields = (fields) => {
    return (
      <Fragment>
        <div>Ventana de bienvenida</div>
        <div>step 2</div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {renderFields()}
      <Button {...buttonConfigNext} />
      <Button {...buttonConfigBack} />
    </Fragment>
  )
}

Welcome.propTypes = {
  onSubmitScreen: PropTypes.any.isRequired
};

export default Welcome;