import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

const themeProduct = makeStyles((theme: Theme) => ({
    cardProduct: {
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
            padding: '0px 20px 0px 20px',
            zIndex: 0,
            position: "absolute",
            top: "65px",
            right: "0px",
            left: "0px"
        },
        [theme.breakpoints.up("sm")]: {
            justifyContent: "center",
            padding: '0px 80px 0px 80px',
            zIndex: 0,
            position: "absolute",
            top: "90px !important",
            right: "0px",
            left: "0px",


        }
    },
    titleProduct: {
        [theme.breakpoints.down("xs")]: {
            marginLeft: "23px !important",
            fontFamily: 'Readex Pro !important',
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "20px",
            letterSpacing: "0.247px",
            color: "#4C5866",
            paddingBottom: "16px"
        },
        [theme.breakpoints.up("sm")]: {
            marginLeft: "24px !important",
            fontFamily: 'Readex Pro !important',
            fontWeight: "600 !important",
            fontSize: "24px !important",
            lineHeight: "20px",
            letterSpacing: "0.247px",
            color: "black",
            paddingBottom: "16px",
            display: 'inline-block',
            whiteSpace: 'nowrap',
            marginBottom: '5px'
        }
    },
    CardsDetails: {

        background: "#FFFFFF",
        [theme.breakpoints.down("xs")]: {
            paddingBottom: '8px',
            marginTop: '15px',

            // border: "1px 1px 0px 0px solid #F3F3F3 !important",
            boxShadow: "2px 2px 0px 0px white !important",
            borderRadius: "21px 21px 0px 0px !important",
        },
        [theme.breakpoints.up("sm")]: {
            maxWidth: "100%",
            padding: '60px 20px 50px 20px',
            border: "1px solid #F3F3F3 !important",
            boxShadow: "2px 4px 4px rgba(185, 185, 185, 0.25) !important",
            borderRadius: "21px !important",
        }
    },
    CardsProducts: {
        border: "1px solid #F3F3F3 !important",
        boxShadow: "2px 4px 4px rgba(185, 185, 185, 0.25) !important",
        borderRadius: "21px !important",
        background: "#FFFFFF",
        maxWidth: '1270px !important',
        margin: 'auto',
        [theme.breakpoints.down("xs")]: {
            paddingBottom: '8px',
            marginTop: '15px'
        },
        [theme.breakpoints.up("sm")]: {
            maxWidth: "100%",
            padding: '60px 20px 50px 20px'
        }
    },
    secondCard: {
        maxWidth: '400px',
        [theme.breakpoints.down("xs")]: {
            padding: '250px 20px 0px 20px', position: "static"
        },
        [theme.breakpoints.up("sm")]: {
            display: 'none'
        }
    },
    principalCard: {
        [theme.breakpoints.down("xs")]: {

        },
        [theme.breakpoints.up("sm")]: {
            padding: '0 10% 0 10%',
        }
    }
}));
export default themeProduct;