import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const Label = (props) => {
  const [t] = useTranslation("global");

  return <Typography style={props.props.style}>{t(props.props.message)}</Typography>;

};

export default Label;
