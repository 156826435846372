import React, { Fragment, useState, useEffect } from "react";
import Login from "../components/login/login";
import OnBoardingLayout from "../utils/on-boarding-layout";
import Loading from "./Loading";
import MessageError from "../components/loader/messageError";
import { useCreditContext } from "../utils/providers/CreditProvider";
import { origins } from "../constants/Contract";

export default function SignIn() {
  const { navigateConfig, setHost } = useCreditContext();
  const [error, seterror] = useState(false)
  const [loading, setloading] = useState(false)

  useEffect(() => {
    setHost(origins.DIGITAL_V2)
    if ( navigateConfig?.previousScreen === "SignUp")  {
      window.analytics.track("SIGNUP_REDIRECT",{
        page: "WC_Signup",
        journey: "WC_Onboarding",
        button_name: "Inicia Sesión",
        product_group: "Lending",
        product_name: "Working_Capital"
      });
    }
  }, [])
  

  return (
    <Fragment>
      {loading && <Loading></Loading>}
      {error && <MessageError></MessageError>}
      {<div style={{ display: (loading || error) ? "none" : "block" }}>
        <OnBoardingLayout screen={<Login validateError={seterror} loading={setloading} />} newdashboard={true} />
      </div>
      }
    </Fragment>)
}
