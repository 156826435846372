import React from 'react'
import themeNotification from "../utils/themes/ThemeNotification";
import {
    Typography,
    Grid,
  } from "@mui/material";
import Button from "../components/inputs/button";
import { primaryButtonStyles, setButtonLabel } from "../utils/themes/ThemeButtons";

export default function Notification({image, animation, title, message, isAnimation=false, isError=false, button, goHome}) {
    let styles = themeNotification()

    const imageClass = () => {
        if(isAnimation) {
            return styles.animatedImage
        }
        if(isError){
            return styles.imageError
        }
        return styles.staticImage
    }

    const titleClass = () => {
        if(isError){
            return styles.titleContainerError
        }
        return styles.titleContainer
    }

    const buttonClass = () => {
        if(isError){
            return styles.buttonError
        }
        return styles.button
    }
    
    return(
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
        >
            <Grid item xs={12} className={imageClass()}>
                {animation}
                <img
                  src={image}
                  alt="notification"
                />
            </Grid>
            <Grid item xs={12} className={titleClass()}>
                <Typography>{title}</Typography>
            </Grid>
            <Grid item xs={12} className={styles.messageContainer}>
                <Typography className={styles.message}>{message}</Typography>
            </Grid>
            {button ?
                <Grid item xs={12} className={buttonClass()}>
                    <Button
                        {...setButtonLabel(primaryButtonStyles, button)}
                        btnNext={goHome}
                    />
                </Grid> : <></>}
        </Grid>
    );
}
