import { useEffect, useState, Fragment } from "react";
import dataJson from "../config/signIn.json";
import GenericForm from "../genericForm";
import { schema, getYup } from "../../utils/yup";
import { useNavigate, useLocation } from "react-router-dom";
import { login } from "../../actions/axios-api";
import { getMtsTokenId } from "../../utils/jwt";
import { screen_to_render } from "../../utils/redirecter/redirect";
import TagManager from "react-gtm-module";
import { useSignIn } from "react-auth-kit";
import { setRefreshToken, setAccessToken } from "../utils/localStorage";
import { useCreditContext } from "../../utils/providers/CreditProvider";
import { useAPIContext } from '../../utils/providers/APIProvider';

const Login = ({validateError, loading}) => {
  const {
    setAuthToken,
    setSession,
    setRedirectOut,
    setIsAbbreviate,
    setHasActiveCredit,
    setIsOverLimit,
    setDocumentStatus,
    setFlow,
    setClientId,
    setOrigin,
    createSession,
    setEpinNumber,
    setEmoneyNumber,
    setUserInfo
  } = useCreditContext();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const location = useLocation();
  const [validates, setValidates] = useState({});
  const [loginError, setLoginError] = useState({});
  const [disabledButton, setDisabledButton] = useState(false);
  const {
    get_user
  } = useAPIContext();

  useEffect(() => {
    window.analytics.page('PAGEVIEW_LOGIN', {
      page: 'WC_Login',
      journey: 'WC_Onboarding',
      product_group: 'Lending',
      product_name: 'Working_Capital',
    });
    localStorage.setItem(
        "from",
        location.state?.from?.pathname ? location.state?.from?.pathname : "/"
    );
    let _validates = {};
    dataJson.forms.fields.forEach(({ props, validations }) => {
      _validates[props.name] = schema(validations, props.type);
    });
    setValidates(_validates);
  }, []);

  const handleSubmit = async (values) => {
    loading(true);
    window.analytics.track('LOGIN_STARTED', {
      page: 'WC_Login',
      journey: 'WC_Onboarding',
      product_group: 'Lending',
      button_name: 'Conectarse',
      product_name: 'Working_Capital',
    });
    const response = await login({
      user: values.email,
      password: values.password,
      device_type: "EMAIL",
      authentication_type:"PASSWORD"
    });
    if (response?.status !== 200) {
      setLoginError({
        "err": true,
        "name": "El usuario o la contraseña son incorrectos",
        "type": "password"
      })
      setDisabledButton(true)
      loading(false);
      setRedirectOut(true); 
      return
    }

    await setAuthToken(response.data.access_token);
    const response_user = await get_user({param: `email=${values.email}`, token: response.data.access_token})
    if (response_user.status !== 200) {
      setLoginError({
        "err": true,
        "name": "El usuario o la contraseña son incorrectos",
        "type": "password"
      })
      setDisabledButton(true)
      loading(false);
      return
    }

    let screen = screen_to_render(response_user.data)
    setIsAbbreviate(response_user.data?.is_abbreviate === "true")
    setHasActiveCredit(response_user.data?.has_active_credit)
    setIsOverLimit(response_user.data?.is_over_limit)
    setDocumentStatus(response_user.data?.document_status)
    setFlow(response_user.data?.flow)
    setOrigin(response_user.data?.origin)
    setEpinNumber(response_user.data['Negocio']['contact-information']['epin_cell_number'])
    setEmoneyNumber(response_user.data['Negocio']['contact-information']['emoney_cell_number'])
    setUserInfo(response_user.data)
    await createSession();
    setSession({"email": values.email, "idpdv": response_user.data["Negocio"]["facturation-information"]["id_pdv"], "is_abbreviate": response_user.data?.is_abbreviate})
    if(screen === "/Contract") {
      navigate(screen, {
        replace: true,
        screen: screen,
        state: {
          data : response_user.data,
          id_pdv: response_user.data["Negocio"]["facturation-information"]["id_pdv"],
          email: values.email,
          password: values.password
        },
      });
      loading(false);
      return
    }
    let tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM,
      dataLayer: {
        event: "signIn",
      },
    };
    TagManager.initialize(tagManagerArgs);
    let mtsToken = getMtsTokenId(response.data.access_token);
    setRefreshToken(response.data.refresh_token)
    setAccessToken(response.data.access_token)
    setClientId(response_user.data.client_id)
    setRedirectOut(false);
    window.analytics.identify(response_user.data?.uniqueId,{
      name: response_user.data['Negocio']['owner-information']['name'] + " " + response_user.data['Negocio']["owner-information"]["lastname"],
      email: response_user.data['Negocio']['contact-information']['email'],
      idpdv: response_user.data['Negocio']['facturation-information']['id_pdv'],
      userId: response_user.data?.uniqueId})

    signIn({
      token: response.data.access_token,
      tokenType: "Bearer",
      expiresIn: mtsToken.expires_in,
      authState: {
        IdToken: mtsToken,
      },
      refreshToken: response.data.refresh_token,
    });
    navigate(screen, {
      replace: true,
      screen: screen,
      state: {
        data : response_user.data,
        id_pdv: response_user.data["Negocio"]["facturation-information"]["id_pdv"],
        email: values.email,
        password: values.password
      },
    });


    loading(false);
  };

  return (
      <Fragment>
        <GenericForm
            form={dataJson.forms}
            initialValue={{}}
            validates={getYup(validates)}
            onSubmit={handleSubmit}
            loginError={loginError}
            setLoginError={setLoginError}
            disabledButton={disabledButton}
            setDisabledButton={setDisabledButton}
        />
      </Fragment>
  );
};

export default Login;