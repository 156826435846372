import CryptoJS from "crypto-js";

const key = process.env.REACT_APP_SESSION;

const encode = (element) => {
    const encrypted = CryptoJS.AES.encrypt(window.btoa(element), window.atob(key));
    return encrypted.toString();
}

const decode = (encrypted) => {
    let decrypted = '{}'
    if(encrypted){
        let preDecrypted= CryptoJS.AES.decrypt(encrypted, window.atob(key)).toString(CryptoJS.enc.Utf8);
        decrypted = window.atob(preDecrypted)
    }
    return decrypted;
}

export {
    encode,
    decode
}
