import React from 'react'
import { Grid } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PropTypes from 'prop-types';


export default function ButtonCreditList({
    back,
    next,
    handleBack,
    handleNext
}) {


    return (<Grid container xs={2} sm={1} justifyContent={'space-between'}>
        <Grid item xs={1} onClick={handleBack} data-testid={'navigate_back'}>
            <ArrowForwardIosIcon style={{ transform: "scaleX(-1)", fontSize: "1rem", color: back ? 'black' : 'gray' }} />
        </Grid>
        <Grid item xs={1} onClick={handleNext} data-testid={'navigate_next'}>
            <ArrowForwardIosIcon style={{ fontSize: "1rem", color: next ? 'black' : 'gray' }} />
        </Grid>
    </Grid>)
}
ButtonCreditList.propTypes = {
    next: PropTypes.bool.isRequired,
    back: PropTypes.bool.isRequired,
    handleNext: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
};