import { validate } from "uuid";


const selectScreen = async (userInfo, navigate) => {
    const documents = userInfo.Negocio?.["documents"] ? userInfo.Negocio?.["documents"] : false
    let rejected, pending, validate = false;
    let screen = '/Remedy';

    if (documents) {
        for (const document of Object.values(documents)) {
            if (document.status === 'rejected') {
                rejected = true
            } else if (document.status === 'pending') {
                pending = true
            }else if(document.status === 'Validated'){
                validate = true
            }
        }
        if (rejected && !pending) {
            screen = '/Remedy'
        } else if (pending) {
            screen = '/PendingDocuments'
        } else if(validate){
           screen = "/MessageScreen"
        }
        else {
            return navigate("ScreenMessage.Contract.")
        }
    }
    return screen
}

export {
    selectScreen
}
