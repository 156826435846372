export const primaryButtonStyles = {
    btn: {
        type: 'button',
        color: 'primary',
        name: 'next',
        "styleClassName": "action-button",
        label: '',
    }
};

export const secondaryButtonStyles = {
    btn: {
        type: 'button',
        color: 'primary',
        name: 'next',
        "styleClassName": "action-button-outlined",
        label: '',
    },
};

export const setButtonLabel = (secondaryButtonStyles, buttonLabel, disabled = false) => {
    return {
        btn: {
            ...secondaryButtonStyles.btn,
            label: buttonLabel,
            disabled: disabled
        }
    }
};