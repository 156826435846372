import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

export const ThemeBanner = makeStyles((theme: Theme) => ({
    bannerOne_web: {
        height: '200px',
        background: 'linear-gradient(180deg, #FABF00 33.4%, #FFD445 61.66%, #FFDC6A 90.17%), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FABE00',

    },
    bannerOne: {
        height: '270px',
        background: 'linear-gradient(180deg, rgba(255,216,88,1) 22%, rgba(250,190,0,1) 100%)',

    },
    bannerOneWowPayment: {
        height: '185px',
        background: 'linear-gradient(180deg, #FABF00 33.4%, #FFD445 61.66%, #FFDC6A 90.17%), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FABE00',

    },
    bannerTwo: {
        background: 'linear-gradient(180deg, rgba(255,216,88,1) 22%, rgba(250,190,0,1) 100%)'
    },
    bannerThree: {
        height: '175px',
        background: 'linear-gradient(180deg, rgba(255,216,88,1) 22%, rgba(250,190,0,1) 100%)',
        borderBottomLeftRadius: '30px',
        borderBottomRightRadius: '30px'
    },
    bannerCredit: {
        height: '185px',
        background: 'linear-gradient(180deg, #FABF00 33.4%, #FFD445 61.66%, #FFDC6A 90.17%), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FABE00',
        alignItems: 'center'
    },
    bannerWowMomentTotal: {
        [theme.breakpoints.up("sm")]: {
            height: '40px',
        },
        [theme.breakpoints.down("sm")]: {
            height: '20px',
            borderBottomLeftRadius: '30px',
            borderBottomRightRadius: '30px',
        },
        background: 'linear-gradient(180deg, rgba(255,216,88,1) 22%, rgba(250,190,0,1) 100%)',
        paddingTop: "110px"
    },
    bannerWowMomentRequired: {
        height: '115px',
        background: 'linear-gradient(180deg, rgba(255,216,88,1) 22%, rgba(250,190,0,1) 100%)',
        borderBottomLeftRadius: '30px',
        borderBottomRightRadius: '30px',

        [theme.breakpoints.down("sm")]: {
            height: '185px',
            background: 'linear-gradient(180deg, #FABF00 33.4%, #FFD445 61.66%, #FFDC6A 90.17%), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FABE00',
        }
    },
    bannerWowMomentOptional: {

        [theme.breakpoints.down("xs")]: {
            height: '100px !important',
            background: 'linear-gradient(180deg, #FABF00 33.4%, #FFD445 61.66%, #FFDC6A 90.17%), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FABE00',
            zIndex: '2 !important'
        },
        [theme.breakpoints.up("sm")]: {
            height: '195px',
            background: 'linear-gradient(180deg, #FABF00 33.4%, #FFD445 61.66%, #FFDC6A 90.17%), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FABE00',
            alignItems: 'center',
        }
    },
    bannerWowMomentCard: {

        [theme.breakpoints.down("xs")]: {
            height: '50px !important',
            background: 'white',
            borderRadius: '20px 20px 0px 0px !important',
            position: 'relative',
            top: '80px',
            zIndex: '1 !important'
        },
       
    },
    bannerPayment: {
        height: '185px',
        background: 'linear-gradient(180deg, #FABF00 0%, #FFD445 49.77%, #FFDC6A 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #FABE00',
        alignItems: 'center'
    },
    bannerDetailsPayment: {
        height: '190px',
        background: '#FFD858',
        [theme.breakpoints.up("sm")]: {
            height: "224px",
            paddingTop: "10px",
            background: "linear-gradient(180deg, rgba(255, 216, 88, 1) 22%, rgba(250, 190, 0, 1) 100%)",
        },
    },
    allCreditBanner: {
        [theme.breakpoints.down("xs")]: {
            background: 'linear-gradient(180deg, rgba(255,216,88,1) 22%, rgba(250,190,0,1) 100%)'

        },
        [theme.breakpoints.up("sm")]: {
            height: '185px',
            background: 'linear-gradient(180deg, #FABF00 33.4%, #FFD445 61.66%, #FFDC6A 90.17%), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FABE00',
        },
    },
    addIcon: {
        width: '20% !important',
        marginLeft: "3px !important",
        display: 'inline !important',
        marginBottom: "-7px !important"
    },
    sideMenuBanner: {
        height: '100vh',
        background: 'linear-gradient(180deg, rgba(255,216,88,1) 22%, rgba(250,190,0,1) 100%)'
    }
}));