import React, { useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import themeConfigureCredit from "../../../utils/themes/ThemeConfigureCredit";
import SliderAmount from "./slider-amount";
import { useTranslation } from "react-i18next";
import countryCode from '../../../utils/translations/countryCode.json';
import { useNavigate } from "react-router-dom";
import Button from '../../../components/inputs/button';
import currencyCountry from '../../../utils/translations/currencyCountry.json';
import currency from '../../../utils/translations/currency.json';
import {
   resetNumber,
   calculateDueDate,
   inputValidates,
   filterProductId
} from "../../../utils/getCardInfo";
import "./slicebar.css";
import { calculate_total_pay } from "../../../utils/getProductInfo";
import { formatNumber } from "../../../utils/formatNumber";
import { useCreditContext } from "../../../utils/providers/CreditProvider";
import formatter from "../../../utils/translations/formatter";
import ConfigureCreditMask from "./config-credit-mask";
import { useAPIContext } from "../../../utils/providers/APIProvider";
import PropTypes from 'prop-types';

const SliderAmountComponent= ({client, sliderValue, inputValue, handleOnChange, maxValue, minValue, hadleSliderOnChange, max, min, onBlurInput, onFocusInput, step}) =>{
   return  <SliderAmount
   client={client}
   sliderValue={sliderValue}
   inputValue={inputValue}
   inputOnChange={handleOnChange}
   maxValue={maxValue}
   minValue={minValue}
   sliderOnChange={hadleSliderOnChange}
   unit={currency[currencyCountry[client?.currency]]}
   divider={client ? client['available_credit'] : 80000 / 10}
   max={max}
   min={min}
   currency={countryCode[client?.currency]}
   onBlurInput={onBlurInput}
   onFocusInput={onFocusInput}
>
   <div style={{
      padding: '10px 15px 10px 15px'
   }}>
      <input
         id={'slider-amount-slider'}
         data-testid={'slider-amount-slider'}
         type="range"
         step={step}
         min={min}
         max={max}
         onChange={hadleSliderOnChange}
         value={sliderValue}
         className="slice-bar"
      />
   </div>
</SliderAmount>
} 

const ScheduleCardComponent= (props) => {
   const { product, scheduleOnSelect } = props || {};
   const timePeriods = product?.subProducts;
   const selectedPeriod = timePeriods?.find((period) => period?.id === scheduleOnSelect);
   const isOneWeekPeriod = selectedPeriod?.repaymentPeriodCount === 3 && selectedPeriod?.repaymentPeriodUnit === "DAYS";
   const [t] = useTranslation("global");
   const styles = themeConfigureCredit();

   return (
       <>
          {isOneWeekPeriod && (
              <Stack sx={{ width: '100%'}} direction="row" justifyContent="center">
                 <Typography className={styles.scheduleTextGrid} sx={{ padding: { sm: 0 } }}>
                     {t('ConfigureCredit.phrase')} <b>{t('ConfigureCredit.phraseDaysWeek')}</b>
                 </Typography>
              </Stack>
          )}
       </>
   );
}

const ButtonComponent=(buttonConfig)=>{
   return <Button {...buttonConfig} />
}
const ResumenComponent =({date, productName, totalAmount})=>{
   const styles = themeConfigureCredit();
   const [t] = useTranslation("global");
   return  <Grid container className={styles.secondCard}>
   <Grid item xs={12} className={styles.titleCard} >{t('ConfigureCredit.resume')}</Grid>
   <Grid container>
      <Grid item xs={6} className={styles.dueDate}>{t('ConfigureCredit.dueDate')}</Grid>
      <Grid item xs={6} className={styles.dueDateValue} >{date}</Grid>
   </Grid>
   <Grid container>
      <Grid item xs={6} className={styles.dueDate} >{'Tipo'}</Grid>
      <Grid item xs={6} className={styles.dueDateValue} >{productName}</Grid>
   </Grid>
   <Grid container>
      <Grid item xs={6} className={styles.totalAmount} >{t('ConfigureCredit.dueTotalDate')}</Grid>
      <Grid item xs={6} className={styles.totalAmountValue} >{totalAmount}</Grid>
   </Grid>
</Grid>
}
const ConfigureCreditCard = ({ validateError, loading, matches, title, buttonBlocked,  lowerLimit
 }) => {
   const {
      client,
      product,
      pendingApprovals,
      setCredit,
      setPendingApprovals,
      setLoanAmount,
      setSubProduct,
      setDueDate,
      setDisabledCredit,
      productName
   } = useCreditContext();
   const {create_credit_pending, get_available_credit} = useAPIContext(); 

   const [t] = useTranslation("global");
   const [sliderValue, setSliderValue] = useState();
   const [inputValue, setInputValue] = useState();
   const [maxValue, setMaxValue] = useState("100.000");
   const [minValue, setMinValue] = useState("100.000");
   const [scheduleOnSelect, setScheduleOnSelect] = useState("");
   const [productOnSelect, setProductOnSelect] = useState({});
   const [totalAmount, setTotalAmount] = useState('0.0');
   const [date, setDate] = useState('00/0/0000');
   const [max, setMax] = useState();
   const [min, setMin] = useState();
   const [step, setStep] = useState();
   const [isOnFocus, setIsOnFocus] = useState();
   const navigate = useNavigate();

   const totalAmountAct = (amount) => {
      setTotalAmount(formatter(calculate_total_pay(amount, productOnSelect)))
   }
   const handleOnChange = (e) => {
      let value = e.target.value;
      if (isOnFocus) {
         if (value === '') {
            value = 0
         }
      }

      value = resetNumber(value, '.');
      let _value = formatNum(value);
      changeInput(_value, 'handleOnChange');
      moveInput(value);

   }
   const hadleSliderOnChange = (event) => {
      let value = event.target.value;
      moveSlide(value)
      setSliderValue(value);
      totalAmountAct(value);
   }
   const moveSlide = (newValue) => {
      let _value = formatNum(newValue);
      changeInput(_value, 'moveSlide');
   }
   const moveInput = (value) => {
      value = parseInt(value);
      setSliderValue(inputValidates(value, false, min, max, client['currency']));
   }
   const formatNum = (amount) => {
      return formatNumber(countryCode[client ? client['currency'] : ""], amount);
   }
   const onBlurInput = (e) => {
      setIsOnFocus(false);
      let value = e.target.value;
      value = resetNumber(value, '.');
      let _value = formatNum(value);
      changeInput(_value, 'onBlurInput');
   }
   const onFocusInput = (e) => {
      setIsOnFocus(true);
      let value = e.target.value;
      let _value = resetNumber(value, '.');
      changeInput(_value, 'onFocusInput');
   }
   let buttonConfig = {
      btn: {
         disabled: buttonBlocked,
         type: 'button',
         color: 'primary',
         name: 'next',
         "styles": {
            "height": "48px"
         },
         "styleClassName": "action-button",
         label: t("ConfigureCredit.continue"),
      },
      btnNext: () => createTransaction(),


   }
   const changeInput = (value, origin) => {
      if (origin === 'onBlurInput' || origin === 'moveSlide') {
         value = inputValidates(value, true, min, max, client['currency']);
         window.analytics.track("LOAN_VALUE",{
            page: "WC_Request_Config",
            journey: "WC_Loans",
            value: resetNumber(value, '.'),
            product_group: "Lending",
            product_name: "Working_Capital"
         });
      }
      setInputValue(value);
      setSliderValue(resetNumber(value, '.'));
      totalAmountAct(resetNumber(value, '.'));
   }
   const slice = Math.round(((client ? client['available_credit'] : 80000) * 80) / 100)
   const initialValues = {
      'slice': slice < lowerLimit ? lowerLimit : slice,
      'input': formatNum(slice < lowerLimit ? lowerLimit : slice),
      'step': ((client ? client['available_credit'] : 80000) - 100000) / 10
   }
   const addPendingApprovals = (credit) => {

      let _credits = pendingApprovals;
      let new_credit = {
         "productId": productOnSelect['encodedKey'],
         "loanId": credit['encodedKey']
      }
      if (filterProductId(_credits, productOnSelect['encodedKey'])) {
         _credits.push(new_credit);
      }

      return _credits;
   }
   const redirectToNext = (res) => {
      if (res === 201 || res === 204) {
         navigate("/CreditDetails");
      }
   }

   const createTransaction = () => {
      window.analytics.track("REQUEST_CONTINUE",{
         page: "WC_Request_Config",
         journey: "WC_Loans",
         button_name: "Continuar",
         product_group: "Lending",
         product_name: "Working_Capital"
      });
      window.analytics.track("REQUEST_PRODUCT_1WEEK",{
         page: "WC_Request_Config",
         journey: "WC_Loans",
         button_name: "1 semana",
         product_group: "Lending",
         product_name: "Working_Capital"
      });
      if (!buttonBlocked) {
         loading(true)
         let body = {
            encodedKey: client['encodedKey'],
            products: pendingApprovals,
            report_name: product['descriptionProduct'],
            loanAmount: sliderValue,
            product: productOnSelect
         }

         create_credit_pending({params:body}).then((response => {
            setCredit(response.data.data);
            setPendingApprovals(addPendingApprovals(response.data.data))
            redirectToNext(response.status);
            loading(false)
         })).catch((error) => {
            loading(false)
            validateError(true)
         })
         setLoanAmount(inputValue);
         setSubProduct(productOnSelect);
         setDueDate(date);
         setDisabledCredit((client['available_credit'] - Number(sliderValue)) < lowerLimit)
      }
   }

   const validate_credit = async () => {
      loading(true)
      const response_mts = await get_available_credit({params:`client_id=${client.client_id}`})
      if (response_mts.status === 200) {
         loading(false)
         setMax(response_mts.data.available_credit);
      } else {
         validateError(true)
      }
   }

   useEffect(async () => {
      window.analytics.page('PAGEVIEW_REQUEST_CONFIG', {
         page: 'WC_Request_Config',
         journey: 'WC_Loans',
         product_group: 'Lending',
         product_name: 'Working_Capital',
      });
      if (!client) {
         return navigate("/Credit");
      } else {
         await validate_credit();
         
      }
      setPendingApprovals(pendingApprovals === undefined ? [] : pendingApprovals)
      setMaxValue(formatNum(client['available_credit']));
      setMinValue(formatNum(lowerLimit));
      if (!sliderValue) {
         setSliderValue(initialValues['slice'])
      }
      if (!inputValue) {
         setInputValue(initialValues['input'])
      }
      setMin(lowerLimit);
      setStep(initialValues['step'])
      if (date === '00/0/0000') {
         setDate(calculateDueDate(product.subProducts[0]));
      }
      if (totalAmount === '0.0') {

         setTotalAmount(formatter(calculate_total_pay(initialValues['slice'], product.subProducts[0])))
      }
      if (productOnSelect.length === undefined && scheduleOnSelect === "") {
         setProductOnSelect(product.subProducts[0])
      }
      if (scheduleOnSelect === "") {
         setScheduleOnSelect(product.subProducts[0]['id'])
      }
    
   }, [client])
   let componentDictionary={
      SliderAmountComponent: SliderAmountComponent({
         client: client,
         sliderValue: sliderValue,
         inputValue: inputValue,
         handleOnChange,
         maxValue: maxValue,
         minValue: minValue,
         hadleSliderOnChange,
         max: max,
         min: min,
         onBlurInput,
         onFocusInput,
         step: step,
      }), 
      ScheduleCardComponent: ScheduleCardComponent({
         product: product,
         scheduleOnSelect: scheduleOnSelect
      }),
      ButtonComponent: ButtonComponent(
         buttonConfig
      ), 
      ResumenComponent: ResumenComponent({
         date: date,
         totalAmount: totalAmount,
         productName: productName
      })
   }
   return <div>
      {client && <ConfigureCreditMask {...componentDictionary} visible={matches} title={title}/>}
   </div>

}

SliderAmountComponent.propTypes = {
   client: PropTypes.any.isRequired,
   sliderValue: PropTypes.any.isRequired,
   inputValue: PropTypes.any.isRequired,
   handleOnChange: PropTypes.func.isRequired,
   maxValue: PropTypes.any.isRequired,
   minValue: PropTypes.any.isRequired,
   hadleSliderOnChange: PropTypes.func.isRequired,
   max: PropTypes.any.isRequired,
   min: PropTypes.any.isRequired,
   onBlurInput: PropTypes.func.isRequired,
   onFocusInput: PropTypes.func.isRequired,
   step: PropTypes.any.isRequired,
 };

ConfigureCreditCard.propTypes = {
   validateError: PropTypes.any.isRequired,
   loading: PropTypes.any.isRequired,
   matches: PropTypes.any.isRequired,
   title: PropTypes.any.isRequired,
   buttonBlocked: PropTypes.any.isRequired,
   lowerLimit: PropTypes.any.isRequired
 };

 ResumenComponent.propTypes = {
   date: PropTypes.any.isRequired,
   productName: PropTypes.any.isRequired,
   totalAmount: PropTypes.any.isRequired
 };

export default ConfigureCreditCard;