import Email from "./inputs/email";
import Password from "./inputs/password";
import Label from "./inputs/label";
import LinkComponent from "./inputs/link";
import TextFieldMaterial from "./inputs/textFieldMaterial";

const inputsComponents = {
  password: Password,
  email: Email,
  label: Label,
  link: LinkComponent,
  textFieldMaterial: TextFieldMaterial
};

const Input = ({ type, data }) => {
  const InputsComponent = inputsComponents[[type]];

  if (!InputsComponent) {
    return null;
  }
  data.props.touch = "false";
  if (!data.props.value) {
    let x = data.props.value;
    if (x === "" || x === null) {
      data.props.value = undefined;
    }
  }
  return <InputsComponent {...data} />;
};

const GenericInput = (props) => {
  return <Input type={props.props.type} data={props} />;
};

export default GenericInput;
