import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { useSideMenuContext } from "../../utils/providers/SideMenuProvider";

function SideMenu({ children }) {

    const { isSideMenuOpen, toggleSideMenu } = useSideMenuContext();

    const toggleDrawer = (open) => (event) => toggleSideMenu(open);

    return (
        <React.Fragment>
            <SwipeableDrawer
                anchor={'left'}
                open={isSideMenuOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <Box
                    sx={{ width: 296 }}
                    role="presentation"
                    data-testid="side-menu"
                >
                    {children}
                </Box>
            </SwipeableDrawer>
        </React.Fragment>
    );
}

export default SideMenu;