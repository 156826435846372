import { Grid, Radio, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment } from "react";
import themePayment from "../../../utils/themes/ThemePaymentOptions";
import formatter from "../../../utils/translations/formatter";
import { useTranslation } from "react-i18next";

const CardPayment = (props) => {
    const {
        paymentConfig,
        paymentType,
        optionSelect,
        handleOnClick,
        lineStatus,
        onColor,
        otherCard,
        onChange,
        onBlur,
        onFocus,
        value,
        _value,
        focus,
        error,
        errorText
    } = props;
    
    const styles = themePayment();
    const [t] = useTranslation("global");
    const ConditionsDictionary = {
        "correctCard&LineInArrears": onColor == paymentType && lineStatus == 'arrears',
        "correctCard": onColor == paymentType,
        "correctOption": onColor == optionSelect
    }
    const cardStyles = {
        "normal": {
            "backgroundColor": "#FFFFFF",
            "text": t('OptionPayment.expire')
        },
        "arrears": {
            "backgroundColor": "#FAE9E5",
            "color": "#D95A3A",
            "text": t('OptionPayment.expired')
        },
        "due": {
            "backgroundColor": "#f8fad9",
            "color": "#E29836",
            "text": t('OptionPayment.expiredToday')

        }
    }
    const radioElement = () => {
        return <Radio
        name = {`${paymentType}-radio`}
            data-testid={`${paymentType}-radio`}
            id={paymentType}
            size="small"
            onChange={handleOnClick}
            value={paymentType}
            checked={paymentType === optionSelect}
            sx={{
                color: 'black',
                '&.Mui-checked': {
                    color: 'black',
                },
            }}
        />
    }
    const specialCard = () => {
        return <div style={{ padding: '10px'}}><Box
            className={styles.specialCard}
            style={{
                borderColor: onColorValidations(),
            }}
            backgroundColor={cardStyles['normal'].backgroundColor}>
            <Grid container style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Grid item xs={1} sm={2} lg={1}>
                    {radioElement()}
                </Grid>
                <Grid container xs={8} justifyContent={'center'} justifyItems={'center'}>
                    <Grid item xs={1} sm={0} lg={1} />
                    <Grid item xs={11} className={styles.titleCard} style={{ display: 'flex', alignItems: 'center' }}>
                        {paymentConfig[paymentType].title}
                    </Grid>
                        {(paymentType === optionSelect) &&
                        <Grid container xs={12} >
                        <div>
                            <p className={styles.legendCard}> {paymentConfig[paymentType].legend}</p>
                            <TextField
                                type="text"
                                size="small"
                                variant="standard"
                                margin="normal"
                                style={{
                                    background: "#FFFFFF",
                                    boxShadow: 'none',
                                    border: 'solid 1px',
                                    height: '35px',
                                    borderRadius: '10px',
                                    fontWeight: '500',
                                    fontSize: '18px',
                                    lineHeight: '20px',
                                    paddingTop: '10px',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    margin: '0',
                                    borderColor: error? '#D95A3A' : 'gray',
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    "data-testid": 'optional-input'
                                }}
                                onBlur={onBlur}
                                onFocus={onFocus}
                                value={focus ? value : _value}
                                onChange={onChange}

                            />
                            {error &&
                            <p className={styles.ErrorCard}>
                                {errorText}</p>}

                        </div>
                        </Grid>}
                </Grid>

            </Grid>
        </Box>
        </div>
    }
    const onColorValidations = () => {
        let value = '';
        if (ConditionsDictionary['correctCard']) {
            value = cardStyles[lineStatus].color
            if (!ConditionsDictionary['correctOption'] && (lineStatus !== 'normal')) {
                value = cardStyles['arrears'].color
            }
        } else {
            value = cardStyles['normal'].color
        }
        return value;
    }
    const dateCard = () => {
        if (ConditionsDictionary["correctCard"] && cardStyles[lineStatus].text) {
            return (
                <Fragment>
                    <Grid item xs={1} sm={0} lg={1} />
                    <Grid item xs={11} className={styles.dateCard}>
                        {paymentConfig[paymentType].date}
                    </Grid>
                </Fragment>
            )
        }
    }
    const comunCard = () => {
        return <div style={{ padding: '10px' }}><Box
            style={{
                borderColor: onColorValidations(),
            }}
            className={styles.comunCard}
            backgroundColor={(onColor == paymentType) ? cardStyles[lineStatus].backgroundColor : cardStyles['normal'].backgroundColor}>
            <Grid container style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Grid item xs={1} sm={2} lg={1}>
                    {radioElement()}
                </Grid>
                <Grid container xs={8} justifyContent={'center'} justifyItems={'center'}>
                    <Grid item xs={1} sm={0} lg={1} />
                    <Grid item xs={11} className={styles.titleCard} style={{ display: 'flex', alignItems: 'center' }} >
                        {ConditionsDictionary['correctCard&LineInArrears'] ? paymentConfig[paymentType].titleArrears : paymentConfig[paymentType].title}
                    </Grid>
                    {dateCard()}
                </Grid>
                <Grid item xs={2} sm={10} lg={2} className="containerArrow">
                    <div className={styles.AmountCard}>{formatter(paymentConfig[paymentType].amount)}</div>
                </Grid>
            </Grid>
        </Box>
        </div>
    }

    return otherCard ? specialCard() : comunCard();
}
export default CardPayment; 