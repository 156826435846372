import React, {useEffect, useState} from "react";
import UserInfoHeader from "../components/credit/userInfoHeader";
import {Grid} from "@mui/material";
import {ThemeBanner} from "../utils/themes/ThemeBanner";
import {useSideMenuContext} from "../utils/providers/SideMenuProvider";
import {useCreditContext} from "../utils/providers/CreditProvider";
import {useAPIContext} from "../utils/providers/APIProvider";
import CreditList from "../components/credit/historyCredits/credit-list";
import Loading from "./Loading";
import {useNavigate} from "react-router-dom";
import PageHeader from "../components/commons/page-header/page-header";
import {useTranslation} from "react-i18next";

export default function MyHistory() {
    const bannerStyles = ThemeBanner();
    const {openSideMenu} = useSideMenuContext();
    const { client } = useCreditContext();
    const [t] = useTranslation("global");
    const currentDate = new Date();
    const [loans, setLoans] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [month, setMonth] = useState(currentDate.getMonth() + 1);
    const [year, setYear] = useState(currentDate.getFullYear());
    const navigate = useNavigate();

    const {
        get_all_credits
    } = useAPIContext();
    const getCredits = async () => {
        setLoading(true)
        const response = await get_all_credits({params: {month: month, year: year}}, client.client_id);
        if(response.status === 200){
            setLoans(response.data.loans)
            setProducts(response.data.products_details)
            setLoading(false)
        }else{
            setLoading(false)
        }
    }
    useEffect(
         async () => {
             await getCredits();
         }, [month, year]
    )

    const handleOnChange= (value) =>{
        setMonth(value.month);
        setYear(value.year);
    }

    const handle = (value) =>{
        let newData = {...value.props};
        let data = {origin: "WC_History_Loans",type: newData.type, amount: newData.amount, loan : newData.loan, product : newData.product, productDetail: newData.productDetail};
        navigate('/Details', { state: { data: data} })
    }


    return (<div>
            {loading && <Loading/>}
            <div style={{display: (loading) ? "none" : "block"}}>
                <Grid spacing={5} className={bannerStyles.bannerCredit}>
                    <Grid sx={{ display: { xs: 'block', sm: 'none' } }} paddingY={3}>
                        <PageHeader title={t("Menu.MyHistory")} product={''} handleBack={() => navigate("/Credit")}/>
                    </Grid>
                    <UserInfoHeader onMenuItemsClick={openSideMenu} userdata={client} visibilityContent={false} />
                    <CreditList
                        loans={loans}
                        handleLoans={(value) => handleOnChange(value)}
                        handleCard={handle}
                        products={products}
                        handleArrowBack={() => navigate("/Credit")}
                    />
                </Grid>
            </div>
        </div>
    )
}

