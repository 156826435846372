import React, { useEffect, useState, Fragment } from "react";
import { useCreditContext } from "../utils/providers/CreditProvider";
import { Stack } from "@mui/material";
import { ThemeBanner } from "../utils/themes/ThemeBanner";
import { useNavigate } from "react-router-dom";
import OptionPayment from "../components/payment/option-payment/option-payment";
import UserInfoHeader from "../components/credit/userInfoHeader";
import Loading from "./Loading";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MessageError from "../components/loader/messageError";
import { useTranslation } from "react-i18next";

export default function Payment() {
  const {
    client,
    navigateConfig,
    loansInfo,
    setReloadInfo,
    handleBack
  } = useCreditContext();
  const [t] = useTranslation("global");
  const _paymentTypes = ['required', 'total', 'optional']
  const navigate = useNavigate();
  const [error, seterror] = useState(false)
  const [loading, setloading] = useState(false)
  const theme = useTheme();

  const bannerStyles = ThemeBanner();
  const title = {
    title: t('OptionPayment.PaymentCredit'),
    back: t('Buttons.Back'),
  }


  const getNavigateBack = (navigateConfig) => {
    if(handleBack){
      setReloadInfo(true)
    }else{
      setReloadInfo(false)
    }
    window.analytics.track('PAYMENT_BACK', {
      page: 'WC_Payment',
      journey: 'WC_Loans',
      product_group: 'Lending',
      product_name: 'Working_Capital',
      button_name: 'Regresar'
    });
    if (navigateConfig?.back) return navigateConfig.back;
    return -1;
  }

  useEffect(async () => {
    window.analytics.page('PAGEVIEW_PAYMENT', {
      page: 'WC_Payment',
      journey: 'WC_Loans',
      product_group: 'Lending',
      product_name: 'Working_Capital',
    });
  }, []);

  let matches = useMediaQuery(theme.breakpoints.down('sm'))

  return (<>
    <Fragment>
      {loading && <Loading></Loading>}
      {error && <MessageError></MessageError>}
      {<div style={{ display: (loading || error) ? "none" : "block" }}>
        <Stack className={bannerStyles.bannerPayment} spacing={2}>
          <UserInfoHeader userdata={client} visibilityContent={'visible'}  {...(!matches ? {} : { navigate: () => navigate(getNavigateBack(navigateConfig)), title: title.title })}/>
          <OptionPayment
            _paymentTypes={_paymentTypes}
            _dataPayment={loansInfo}
            validateError={seterror} loading={setloading}
            matches={matches}
            backnavigate={() => navigate(getNavigateBack(navigateConfig))}
            title={title.back}
          />
        </Stack>
      </div>
      }
    </Fragment>
  </>);
}
