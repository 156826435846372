import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { fontFamily } from "@mui/system";

const themeDetails = makeStyles((theme: Theme) => ({
    cardDetails:{
        display: 'block',
        textAlign: 'start !important',
        [theme.breakpoints.up('xs')]: {
            marginLeft: '35px', 
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '-48px', 
        },
    },
    dueText:{
        marginBottom: '-15px',
        fontWeight:'500',
        [theme.breakpoints.up('xs')]: {
            marginLeft: '45px', 
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '35px', 
        },
    },
    detailGrid: {
        margin: '10px',
        borderRadius: '20px',
        width: '100px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    detail: {
        [theme.breakpoints.down('sm')]: {
            marginTop: "5%"
        },
        [theme.breakpoints.up('sm')]: {
            display: "none",
        },
    },
    detailDivImage: {
	    paddingTop: '10px',
        paddingBottom: '10px',
        textAlign: 'center'
    },
    detailImageEpin: {
        paddingTop:'10px',
	    width: '65%',
    },
    detailImageEmoney: {
        padding:'15px',
	    width: '65%'
    },
    textFieldKeyDetails:{
        fontSize: "14px !important",
        color: "#708195 !important",
        lineHeight: "14px !important",
        fontWeight: "400 !important",
        fontFamily: "Readex Pro !important",
        [theme.breakpoints.up("sm")]: {
            fontSize: "16px !important",
            fontStyle: "normal",
            lineHeight: "24px !important",
            letterSpacing: "0.247px",   
        },
    },
    textFieldValueDetails:{
        fontSize: "14px !important",
        fontWeight: "600 !important",
        fontFamily: "Readex Pro !important",
        [theme.breakpoints.up("sm")]: { 
            fontSize: "16px !important",
            fontStyle: "normal",
            fontWeight: "500 !important",
            lineHeight: "24px !important",
            letterSpacing: "0.247px",   
        }
    },
    TotalPayment: {
        [theme.breakpoints.up("sm")]: { 
            fontSize: "22px !important", 
        }
    },
    divider:{
        marginBottom: "40px !important", 
        marginTop: "14px !important",
        [theme.breakpoints.up("sm")]: { 
            marginBottom: "20px !important",
        }
    },
    textCardMoney:{
        textAlign: "end !important",
        fontSize: "20px",
        fontWeight: "600",
        fontFamily: 'Readex Pro',
    },
    textCardMoney_:{
        textAlign: "end !important",
        fontSize: "20px",
        fontWeight: "600",
        fontFamily: 'Readex Pro',
    },
    gridLoan: {
        [theme.breakpoints.up("xs")]: {
            width: "330px !important",
            justifyContent: "flex-start !important",
        },
    },
    cardCointainer: {
        position: "absolute !important",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        [theme.breakpoints.up("xs")]: {
            margin: "85px auto auto auto !important",
            borderRadius: "24px !important",
            backgroundColor: "white !important",
            width: "85vw !important",
            height: "70vh !important",
            boxShadow: '0px 4px 12px 0px rgba(194, 209, 217, 0.46) !important',
            justifyContent: "center !important",
            alignItems: "center !important",
        },
        [theme.breakpoints.only("md")]: {
            height: "99vh !important",  
        },
        [theme.breakpoints.only("lg")]: {
            height: "99vh !important",
        },
        [theme.breakpoints.down("xs")]: {
            margin: "12vh auto auto auto !important",
            paddingLeft: '30px !important',
            paddingRight:'50px !important',
            paddingTop:'30px !important',
            background: "#FFFFFF !important",
            borderTopLeftRadius: '21px !important',
            borderTopRightRadius: '21px !important',
        },
    },
    cardContentHeader: {
        [theme.breakpoints.up("xs")]: {
            padding: "35px !important",
        },
        [theme.breakpoints.down("xs")]: {
            display: "none !important"
        },
    },
    cardContent: {
        [theme.breakpoints.up("sm")]: {
            justifyContent: "center !important",
            alignItems: "center !important",
            display: "flex",
            height: "495px !important",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "0px !important"
        },
    },
    loansContainer: {
        [theme.breakpoints.up("xs")]: {
            // width: "60vw !important",
            backgroundColor: "white !important",
        },
    },
    buttonContainer: {
        marginTop: "27% !important", 
        marginBottom: '30px !important'
    },
    titleHeader: {
        [theme.breakpoints.up("xs")]: {
            fontFamily: "Readex Pro !importan",
            fontSize: "24px !important",
            fontStyle: "normal !important",
            fontWeight: "600 !important",
            lineHeight: "34px !important",
            letterSpacing: "0.247px !important",
            marginBottom: "20px !important",
            display: 'inline-block',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down("xs")]: {
            display: "none !important",
        }
    },
    subtitleHeader:{
        fontFamily: "Readex Pro !importan",
        fontWeight: "600 !important",
        [theme.breakpoints.up("xs")]: {
            fontSize: "24px !important",
            fontStyle: "normal !important",
            lineHeight: "34px !important",
            display: 'inline-block',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "14px !important",
            marginLeft: "25px"
        }
       
    },
    detailsContainerColumns: {
        padding: "0px !important",
        [theme.breakpoints.up("xs")]: {
            justifyContent: "center !important",
        },
        // [theme.breakpoints.down("xs")]: {
        //     display: "none !important",
        // }
    },
    detailsContainerMobile: {
        [theme.breakpoints.up("xs")]: {
            display: "none !important",
        },
    },
    columA: {
        [theme.breakpoints.up("sm")]: {
            width: "30vw",
            marginRight: "5vw",
        },
    },
    columB: {
        [theme.breakpoints.up("sm")]: {
            width: "30vw",
            marginLeft: "5vw",
        },
    },
    rowContent: {
        [theme.breakpoints.up("sm")]: {
            paddingBottom: "2vh",
        },
    }
}));

export default themeDetails;
