import { Grid, TextField, } from "@mui/material";
import React from "react";
import themeConfigureCredit from "../../../utils/themes/ThemeConfigureCredit";

const SliderAmount = ({
    inputValue,
    inputOnChange,
    maxValue,
    minValue,
    unit,
    currency,
    onBlurInput,
    onFocusInput,
    children
}) => {

    const styles = themeConfigureCredit();

    return <div style={{ marginBottom: '30px' }}> <Grid container
        className={styles.sliderAmountPrincipal}

    >
        <Grid container className={styles.gridContainerInput}>
            <Grid item xs={7} className={styles.inputAmount}>
                <div style={{ display: 'flex', flexDirection: 'row', width: "90%" }}>
                    <p className={styles.unitTextTop}>{unit}</p>
                    <TextField
                        type="text"
                        onBlur={onBlurInput}
                        onFocus={onFocusInput}
                        variant="standard"
                        margin="normal"
                        style={{
                            fontFamily: "Readex Pro",
                            background: "#F6F6F6",
                            boxShadow: 'none',
                            border: 'none',
                            borderRadius: '8px',
                            lineHeight: '20px',
                            padding: '8px 10px 8px 8px',
                            margin: '0',
                            width: '60%',
                        }}
                        id="amount-input"
                        size="small"
                        aria-label= " "
                        fullWidth
                        name={`amount-input`}
                        InputLabelProps={{ style: { color: "#00377D" } }}
                        InputProps={{
                            disableUnderline: true,
                            "data-testid":'amount-input',
                        }}
                        inputProps={{ 
                            style: { 
                                textAlign: "right", 
                                padding: "0px", 
                                fontWeight: "700",
                                fontSize: "18px",
                                fontFamily: "Readex Pro",
                            },
                        }}
                        value={inputValue}
                        onChange={inputOnChange}
                    />

                </div>

            </Grid>
        </Grid>
        <Grid item xs={12}>
            {children}

        </Grid>
        <Grid container >
            <Grid item xs={6} className={styles.amountTextBottonLeft}>{unit} {minValue}</Grid>
            <Grid item xs={6} className={styles.amountTextBottonRigth}>{unit} {maxValue}</Grid>
        </Grid>
    </Grid></div>

}
export default SliderAmount;