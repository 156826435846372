import { default as img } from "./inputs/image";
import CreateAccount from "../assets/generic/CreateAccount.svg";
import FinishImage from "../assets/generic/FinishImage.svg";

const imageComponents = {
  image: img,
};

const images = {
  "CreateAccount": CreateAccount,
  "FinishImage": FinishImage
};

const Image = ({ type, data }) => {
  const ImageComponent = imageComponents[[type]];
  const source = images[[data.name]];
  if (!ImageComponent) {
    return null;
  }
  return <ImageComponent img={source} classname={data.styleClassName} />;
};

const GenericImage = ({ props }) => {
  return <Image type={props.type} data={props} />;
};

export default GenericImage;
