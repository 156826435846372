import React, { useState, useEffect } from "react";
import dataJson from "../config/signUp-password.json";
import GenericForm from "../genericForm";
import { schema, getYup } from "../../utils/yup";
import {get_user} from "../../actions/axios-api-kyx";
import {useNavigate} from "react-router-dom";
import {useCreditContext} from "../../utils/providers/CreditProvider";

export default function VerifyPassword(_props) {
  const [validates, setValidates] = useState({});
  const navigate = useNavigate();
  const { setCreateAccount } = useCreditContext();

  const onSubmit = async (values) => {
    await window.analytics.track("PASSWORD_CREATION_CONTINUE", {
      page: "WC_Signup_password_creation",
      journey: "WC_Onboarding",
      button_name: "continuar",
      product_group: "Lending",
      product_name: "Working_Capital"
    });
    navigate("/form", {
      screen: "Form",
      state: {
        token: _props.token,
        password: values["password"],
        email: _props.email,
        origin: _props.origin,
        tokenID: _props.tokenID,
      },
    });
  };

  const onSubmitLegacyUser = async (values) => {
    await window.analytics.track("PASSWORD_CREATION_CONTINUE", {
      page: "WC_Signup_password_creation",
      journey: "WC_Onboarding",
      button_name: "continuar",
      product_group: "Lending",
      product_name: "Working_Capital"
    });

      setCreateAccount({ "token": _props.token, "password": values["password"], "email": _props.email })
      navigate("/FormLegacy");

  }

  useEffect(async () => {
    await window.analytics.page("PAGEVIEW_PASSWORD_CREATION", {
      page: "WC_Signup_password_creation",
      journey: "WC_Onboarding",
      product_group: "Lending",
      product_name: "Working_Capital"
    });
    let _validates = {};
    dataJson.forms.fields.forEach(({props, validations}) => {
      _validates[props.name] = schema(validations, props.type);
    });
    setValidates(_validates);
  }, []);

  return (
      <GenericForm
          form={dataJson.forms}
          initialValue={{}}
          validates={getYup(validates)}
          setLoginError={() => {}}
          onSubmit={(e) => _props?.isLegacyMomoUser ? onSubmitLegacyUser(e) : onSubmit(e)}
          isDefault={true}
          loginError={false}
          disabledButton={false}
          setDisabledButton={() => {}}
      />
  );
}
