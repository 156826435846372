import React from 'react'
import IframeKym from '../components/kym/IframeKYM'
import {useLocation} from "react-router-dom";

const Form = () => {
  const location = useLocation();
  const password = location.state?.password
  const email =  location.state?.email
  const token = location.state?.token
  const origin = location?.state?.origin
  const tokenID = location?.state?.tokenID
  return email ? (
    <IframeKym FormId="F1022" idpdv="" email="" remedy={false} userEmail={email} password={password} token={token} origin={origin} tokenID={tokenID}></IframeKym>
  ) : <></>
}

export default Form
