const getData = (data, password, userType = "Digital V2", isMtsUser, mtsClientID, checkNoticePrivacy) => {
    try {
        let info;
        let business_name = (data && data.Negocio["business-information"]["business_name"]) || ("");
        info = {
            "business_name": business_name,
            "password": password,
            "e_pin": data.Negocio["contact-information"]["epin_cell_number"],
            "e_money": data.Negocio["contact-information"]["emoney_cell_number"],
            "name": data.Negocio["owner-information"]["name"],
            "lastname": data.Negocio["owner-information"]["lastname"],
            "ruc": data.Negocio["facturation-information"]["ruc"],
            "msisdn": data.Negocio["owner-information"]["active_cell_number"],
            "id_pdv": data.Negocio["facturation-information"]["id_pdv"],
            "user_type": userType,
            "person_type": data.Negocio["business_type"]["type_of_company"],
            "id_number": data.Negocio["facturation-information"]["id_number"],
            "email": data.Negocio["contact-information"]["email"],
            "legal_representative_name": data.Negocio?.["business-information"]["legal_representative_name"],
            "legal_representative_type":  data.Negocio?.["business-information"]["legal_representative_type"],
            "status": "true",
            "contact": data.Negocio["owner-information"]["contact"] ? data.Negocio["owner-information"]["contact"].replace(/-/g, "") :"0000000000",
            "is_abbreviate": data?.is_abbreviate + "",
            "create_account": "true",
            "check_notice_privacy": checkNoticePrivacy,
            "check_terms_conditions": "true",
            "client_id": mtsClientID,
            "contract" : data?.signed_contract ? "false" : "true",
            "is_mts_user": isMtsUser
        };
        return info;
    } catch (err) {
        console.error("get data ex:" + err);
        return {};
    }
}


const getDataContract = (data) => {
    try {
        let business_name = "";
        let legal_representative_name = "";
        let legal_representative_type = "";

        try {
            business_name = data.Negocio["business-information"]["business_name"];
            legal_representative_name = data.Negocio["business-information"]["legal_representative_name"];
            legal_representative_type = data.Negocio["business-information"]["legal_representative_type"];
        } catch {
        }
        let dataContract;
        dataContract = {
            person_type: data.Negocio["business_type"]["type_of_company"],
            firstname: data.Negocio["owner-information"]["name"],
            lastname: data.Negocio["owner-information"]["lastname"],
            name: data.Negocio["owner-information"]["name"] + " " + data.Negocio["owner-information"]["lastname"],
            id_number: data.Negocio["facturation-information"]["id_number"],
            ruc: data.Negocio["facturation-information"]["ruc"],
            id_pdv: data.Negocio["facturation-information"]["id_pdv"],
            email: data.Negocio["contact-information"]["email"],
            cell_number_epin: data.Negocio["contact-information"]["epin_cell_number"],
            cell_number_emoney: data.Negocio["contact-information"]["emoney_cell_number"],
            business_name: business_name,
            legal_representative_name: legal_representative_name,
            legal_representative_type: legal_representative_type,
            contact: data.Negocio["owner-information"]["contact"],
            is_abbreviate: data?.is_abbreviate
        }
        return dataContract;
    } catch (err) {
        console.error("getDataContract ex:" + err);
        return {};
    }
};

const getAccount = (data, email, password, userType = "Digital V2") => {
    try {
        let account;
        let business_name = (data && data.Negocio["business-information"]["business_name"]) || ("");
        account = {
            "business_name": business_name,
            "useremail": email,
            "password": password,
            "epin_cell_number": data.Negocio["contact-information"]["epin_code"] + data.Negocio["contact-information"]["epin_cell_number"],
            "emoney_cell_number": data.Negocio["contact-information"]["emoney_code"] + data.Negocio["contact-information"]["emoney_cell_number"],
            "name": data.Negocio["owner-information"]["name"],
            "lastname": data.Negocio["owner-information"]["lastname"],
            "ruc": data.Negocio["facturation-information"]["ruc"],
            "msisdn": data.Negocio["owner-information"]["active_cell_number"],
            "id_pdv": data.Negocio["facturation-information"]["id_pdv"],
            "user_type": userType
        };
        return account;
    } catch (err) {
        console.error("get account ex:" + err);
        return {};
    }
};

export {getDataContract, getAccount, getData};