import React, {useEffect} from 'react'
import { useTranslation } from "react-i18next";

import OnBoardingLayout from "../utils/on-boarding-layout";
import Notification from "./Notification";
import messageRemedy from '../assets/generic/messageRemedy.svg';
import {wcDefaultSegmentProperties} from "../utils/segment";

export default function PendingDocuments() {
    const [t] = useTranslation("global");

    useEffect(() => {
        window.analytics.page("PAGEVIEW_DOC_PENDING", {
            page: "WC_Docs_Pending",
            ...wcDefaultSegmentProperties()
        });
    }, []);

    return(
        <OnBoardingLayout screen={<Notification image={messageRemedy} title={t('Notifications.PendingDocuments.Title')} message={t('Notifications.PendingDocuments.Message')}/>} newdashboard={true} fullcard={true} smallcard={false}/>
    );
}
