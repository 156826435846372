import React from "react";
import { Typography } from "@mui/material";

const SubTitle = ({ subTitle }) => {
  return (
    <Typography className="subTitle" justifyContent="center" textAlign="center">
      {subTitle}
    </Typography>
  );
};

export default SubTitle;
