import React from 'react'

import IframeKym from '../components/kym/IframeKYM'
import { useCreditContext } from '../utils/providers/CreditProvider'

const FormLegacy = () => {
  const { session, createAccount } = useCreditContext();
  
  return createAccount?.email ? (
    <IframeKym 
      FormId="F1018_legacy" 
      idpdv={session.idpdv} 
      email="" 
      remedy={false} 
      userEmail={session.email} 
      legacyUserWc={true} 
      password={createAccount.password} 
      token={createAccount.token} />
  ) : <></>
}

export default FormLegacy