import React from 'react'
import { Box, Card, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { primaryButtonStyles, setButtonLabel } from "../../utils/themes/ThemeButtons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "../inputs/button";
import invalidTokenImage from '../../assets/invalidToken/invalidToken.svg';
import themeInvalidToken from "../../utils/themes/ThemeInvalidToken";

export default function InvalidTokenComponent() {
    let styles = themeInvalidToken()
    const [t] = useTranslation("global");
    const navigate = useNavigate()

    return (
        <Stack direction="column"
                alignItems="center"
                justifyContent="center" 
                className={styles.boxOptReq}>
            <Card className={styles.card}>
                <Grid className={styles.gridContainer}>
                    <Grid 
                        container
                        display="flex"
                        justifyContent="center"
                        style={{marginBottom:'25px'}}
                    >
                        <img
                            alt="invalid token"
                            className={styles.logo}
                            src={invalidTokenImage}
                        />
                    </Grid>

                    <Grid
                        container
                        justifyContent="center" className={styles.gridText}>
                        <Grid item>
                            <Typography className={styles.text}>{t("Notifications.InvalidToken.Message")}</Typography>
                        </Grid>
                        <Box sx={{width: '100%'}}>
                            <Grid container >
                                <Grid item xs={12} sm={6} md={6} className={styles.buttonContainer}>
                                    <Button
                                        {...setButtonLabel(primaryButtonStyles, t("Notifications.InvalidToken.Button"))}
                                        btnNext={()=> { navigate("/") }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </Stack>
    )
}
