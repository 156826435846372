import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import FinishProcess from "../../src/assets/generic/FinishProcess.svg";
import PendingValidate from "../../src/assets/generic/PendingValidate.svg";
import RejectedProcess from "../../src/assets/generic/RejectedProcess.svg";
import DocumentRemedy from "../../src/assets/generic/messageRemedy.svg";
import themeGenericMessage from "../utils/themes/ThemeGenericMessage";

const Data = {
    "finish-contract": FinishProcess,
    rejected: RejectedProcess,
    "pending-validate": PendingValidate,
    "remedy": DocumentRemedy
};
const Functions = {
    link: Link.onClick,
};
const Message = ({ data }) => {
    const theme = themeGenericMessage();
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const isDefaultType = data.data.default === false ? data.data.default : true;
    const styles = {
        button: {
            backgroundColor: "#fabe00",
            color: "#404A56",
        },
        contentView: {
            height: "100%",
            webkitBoxShadow: "1px 3px 1px #9E9E9E",
            mozBoxShadow: "1px 3px 1px #9E9E9E",
        },
        contentImage: {
            height: "30vh",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        },
    };

    return (
        <Box className={theme.PrincipalBox} mt={12}>
            <Box className={theme.contentView} p={5}>
                <Box className={theme.BoxStyle} pb={15}>
                    {isDefaultType ? (
                        <Box>
                            <Box className={theme.BoxTypography}>
                                <Typography variant="title" component="div" gutterBottom className={theme.PrimaryTypography}>
                                    {t(data.data.title)}
                                </Typography>
                            </Box>
                            <Box className={theme.BoxTypography}>
                                <Typography component="div" gutterBottom className={theme.SecondaryTypography}>
                                    {t(data.data.subTitle)}
                                </Typography>
                            </Box>
                            <Box className={theme.ContentImage}>
                                <img src={Data[data.data.image]} alt="Finish" className={theme.Image}></img>
                            </Box>
                        </Box>
                    ) : (
                        <Box>
                            <Box className={theme.ContentImage}>
                                <img src={Data[data.data.image]} alt="Finish" className={theme.Image}></img>
                            </Box>
                            <Box className={`${theme.BoxTypography} ${theme.BoxSize}`}>
                                <Typography variant="title" component="div" gutterBottom className={theme.ThirdTypography}>
                                    {t(data.data.title)}
                                </Typography>
                            </Box>
                            <Box className={`${theme.BoxTypography} ${theme.BoxSize}`}>
                                <Typography component="div" gutterBottom className={theme.FourthTypography}>
                                    {t(data.data.subTitle)}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {(data.data.button || data.data.loading) && (<Box mt={13} sx={{ justifyContent: "center", display: "flex" }}>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={1} md={1} lg={1} mb={2} sx={{ textAlign: "center" }}>
                                {data.data.button && (
                                    <Button
                                        style={styles.button}
                                        onClick={
                                            data.data.button.onClick
                                                ? () => Functions[data.data.button.onClick]
                                                : () => navigate(data.data.button.link)
                                        }
                                    >
                                        {t(data.data.button.name)}
                                    </Button>
                                )}
                                {data.data.loading && (
                                    <CircularProgress style={{ marginLeft: "20px", width: "60px", height: "60px", }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Box>)}
                </Box>
            </Box>
        </Box>
    );
};

const GenericMessage = (props) => {
    const DataMessages = props.type;
    if (!DataMessages) {
        return null;
    }

    return <Message data={DataMessages} />;
};

export default GenericMessage;
