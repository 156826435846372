import { Grid, Box } from '@mui/material';
import React from 'react';
import Emoney from '../../../assets/credit/emoney.svg';
import Epin from '../../../assets/credit/epin.svg';
import Emoneys from '../../../assets/credit/emoneyshort.svg';
import Epins from '../../../assets/credit/epinshort.svg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import themeActiveCredit from '../../../utils/themes/ThemeActiveCredit';
import themeDetails from '../../../utils/themes/ThemeDetails';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip, {tooltipClasses, TooltipProps} from '@mui/material/Tooltip';
import { styled } from "@mui/material/styles";
import { InteresAndTotal } from '../../../utils/getProductInfo';
import formatter from '../../../utils/translations/formatter';

const logo = {
    'Emoney': Emoney,
    'Epin': Epin,
    'Tigo Money': Emoneys,
    'Mini Carga': Epins
}
const background_color = {
    'Emoney': "#FFD551",
    'Epin': "#DEEDFF",
    'Tigo Money': "#FFD551",
    'Mini Carga': "#DEEDFF"
}

const BootstrapTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} arrow classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#363853",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#363853",
        maxWidth: 200,
        marginLeft: "40px"
    }
}));
export default function CardCredit(props) {
    const {
        type,
        amount,
        date,
        due = true,
        url = "/",
        style = {},
        generic = false,
        details = false,
        isFunction = false,
        handlerFunction,
        product,
        productDetail,
        loan,
        simpleCard = false,
        loanStatus
    } = props;

    /*
    How to use this component:
      type: choose between Emoney | Epin types
      amount:  credit's amount 
      currencyCountry: country initials
      due: choose between true | false boolean values
      url: url to credit description,
      date: due date (due node has priority)
    */
    const styles = themeActiveCredit();
    const stylesDetails = themeDetails();
    const navigate = useNavigate();
    const [t] = useTranslation("global");

    const handlerClick = () =>{
        if (!isFunction){
            navigate(url, { state: { data: props} })
        }else {
            handlerFunction({product, loan, props, productDetail, type, amount, date})
        }
    }
    const legend = {
        'ACTIVE': <p className={styles.activeText}>{t('Credit.creditStatus.Active')}</p>,
        'CLOSED': <p className={styles.closedText}>{t('Credit.creditStatus.Closed')}</p>,
        'ACTIVE_IN_ARREARS': <p className={styles.dueText_}>{t('Credit.creditStatus.Due')}</p>
    }
    const dueLegend = () => {
        return <React.Fragment>
            <Grid container
                justify="flex-start"

            >
                <BootstrapTooltip title={t('Credit.creditActive.legendDueCredit')} arrow placement="top" enterTouchDelay={1}>
                    <div className={styles.divInToolTip}>
                        <p className={styles.dueText}>
                            {t('Credit.creditActive.dueCredit')}
                        </p >
                        <HelpOutlineOutlinedIcon className={styles.questionIcon} />
                    </div>
                </BootstrapTooltip>
            </Grid>

        </React.Fragment>
    }
    const legendText = () => due ? dueLegend() : `${t('Credit.creditActive.dueDate')} ${date}`;
    const stylesImage ={
        'Emoney': !details ? styles.imageTypeCreditEmoney : stylesDetails.detailImageEmoney,
        'Epin': !details ? styles.imageTypeCreditEpin : stylesDetails.detailImageEpin,
        'Tigo Money': !details ? styles.imageTypeCreditEmoneys : stylesDetails.detailImageEmoney,
        'Mini Carga': !details ? styles.imageTypeCreditEpins : stylesDetails.detailImageEpin,
    }

    const cardDetails = () => {
        return (
            <React.Fragment>
                <Grid item xs={5} style={{ textAlign:'-webkit-right'}}>
                    <div style={{ backgroundColor: background_color[type] }} className="detail-Grid" >
                        <div className={stylesDetails.detailDivImage}>
                            <img
                                className={stylesImage[type]}
                                src={logo[type]}
                                alt={type}
                            />
                        </div>
                    </div>
                </Grid>
            </React.Fragment>
        )
    }

    const { total } = loan && productDetail ? InteresAndTotal({loan, productDetail}) : 0

    return (
        <div style={style}>
            <Box className={!details && styles.boxCard}>
                <Grid
                    className={styles.cardBody}
                    container
                    justify="center"
                    alignItems="center"
                >
                    {!details ? <Grid item xs={3} backgroundColor={background_color[type]} className={styles.gridImage}>
                        <div className={styles.divImage}>
                            <img
                                className={stylesImage[type]}
                                src={logo[type]}
                                alt={type}
                            />
                        </div>
                    </Grid> : cardDetails()}
                    {!simpleCard ?
                        <Grid item xs={details ? 7 : 8}>
                            <Grid container
                                className={styles.resumeContainer}
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item xs={12} className={!details ? styles.textCardMoney : stylesDetails.textCardMoney}>{amount ? `${formatter(total)}` : `${type}`}</Grid>
                                {!generic ? <Grid item xs={12} className={styles.textCardLegend}>{legendText()}</Grid> : <></>}
                            </Grid>
                        </Grid>
                        :
                        <React.Fragment>
                            <Grid item xs={6}>
                                <Grid container
                                    className={styles.resumeContainer}
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Grid item xs={12} className={styles.textCardMoney_}>{"Gs " + amount}</Grid>
                                    <Grid item xs={12} mt={1}></Grid>
                                    <Grid item xs={12} className={styles.textCardLegend_}>{legend[loanStatus]}</Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={2} className={styles.dateText}>
                                {date}
                            </Grid></React.Fragment>
                    } 
                    {!details && <Grid item xs={1} data-testid={`product-${type?.replace(" ", "-")}`} className="containerArrow" onClick={handlerClick}>
                        <ArrowForwardIosIcon className="Arrow" />
                    </Grid>}
                </Grid>
            </Box>
        </div>
    )
}
